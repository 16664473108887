import React, { useState, useEffect } from 'react';

import {
    ButtonGroup, ToggleButtonGroup, ToggleButton, Badge, Tooltip, Zoom, Typography, MenuItem, tooltipClasses, styled, Button, TextField, Snackbar, Alert, ListItemText,
    useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';


import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import './Partes.css';
import MostrarError from '../../components/AccionesPartes/MostrarError';
import CargaSpinner from '../../components/AccionesPartes/CargaSpinner';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(10),
        border: '1px solid #dadde9',

    },
}));



function PreConforme() {
    let hoteles = localStorage.getItem('hoteles');
    const [hotelesLista, setHotelesLista] = useState([]);
    const [hotelTrabajo, setHotelTrabajo] = useState(0);

    //Conjunto para mostrar un Alert con el resultado de la petición
    const [mensajeError, setMensajeError] = useState('');
    const [tipoError, setTipoError] = useState('');
    const [contadorError, setContadorError] = useState(1);
    const [contadorSpinner, setContadorSpinner] = React.useState(false);
    const [estadoAlert, setEstadoAlert] = React.useState(false);

    const [actualizo, setActualizo] = useState(true);
    const [estadoInicial, setEstadoInicial] = React.useState(true);

    const [activoFiltro, setActivoFiltro] = useState(false);
    const [estadoFiltros, setEstadoFiltros] = React.useState(false);
    const [datosFiltro, setDatosFiltro] = useState({
        id_factura: '',
        n_factura: '',
        cif: '',
        mostrar: '0',
        fecha_desde: '',
        fecha_hasta: ''
    });
    const [datosFiltroTemp, setDatosFiltroTemp] = useState({});

    const [datos, setDatos] = useState([]);
    const [tablaFacturas, setTablaFacturas] = useState("");
    const [tipoMostrar, setTipoMostrar] = useState([{ value: '0', label: 'Faltan Confirmar' }, { value: '1', label: 'Confirmadas por Usuario' }, { value: '2', label: 'Todas' }]);


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const mostrarError = (mensajeError, tipoError) => {
        setMensajeError(mensajeError);
        if (tipoError) {
            setTipoError(tipoError);
        } else {
            setTipoError("error");
        }
        setContadorError(contadorError + 1);
        setEstadoAlert(true);
        const timer2 = setTimeout(() => {
            setEstadoAlert(false);
        }, 3000);
        return () => clearTimeout(timer2);
    };

    const cambiaHotelTrabajo = (event) => {
        if (event.target.name === 'id_hotel') {
            setHotelTrabajo(event.target.value);
        }
    }

    const actualizar = () => {
        setActualizo(!actualizo);
    }

    const fd = new FormData();
    const requestOptions = {
        method: 'POST',
        body: fd
    };

    /***********************************************/
    // Filtros
    const ocultarFiltros = () => {
        setEstadoFiltros(false);
        actualizar();
    };

    const ocultarFiltros2 = () => { // Solo oculta los filtros.
        setEstadoFiltros(false);
        setDatosFiltro(datosFiltroTemp);

        comprobarEstadoFiltros();
    };

    const comprobarESC = (event) => {
        if (event.keyCode === 27) {
            ocultarFiltros();
        }
    };

    const comprobarEstadoFiltros = () => { // Comprobamos si hay algun valor en datosFiltro
        const hayFiltrosActivos = Object.values(datosFiltro).some(
            (valor) => valor !== '' && valor !== false && valor !== 0 && valor.length !== 0
        );
        setActivoFiltro(hayFiltrosActivos);
    };

    const mostrarFiltros = () => {
        setDatosFiltroTemp({ ...datosFiltro });
        setActivoFiltro(true);
        setEstadoFiltros(true);
    };
    const comprobarEnter = (e) => {
        if (e.key === 'Enter') {
            guardarDatos();
        }
    }
    //Funcion para realizar el Filtro de los partes.
    const guardarDatos = () => {
        const datos = datosFiltro;
        var str = '';

        Object.keys(datos).forEach((nombre, i) => {
            if (Object.values(datos)[i] !== '' && Object.values(datos)[i] !== undefined) {
                if (nombre === "fecha_hasta" || nombre === "fecha_desde") {
                    let fecha_ordenada = "";

                    // Dividir la cadena de la fecha en partes usando el separador "-"
                    const partes = Object.values(datos)[i].split("-");

                    // Reorganizar las partes para que la fecha esté en formato "yyyy-mm-dd"
                    fecha_ordenada = partes[2] + "-" + partes[1] + "-" + partes[0];
                    str += nombre + "=" + fecha_ordenada + '|';

                } else {
                    str += nombre + "=" + Object.values(datos)[i] + '|'
                }
            }
        });
        console.log(str);
        setEstadoFiltros(false);
        setActivoFiltro(false);
        comprobarEstadoFiltros();
    };

    const actualizarFiltros = (event) => {
        // console.log(event);

        const { name, value } = event.target;
        if (name === 'id_factura' || name === 'n_factura') {
            const validValue = value.replace(/[^0-9,]/g, ''); // Eliminar cualquier carácter no numérico o coma
            setDatosFiltro({
                ...datosFiltro,
                [name]: validValue,
            });
        } else {
            setDatosFiltro({
                ...datosFiltro,
                [name]: value,
            });
        }
    };


    /***********************************************/

    const getFacturas = () => {
        // console.log("Hotel trabajo " + hotelTrabajo);
        // console.log(hotelesLista);

        fd.append("f", "partes.getFacturasConf");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", hotelTrabajo);

        const datos = datosFiltro;
        var params = '';
        Object.keys(datos).forEach((nombre, i) => {
            if (Object.values(datos)[i] !== '' && Object.values(datos)[i] !== undefined) {
                if (nombre === "fecha_hasta" || nombre === "fecha_desde") {
                    let fecha_ordenada = "";

                    // Dividir la cadena de la fecha en partes usando el separador "-"
                    const partes = Object.values(datos)[i].split("-");

                    // Reorganizar las partes para que la fecha esté en formato "yyyy-mm-dd"
                    fecha_ordenada = partes[2] + "-" + partes[1] + "-" + partes[0];
                    params += nombre + "=" + fecha_ordenada + '|';

                } else {
                    params += nombre + "=" + Object.values(datos)[i] + '|'
                }
            }
        });
        var id = localStorage.getItem('id');
        params += "id_usuario=" + id + '|';

        fd.append("params", params);
        fetch('https://api.workers.grupotel.com/', requestOptions)
            .then(response => response.json(), setContadorSpinner(true))
            .then(result => {
                console.log(params);
                console.log(result);
                let error = result.error;
                if (error.id === 0) {
                    let facturas = [];
                    let infoFactura = result.facturas;
                    console.log(infoFactura)
                    // Object.keys(infoFactura).forEach((id_factura, i) => {
                    //     var factura = Object.values(infoFactura)[i];
                    //     var facturaObj = {};
                    //     Object.keys(factura).forEach((element, j) => {
                    //         facturaObj[element] = Object.values(factura)[j];
                    //     });
                    //     facturas = [...facturas, facturaObj];
                    // });

                    const timer2 = setTimeout(() => {
                        setContadorSpinner(false);
                        setDatos(infoFactura);
                        mostrarError("Actualizado", "success");
                    }, 1000);

                    return () => clearTimeout(timer2);

                } else if (error.id >= 501 && error.id <= 510) {
                    localStorage.setItem('token', 'CADUCADO');
                    window.location.reload();
                } else if (error.id === 102) {
                    const timer2 = setTimeout(() => {
                        setContadorSpinner(false);
                        setDatos('');
                        mostrarError(error.msg);
                        console.log(error.msg);
                    }, 1000);
                    return () => clearTimeout(timer2);
                } else {
                    const timer2 = setTimeout(() => {
                        setContadorSpinner(false);
                        setDatos('');
                        mostrarError(error.msg);
                        console.log(error.msg);
                    }, 1000);
                    return () => clearTimeout(timer2);
                }
            })
    };

    const eliminarFiltros = () => {
        setDatosFiltro({
            id_factura: '',
            n_factura: '',
            cif: '',
            mostrar: '0',
            fecha_desde: '',
            fecha_hasta: ''
        });
    }

    const columnasFacturas = [
        {
          headerClassName: 'header-grid',
          field: 'id_factura',
          headerName: 'ID Factura',
          width: 120,
        },
        {
          headerClassName: 'header-grid',
          field: 'n_factura',
          headerName: 'N° Factura',
          width: 150,
        },
        {
          headerClassName: 'header-grid',
          field: 'cif',
          headerName: 'CIF',
          width: 180,
        },
        {
          headerClassName: 'header-grid',
          field: 'url',
          headerName: 'Ver Factura',
          width: 120,
          renderCell: (params) => {
            const verFactura = () => {
              window.open(params.row.url, '_blank'); // Abre la URL en una nueva pestaña
            };
            return (
              <Button
                variant="contained"
                color="primary"
                onClick={verFactura}
                size="small"
              >
                Ver
              </Button>
            );
          },
        }
      ];

    useEffect(() => {
        let listaHoteles = JSON.parse(hoteles);
        listaHoteles.unshift({ "value": "0", "label": "..." });
        setHotelesLista(listaHoteles);

        //Si tenemos un hotel guardado en el localStorage utilizando Ajustes.
        let idHotel = localStorage.getItem('idHotelSeleccionado');
        if (estadoInicial) {
            if (idHotel) {
                setHotelTrabajo(idHotel);
                setEstadoInicial(false);
            } else {
                console.log(listaHoteles);
            }
        }
        getFacturas();

    }, [hotelTrabajo, actualizo]);

    useEffect(() => {
        setTablaFacturas(
          <DataGrid
            className='grid'
            rowHeight={48}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'blanco' : 'fondogris blanco'
            }
            getRowId={(row) => row.id_factura}
            rows={datos} 
            columns={columnasFacturas}
            pageSize={8}
            rowsPerPageOptions={[8]}
            localeText={{
              noRowsLabel: "No se han encontrado datos.",
              noResultsOverlayLabel: "No se ha encontrado ningún resultado",
            }}
          />
        );
      }, [datos]);

    return (
        <>
            <div className='container'>
                <div className='content'>
                    <div className='article'>
                        <div>
                            <div className='header'>
                                <div className='containerAcciones'>
                                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                                        <HtmlTooltip
                                            placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">{"Filtrar"}</Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <Button
                                                className={activoFiltro === true ? "boton filtro pintado" : "boton filtro"}
                                                onClick={mostrarFiltros}
                                            >
                                                <FilterAltIcon className='icon-button' />
                                            </Button>
                                        </HtmlTooltip>

                                        <HtmlTooltip
                                            placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                                            title={
                                                <React.Fragment>
                                                    <Typography color="inherit">{"Actualizar"}</Typography>
                                                </React.Fragment>
                                            }
                                        >
                                            <Button
                                                className="boton"
                                                onClick={actualizar}
                                            >
                                                <RotateLeftIcon className='icon-button' />
                                            </Button>
                                        </HtmlTooltip>
                                    </ButtonGroup>
                                </div>
                                <div className='grid-container'>
                                {tablaFacturas}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='aside' >

                        <div className='header'>
                            <TextField
                                className='fifty'
                                variant="outlined"
                                margin="normal"
                                label="Hotel"
                                name="id_hotel"
                                size="small"

                                value={hotelTrabajo ? hotelTrabajo : ""}
                                onChange={cambiaHotelTrabajo}
                                select
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                        }
                                    }
                                }}
                            >
                                {hotelesLista.map((hotel) => (
                                    <MenuItem key={hotel.value} value={hotel.value} >
                                        {hotel.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='grid-container'>

                        </div>
                    </div>
                </div>
            </div>

            <Dialog fullScreen={fullScreen}
                open={estadoFiltros}
                onClose={comprobarESC}
                aria-labelledby="responsive-dialog-title" id="dialogoFiltros">
                <DialogTitle>Filtros</DialogTitle>
                <DialogContent >
                    <form>
                        <div className='bloque-inputs-filtros '>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                type="text"
                                label="ID Factura"
                                onKeyPress={(ev) => { comprobarEnter(ev) }}
                                name="id_factura"
                                size="small"
                                value={datosFiltro.id_factura ? datosFiltro.id_factura : ''}
                                onChange={actualizarFiltros}
                                autoComplete="off"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                type="text"
                                label="Nº Factura"
                                onKeyPress={(ev) => { comprobarEnter(ev) }}
                                name="n_factura"
                                size="small"
                                value={datosFiltro.n_factura ? datosFiltro.n_factura : ''}
                                onChange={actualizarFiltros}
                                autoComplete="off"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                type="text"
                                label="CIF"
                                onKeyPress={(ev) => { comprobarEnter(ev) }}
                                name="cif"
                                size="small"
                                value={datosFiltro.cif ? datosFiltro.cif : ''}
                                onChange={actualizarFiltros}
                                autoComplete="off"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                label="Facturas"
                                name="mostrar"
                                size="small"
                                value={datosFiltro.mostrar ? datosFiltro.mostrar : ''}
                                onChange={actualizarFiltros}
                                select
                            >
                                {tipoMostrar.map((mostrar) => (
                                    <MenuItem key={`filtros-${mostrar.value}`} value={mostrar.value}>
                                        {mostrar.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className="colorGrupo botonBorrarFiltros" onClick={eliminarFiltros}>Limpiar Filtros</Button>
                    <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                        <Button className="colorGrupo" onClick={ocultarFiltros2}>Cancelar</Button>
                        <Button className="colorGrupo" onClick={guardarDatos}>Filtrar</Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
            <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
            <CargaSpinner contadorSpinner={contadorSpinner} />
        </>
    )
}
export default PreConforme;
