import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { set_id } from '../../parteDucks'
import { useNavigate } from 'react-router-dom';

import {
	Tooltip, styled, InputAdornment, tooltipClasses, Zoom, Typography, IconButton, ButtonGroup, ListItemText, TextField, Dialog, useTheme, useMediaQuery, Button, DialogTitle, DialogContent,
	DialogActions, MenuItem, Box, List, ListItem, ListItemButton, Autocomplete, Collapse, OutlinedInput, InputLabel, Chip, FormControl
} from '@mui/material';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PrintIcon from '@mui/icons-material/Print';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Select from "@mui/material/Select";


import MostrarError from '../AccionesPartes/MostrarError';
import './DatosParte.css';

function DatosParte({ tipoSalidas, datosParteAParte }) {
	const API_URL = 'https://api.workers.grupotel.com/';
	const Api_Imagen = 'https://api.workers.grupotel.com/imagenes/parte/';
	const Api_Antiguo_Imagen = 'https://api.grupotel.com/workers/imagenes/partes/';

	const HtmlTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));

	let departamento_id = localStorage.getItem('id_departamento');

	const recargarPartes = () => {
		datosParteAParte(true);//Llamamos a la funcion de Parte y le pasamos nuetro boolean
		const timer2 = setTimeout(() => {
			datosParteAParte(false);//Para evitar bucles infinitos. 
		}, 1000);
		return () => clearTimeout(timer2);
	}
	const [objeto, setObjeto] = React.useState([]);
	const parte = useSelector(state => state.partes.array);
	const [infoParteTemp, setInfoParteTemp] = React.useState([]);
	const [modoLectura, setModoLectura] = React.useState(false);

	const [textoBusqueda, setTextoBusqueda] = useState('');

	//Variable para controlar los botones Play, Pause y Stop. Si es false se muestra solo el Play. Si es true se muestran el Pause y Stop.
	const [ocultarPlay, setOcultarPlay] = React.useState(false);
	const [ocultarPauseStop, setOcultarPauseStop] = React.useState(false);
	//Boton de Edición.
	const [ocultarBotonEdición, setOcultarBotonEdición] = React.useState(false);
	const [ocultarBotonInforme, setOcultarBotonInforme] = React.useState(false);

	const [actualizo, SetActualizo] = useState(true);
	const [mostrado, SetMostrado] = useState(true);

	//Guardar en personName el string ,  de Usuario Asignado.
	const [personName, setPersonName] = useState([]);

	//Listado de los Usuarios con el mismo id Departamento.
	const [usuariosLista, setUsuariosLista] = useState([]);
	//Lista de los proveedores
	const [terceros, setTercerosLista] = useState([]);
	const [mostrarTerceros, setMostrarTerceros] = useState(false);
	//Lista del Tipo de Terceros.
	const [tipoTerceros, setTipoTercerosLista] = useState([]);

	//Boolean utilizado para habilitar o deshabilitar el boton de atras en Nuevo Parte. Hace referencia a los departamentos.
	const [activarAtras, setActivarAtras] = useState(false);
	//Array que contiene el resultado de getLocationsList. Obtiene un listado de todos los departamentos.
	const [todosDepartamentos] = useState([]);
	//Array temporal para comprobar si hay departamentos con el mismo idPadre.
	const [departamentosListado] = useState([]);
	//Array utilizada para mostrar los departamentos, en Crear Parte.
	const [departamentosNuevoParte] = useState([]);

	//Conjunto de listas donde guardamos un string de los id de Departamentos a los que damos click. Y lista de los nombres de departamentos.
	const [rutaActual, setRutaActual] = useState({ idDepartamento: '', nombre: '' });
	//Variable que utilizamos como contador. Para saber en que posición estamos de los departamentos.
	const [posicionActual, setPosicionActual] = useState(0);
	//Variable para mostrar la ruta de Lugares/Departamentos del Parte. O contendra un "Seleccionar Lugar/Departamento".
	const [departamentoInicial, setDepartamentoInicial] = useState("");
	//Variable para controlar si se esta mostrando el Listado de Departamentos en Editar Parte. 1 no se muestra. 0 se muestra.  
	const [estadoListadoDepartamentos, setEstadoListadoDepartamentos] = useState(0);

	//Variable donde se guardara la imagen seleccionada en Editar Parte.
	const [listaImagenEditarParte, setListaImagenEditarParte] = useState([]);
	const [listaPDFEditarParte, setListaPDFEditarParte] = useState([]);
	const [albaranHistorico, setAlbaranHistorico] = useState([]);

	//Boolean para mostrar o cultar el dialog que contendrá las imagenes del parte seleccionado.
	const [galeriaImagenes, setGaleriaImagenes] = React.useState(false);
	//Posicion en la lista de Imagenes
	const [posImage, setPosImage] = useState(0);
	//Boolean para mostrar o cultar el dialog que contendrá los PDF del parte seleccionado.
	const [pdfParte, setPdfParte] = React.useState(false);

	const [controlError, setControlError] = useState(false);

	const [departamentosFiltrados, setDepartamentosFiltrados] = useState([]);

	const estadoInicial = [{
		id: '',
		fecha_creacion: '',
		creador: '',
		id_usuario: '',
		usuario_asignado: '',
		fecha_aplazado: '',
		id_hotel: '',
		hotel: '',
		bloque: '',
		planta: '',
		hab: '',
		operacion: '',
		prioridad: '',
		tipo: '',
		departamento: '',
		titulo: '',
		observaciones: '',
	}]
	const [datosEditarParte, setDatosEditarParte] = useState(estadoInicial);

	// Han solicitado mostrar los partes activos o la lista de departamentos de 'Cambiar Lugar'
	const [mostrarPartes, setMostrarPartes] = useState(false);
	//Partes de la habitación buscada.
	const [listaPartesRoom, setListaPartesRoom] = useState([]);

	const fd = new FormData();
	const requestOptions = {
		method: 'POST',
		body: fd
	};

	const actualizarListaPartes = () => {
		SetActualizo(!actualizo);
	};
	//Dialog para confirmar.
	const [openConfirm, setOpenConfirm] = useState(false);
	const [casoBotonEditar, setCasoBotonEditar] = useState(false);
	const [partesActivos, setPartesActivos] = useState([]);

	//Información parte abierto

	const handleOpenConfirm = () => {
		setPartesActivos([]);
		setOpenConfirm(false);
		//setInfoParteTemp();
	}
	const handleSearchText = (e) => {
		const searchText = e.target.value.toLowerCase();
		setTextoBusqueda(searchText);
		const departamentosEncontrados = departamentosNuevoParte.filter(departamento =>
			departamento.label.toLowerCase().includes(searchText)
		);
		setDepartamentosFiltrados(departamentosEncontrados);
	}

	//Conjunto para mostrar un Alert con el resultado de la petición
	const [mensajeError, setMensajeError] = useState('');
	const [tipoError, setTipoError] = useState('');
	const [contadorError, setContadorError] = useState(1);
	const [estadoAlert, setEstadoAlert] = React.useState(false);

	//Mostrar Error y vaciar el Objeto de Datos Parte
	const mostrarErrorObjeto = (mensajeError) => {
		setMensajeError(mensajeError);
		setContadorError(contadorError + 1);
		setTipoError("error");
		setEstadoAlert(true);
		const timer2 = setTimeout(() => {
			setEstadoAlert(false);
			setObjeto([]);
		}, 1000);
		return () => clearTimeout(timer2);
	};

	//Funcion basica para mostrar alert de errores o success.
	const mostrarError = (mensajeError, tipoError) => {
		setMensajeError(mensajeError);
		if (tipoError) {
			setTipoError(tipoError);
		} else {
			setTipoError("error");
		}
		setContadorError(contadorError + 1);
		setEstadoAlert(true);
		const timer2 = setTimeout(() => {
			setEstadoAlert(false);
		}, 1000);
		return () => clearTimeout(timer2);
	};

	const historialParte = (parte) => {
		if (parte != "") {//Comprobar si nos llega un idParte
			SetMostrado(true)
			const fd = new FormData();

			fd.append("f", "partes.get");
			fd.append("token", localStorage.getItem('token'));
			fd.append("id", parte);
			//En el caso de ser salidas le añadimos el parametro para poder visualizar la informacion de partes de otros departamentos.
			if (tipoSalidas == 1) {
				fd.append("salidas", 1);
			}
			const requestOptions = {
				method: 'POST',
				body: fd
			};
			let datosParte = [];
			let historicosParte = [];
			let fotosParte = [];
			let temp = [];
			fetch(API_URL, requestOptions)
				.then(response => response.json())
				.then(result => {
					let error = result.error;
					if (error.id === 0) {
						// console.log("Información del parte")
						// console.log(result)
						datosParte = result.parte.datos;
						historicosParte = result.parte.historico;
						fotosParte = result.parte.fotos;
						temp.push(datosParte);
						temp.push(historicosParte);
						temp.push(fotosParte);
						setObjeto(temp);
						obtenerUsuariosDepartamento(datosParte.id_hotel);
						setInfoParteTemp(datosParte);
						//Comprobamos si el ultimo historico tiene usuarios asignados.

						if (historicosParte != undefined && historicosParte != []) {
							if (historicosParte[0].id_usuarios !== '' && historicosParte[0].id_usuarios !== null) {
								let asignados = crearArrayUsuariosAsignados(historicosParte[0].id_usuarios, historicosParte[0].asignados);
								setPersonName(asignados);
							} else {
								setPersonName([]);
							}

							//Obtenemos el estado del ultimo historico del parte.
							if (departamento_id == datosParte.id_departamento) {
								if (historicosParte[0].estado === "nuevo" || historicosParte[0].estado === "asignado") {
									//Activar play 	Nuevo/Asignado
									setOcultarPlay(false);
									setOcultarPauseStop(true);
								} else if (historicosParte[0].estado === "en ejecucion" || historicosParte[0].estado === "en-ejecucion") {
									//Caso de Activar el Pause y Stop. 	En ejecucion	
									setOcultarPauseStop(false);
									setOcultarPlay(true);
								} else {
									//Caso Ocultar todos los botones. Terceros/Cerrado/Anulado
									setOcultarPlay(true);
									setOcultarPauseStop(true);
								}
							} else {
								setOcultarPlay(true);
								setOcultarPauseStop(true);
							}
							if (historicosParte.length > 0) {
								const initialOpenState = {};
								historicosParte.forEach((historia, i) => {
									initialOpenState[historia.fecha_evento + i] = true;
								});
								setOpenCollapse(initialOpenState);
							}
						} else {
							setPersonName([]);
							setOcultarPlay(true);
							setOcultarPauseStop(true);
						}

						mostrarError("Historico parte: " + parte, "success");
					} else if (error.id >= 501 && error.id <= 510) {
						localStorage.setItem('token', 'CADUCADO');
						mostrarErrorObjeto("Token caducado. Vuelva a Iniciar sesion");
						console.log("Token caducado. Vuelva a Iniciar sesion");
						window.location.reload();
					} else {
						mostrarErrorObjeto(error.msg);
						console.log(error.msg);
					}
				})
		} else {
			SetMostrado(false)
		}
	}



	const buscarPartesHabitacion = () => {

		//Peticion para obtener todos los partes de la habitacion 
		if (infoParteTemp.habitacion !== '' && infoParteTemp.habitacion !== undefined && infoParteTemp.habitacion !== '0') {
			fd.append("f", "partes.getByRoom");
			fd.append("token", localStorage.getItem('token'));
			fd.append("id_hotel", infoParteTemp.id_hotel);
			fd.append("hab", infoParteTemp.habitacion);
			fd.append("abiertos", 1);
			fetch(API_URL, requestOptions)
				.then((response) => {
					return response.json()
				})
				.then((result) => {
					let error = result.error;
					if (error.id === 0) {
						delete result.error;
						setListaPartesRoom(result.partes);
						//console.log(result);
					} else if (error.id === 102) {
						setListaPartesRoom('');
					} else {
						//console.log(result);
						setListaPartesRoom('');
						mostrarError(error.msg);
						console.log(error.msg);
					}
				})
				.catch(() => {
					mostrarError("Error Api. Obtener los partes de una habitacion.");
					console.log("Error Api. Obtener los partes de una habitacion.");
				})
		} else {
			setListaPartesRoom(''); // Vaciamos el listado de partes.
		}
	}
	//Boolean para mostrar los historicos del parte que le indiquemos.
	const [isOpenCollapse, setIsOpenCollapse] = useState(null);
	const abrirHistorialParte = (clickedIndex) => {
		if (isOpenCollapse === clickedIndex) {
			setIsOpenCollapse(null);
		} else {
			setIsOpenCollapse(clickedIndex);
		}
	};



	//const [isOpenCollapse, setIsOpenCollapse] = useState(null);
	const [openCollapse, setOpenCollapse] = useState({}); // Usamos un objeto para rastrear los estados de los Collapse

	const abrirHistorial = (clickedIndex) => {
		// if (isOpenCollapse === clickedIndex) {
		// 	setIsOpenCollapse(null);
		// } else {
		// 	setIsOpenCollapse(clickedIndex);
		// }
		setOpenCollapse((prevState) => ({
			...prevState,
			[clickedIndex]: !prevState[clickedIndex],
		}));
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const crearArrayUsuariosAsignados = (listaIdUsuarios, listaNombreUsuario) => {
		let personas = [];
		if (listaIdUsuarios != "" && listaIdUsuarios != null && listaNombreUsuario != null && listaNombreUsuario != "") {

			let ids = [];
			if (listaIdUsuarios.split(",")) {//Tenemos que ordenar los id de usuario por orden numerico. De menor a mayor.
				ids = listaIdUsuarios.split(",").sort((a, b) => a - b);
			} else {
				ids = listaIdUsuarios;
			}

			let nombres = [];
			if (listaNombreUsuario.split(",")) {//El sql nos devuelve ya los nombres ordenados por id de menor a mayor.
				nombres = listaNombreUsuario.split(",");
			} else {
				nombres = listaNombreUsuario;
			}

			// Crear un array de objetos con cada ID y su nombre correspondiente
			personas = ids.map((id, index) => ({
				value: id,
				label: nombres[index]
			}));
		}
		return personas;
	}

	//Funcion utilizada para guardar el listado de Usuario con el mismo departamento en usuariosLista.
	//Esta función nos retorna el id del ultimo usuario asignado del parte actual.
	const obtenerUsuarios = () => {
		//Guardara el id del usuario asignado si existe, en caso contrario guardara solo ""
		let idUsuario = [];

		fd.append("f", "usuarios.getList");
		fd.append("token", localStorage.getItem('token'));
		let str = "";
		str = "id_hotel=" + datos.id_hotel + "|id_departamento=" + localStorage.getItem('id_departamento') + '|'

		fd.append("params", str);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				delete result.error;

				//Recorremos el array de Usuarios para obtener sus id de Usuario y nombres.
				Object.keys(result.usuarios).map((id, i) => {
					// var nombreUsuario = Object.values(result.usuarios)[i].nombre;
					// usuarios.push({ value: id, label: nombreUsuario });

					//Comprobamos si hay un usuario asignado en el ultimo historico del parte.
					if (historico[0].usuario_asignado) {
						//Comprobamos que tengan el mismo nombre y guardamos el id.
						if (Object.values(result.usuarios)[i].nombre == historico[0].usuario_asignado) {
							//idUsuario = id;
							idUsuario.push(id);
						}
					}
				})
				//Guardamos el listado de Usuarios para el Select de Editar Parte.
				//setUsuariosLista(usuarios);

				//En el caso de no haber encontrado al Usuario o que no tenga Usuario Asignado.
				if (idUsuario.length == 0) { idUsuario.push("") }
				return idUsuario;
			})
			.catch(() => {
				mostrarError("No se ha podido listar los Usuarios.");
				console.log("No se ha podido listar los Usuarios.");
			})
		return idUsuario;
	}

	const obtenerUsuariosDepartamento = (hotel_id) => {
		let temp = [];
		fd.append("f", "usuarios.getList");
		fd.append("token", localStorage.getItem('token'));
		let str = "";
		str = "id_hotel=" + hotel_id + "|id_departamento=" + localStorage.getItem('id_departamento') + '|';
		fd.append("params", str);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				delete result.error;

				//Recorremos el array de Usuarios para obtener sus id de Usuario y nombres.
				Object.keys(result.usuarios).map((id, i) => {
					var nombreUsuario = Object.values(result.usuarios)[i].nombre;
					//temp.push([id,nombreUsuario]);
					temp.push({ value: id, label: nombreUsuario });
				})
				//Guardamos el listado de Usuarios para el Select de Editar Parte.
				setUsuariosLista(temp);
			})
			.catch(() => {
				mostrarError("No se ha podido listar los Usuarios.");
				console.log("No se ha podido listar los Usuarios.");
			})
	}

	//Funcion para obtener los campos del Parte seleccionado.
	const editarTemporal = (idUsuario, tipoOperacion) => {//Recibimos el IdUsuario conectado. Y el tipo de operacion a realizar en el parte.
		let operacion = "";
		if (tipoOperacion) { operacion = tipoOperacion; }
		vaciarDepartamentos();
		datosEditarParte.length = 0;
		//Obtenemos la ultima fecha de Aplazado si existe     11-02-2023 08:00:00
		let fecha = "";
		let asignado = "";
		if (historico != undefined && historico != []) {
			fecha = historico[0].fecha_aplazado;
			asignado = historico[0].usuario_asignado;
		}

		//Creamos un array para separar la fecha de la hora   ['11-02-2023', '08:00:00']
		let soloFecha = fecha.split(" ");
		let fechaAplazada;
		//Obtenemos unicamente la fecha y le damos la vuelta para poder imprimir en el Textfield tipo Date. 2023-02-11
		fechaAplazada = soloFecha[0].split("-").reverse().join("-");
		// console.log("Información del parte");
		// console.log(datos);
		//Guardamos en el temporal la ruta de Departamentos/Lugares.
		setDepartamentoInicial(datos.titulo);
		//Obtenemos todos los datos del parte actual.Para mostrar en el Dialog Editar Parte.
		setDatosEditarParte({
			id: datos.id_parte,
			fecha_creacion: datos.fecha_creacion,
			creador: datos.creador,
			id_usuario: idUsuario,
			usuario_asignado: asignado,
			fecha_aplazado: fechaAplazada,
			id_hotel: datos.id_hotel,
			hotel: datos.hotel,
			bloque: datos.bloque,
			planta: datos.planta,
			hab: datos.habitacion,
			operacion: operacion,
			prioridad: datos.ultima_prioridad,
			tipo: datos.tipo,
			departamento: datos.departamento_creador,
			titulo: datos.titulo,
			observaciones: ''
		})
	}

	/**************************************************************/
	// 			DEPARTAMENTOS / LUGARES
	//**************************************************************/

	/**************************************************************/
	//Modificación de Departamentos.
	/**************************************************************/
	const buscarDepartamentos = (id_hotel) => {
		if (id_hotel != 0) {
			fd.append("f", "partes.getLocationsList");
			fd.append("token", localStorage.getItem('token'));
			fd.append("id_hotel", id_hotel);
			fetch(API_URL, requestOptions)
				.then(response => response.json())
				.then(result => {
					let error = result.error;
					if (error.id == 0) {
						let departamentosLista = result;
						//Funcion para limpiar las listas.
						vaciarDepartamentos();
						//Guardamos todos los departamentos.
						todosDepartamentos.push(departamentosLista);
						//LLamamos a la funcion para mostrar los departamentos padre con idPadre 0
						obtenerDepartamento(0);
					}
				})
				.catch(() => {
					mostrarError("No se ha podido obtener departamentos.");
					console.log("No se ha podido obtener departamentos.");
				})
		}
	};

	//Obtiene el id del Padre y mapea todos los departamentos con ese mismo idPadre.
	const obtenerDepartamento = (idPadre, textoPadre, back) => {

		let departamentosLista = todosDepartamentos[0];
		departamentosListado.length = 0;

		//Comprobar y obtener los que tengan el idPadre
		Object.keys(departamentosLista).map((index, i) => {
			if (index != 'error') {
				var departamento = Object.values(departamentosLista)[i];
				if (departamento.id_padre == idPadre) {
					departamentosListado.push({ value: departamento.id_lugar, label: departamento.nombre, padre: departamento.id_padre });
				}
			}
		})
		if (departamentosListado.length !== 0) {
			// departamentosListado.sort((a, b) => (a.label > b.label) ? 1 : -1);
			departamentosListado.sort((a, b) => {
				// Manejar el caso en el que no se proporciona un índice
				if (a.indice === undefined && b.indice === undefined) {
					// Si ambos elementos no tienen índice, ordenar alfabéticamente por nombre
					if (a.label < b.label) return -1;
					if (a.label > b.label) return 1;
					return 0;
				} else if (a.indice !== undefined && b.indice !== undefined) {
					// Si ambos elementos tienen índice definido, ordenar por índice
					return a.indice - b.indice;
				} else {
					// Si uno tiene índice definido y el otro no, el que tenga índice definido debe ir primero
					return a.indice !== undefined ? -1 : 1;
				}
			});

			departamentosNuevoParte.length = 0;
			departamentosNuevoParte.push(...departamentosListado);
			//Si es Departamento Padre inicializamos la posicion.
			if (idPadre === 0) {
				setPosicionActual(1);
			} else {
				//Si es un sub departamento guardamos la posicion.
				setPosicionActual(posicionActual + 1);
			}
		}
		//En el caso de no ser un back .
		if (back == undefined) {
			//Caso de un Sub departamento.
			//Si tenemos id del Departamento y la posicionActual no es 0
			if (rutaActual.idDepartamento != '' && posicionActual >= 1) {
				var listaRutas = rutaActual.idDepartamento.split(",");
				setActivarAtras(false);

				//Funcion para cambiar el contenido del ultimo id y nombre.
				const actualizarUltimo = () => {
					let rutaId = rutaActual.idDepartamento.split(",");
					rutaId[listaRutas.length - 1] = idPadre;
					let rutaDepartamento = rutaActual.nombre.split("-");
					rutaDepartamento[listaRutas.length - 1] = textoPadre;
					setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
				}
				//Si el ultimo id es igual a idPadre. Y estamos en la misma posicion reemplazamos el contenido.
				if (listaRutas[listaRutas.length - 1] == idPadre && posicionActual == listaRutas.length) {
					actualizarUltimo();
				} else {
					//Caso de que en la misma posicion se ha introducido un id distinto.
					//Se compara que estemos en la misma posicion,que el tamaño del array. Y si la ultima posicion tiene un id diferente lo reemplazamos.
					if (posicionActual == listaRutas.length && listaRutas[listaRutas.length - 1] != idPadre) {
						actualizarUltimo();
					} else {
						//Si la posicion es la misma, pero el id es distinto añdimos le nuevo valor.
						let rutaId = rutaActual.idDepartamento + "," + idPadre;
						let rutaDepartamento = rutaActual.nombre + "-" + textoPadre;
						setRutaActual({ idDepartamento: rutaId, nombre: rutaDepartamento });
					}
				}
			} else {
				//Caso inicial de idPadre 0
				if (textoPadre != undefined) {
					setActivarAtras(false);
					setRutaActual({ idDepartamento: idPadre, nombre: textoPadre });
				}
			}
		}
		actualizarListaPartes();
	};

	//Funcion de flecha para volver al departamento anterior.
	const backDepartamento = () => {
		//Obtenemos el penultimo id y texto
		let rutaId = rutaActual.idDepartamento.split(",");

		let rutaDepartamento = rutaActual.nombre.split("-");
		rutaId.pop();
		rutaDepartamento.pop();
		if (rutaId[rutaId.length - 1] != undefined) {
			//listaRutas[listaRutas.length - 1]
			obtenerDepartamento(rutaId[rutaId.length - 1], rutaDepartamento[rutaDepartamento.length - 1], 1);
			setPosicionActual(rutaId.length + 1);
			setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
		} else {
			rutaActual.idDepartamento = "";
			rutaActual.nombre = 'Nuevo parte';
			setRutaActual({ idDepartamento: "", nombre: "" });
			obtenerDepartamento(0);
			setActivarAtras(true);
		}
	}

	//Obtenemos el id del padre que puede venir del div o el span.
	const obtenerSubDepartamento = (event) => {
		// Si nos llega un onclick del texto. span obtenemos el contenido
		let id = event.target.parentElement.id;
		let texto = event.target.innerText;

		//Si no era un span el id estara vacio. En este caso obtenemos el id del div
		if (id === '') {
			id = event.target.id;
		}

		if (id != undefined) {
			obtenerDepartamento(id, texto);
			//actualizarListaPartes();
		}
	};

	const vaciarDepartamentos = () => {
		todosDepartamentos.length = 0;
		rutaActual.idDepartamento = "";
		rutaActual.nombre = 'Seleccione un Lugar/Departamento';
		setPosicionActual(0);
		setRutaActual({ idDepartamento: "", nombre: "" });
	};
	/**************************************************************/
	//  FIN		Modificación de Departamentos.
	/**************************************************************/

	/**************************************************************/
	// 		Funcionalidad de los botones / Eventos de presionar teclas
	/**************************************************************/

	const buscarHabitacion = () => {
		const datos = datosEditarParte;
		if (datos.id_hotel != '' && datos.hab != '' && datos.hab != undefined && datos.id_hotel != undefined) {
			fd.append("f", "habitaciones.get");
			fd.append("token", localStorage.getItem('token'));
			fd.append("id_hab", datos.hab);
			fd.append("id_hotel", datos.id_hotel);
			// let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
			fetch(API_URL, requestOptions)
				.then(response => response.json())
				.then(result => {
					if (result) {
						let infoPartes = result;
						setDatosEditarParte({ ...datosEditarParte, "bloque": infoPartes.bloque, "planta": infoPartes.planta })
					}
				})//En caso de error mostramos nuestro mensaje
				.catch(() => {
					mostrarError("No existe la habitación: " + datos.hab);
					console.log("No existe la habitación: " + datos.hab);
				})
		} else if (datos.hab == '' && datos.hab != undefined) {
			mostrarError("No ha seleccionado la habitación. ");
			console.log("No ha seleccionado la habitación. ");
		}
	};

	//Funcion para inicializar y mostrar los departamentos o Ocultarlos.
	const mostrarDepartamentos = () => {
		console.log(estadoListadoDepartamentos)
		if (estadoListadoDepartamentos == 0) {
			setEstadoListadoDepartamentos(1);
			setMostrarPartes(false);
			setActivarAtras(true);
			//Buscamos todos los departamentos relacionados con el Hotel.
			buscarDepartamentos(datos.id_hotel);
			setDepartamentoInicial("Seleccione un Lugar/Departamento");
			//Mostrar el boton para volver atras en un departamento
			document.querySelector('.botonAtras').classList.remove('ocultar');
			//Mostar el listado de Departamentos.
			document.querySelector('.departamentoEditar').classList.remove('ocultar');
			//Guardamos el 1 para indicar que se esta mostrando el Listado de Departamentos en Editar Parte.
		} else {
			setEstadoListadoDepartamentos(0);
			setDepartamentoInicial(datos.titulo);
		}
		// else {
		// 	setMostrarPartes(true);
		// 	//Guardamos en el temporal la ruta de Departamentos/Lugares.
		// 	setDepartamentoInicial(datos.titulo);

		// 	//Guardamos el 0 para indicar que se esta ocultando el Listado de Departamentos en Editar Parte.
		// 	setEstadoListadoDepartamentos(0);
		// }
	};

	const mostrarPartesAbiertos = () => {
		if (mostrarPartes == false) {
			setEstadoListadoDepartamentos(0);
			setMostrarPartes(true);
			setDepartamentoInicial(datos.titulo);
		} else {
			setMostrarPartes(false);

		}

	}

	//Funcion para editar la información del Parte.
	const confirmarEdicion = () => {
		if (datosEditarParte.operacion != "" && datosEditarParte.operacion != " ") {//Comprobamos que hayan seleccionado una Operacion/Estado del Parte. "Nuevo/Asignado,etc..."
			setControlError(false);
			if (datosEditarParte.operacion === "en ejecucion") { //En caso de ser en ejecución hay que comprobar si el usuario tiene mas partes en ejecución.
				asignarPartes("editar");
			} else {
				modificarParte();
			}
		} else {
			setControlError(true);
			mostrarError("Seleccione un Estado al Parte.");
			console.log("Seleccione un Estado al Parte.");
		}
	};

	//Función para realizar la modificación del parte con la información obtenida del formulario Editar Parte
	const modificarParte = () => {
		var str = '';

		//Comprobar los Usuarios asignados del Chip.
		//Si no tiene datos tenemos que introducir el usuario actual.
		if (personName.length >= 1) {//Comprobamos si hay asignado 1 usuario.
			//Creamos un array de los id de usuarios asignados en el chip.
			let usuarios = personName.map(function (usuario) {
				return usuario.value;
			});

			//Creamos un string con los id de usuarios seleccionados en el chip. Si solo hay uno obtenemos su id.
			let asignados = "";

			//? para verificar si historico[0] existe y tiene la propiedad usuario_asignado
			let id_asignado = ""//Si el ultimo historico tenia a una persona asignada lo obtenemos.
			if (historico != undefined && historico != []) {
				id_asignado = historico[0]?.id_asignado;
			}

			//Comprobamos si el primer usuario asignado sigue estando en el array del chip.
			if (usuarios.includes(id_asignado)) {
				//Obtenemos el indice del usuario asignado.
				const index = usuarios.findIndex(persona => persona === id_asignado);

				//Si no esta en la primera posición.
				if (index !== 0) {//Ponemos al usuario asignado el primero de la lista.
					usuarios.splice(index, 1); // Elimina el elemento encontrado
					usuarios.unshift(datosEditarParte.id_usuario); // Añade el elemento al inicio del array
				}
				asignados = usuarios.join(",");
				str += "id_usuario=" + usuarios[0] + "|";
				str += "id_usuarios=" + asignados + "|";
			} else {
				asignados = usuarios.join(",");

				str += "id_usuario=" + usuarios[0] + "|";
				str += "id_usuarios=" + asignados + "|";
			}
		} else {//Añadimos el usuario actual a los usuarios asignados.
			str += "id_usuarios=" + localStorage.getItem('id') + "|";
			str += "id_usuario=" + localStorage.getItem('id') + "|";
		}
		let operacion = '';
		//Creamos y establecemos el formato de los parametros que vamos a enviar.
		Object.keys(datosEditarParte).forEach((nombre, i) => {
			let paramValue = Object.values(datosEditarParte)[i];
			if (nombre != 'id_usuario') {
				//Comprobamos que el campo no sea la Fecha para aplazar el parte.
				if (nombre != 'fecha_aplazado') {
					if (Object.values(datosEditarParte)[i] != '' && Object.values(datosEditarParte)[i] != undefined) {
						if (nombre === 'observaciones') {
							let observacionesValue = Object.values(datosEditarParte)[i].replace(/\|/g, ' ');
							//Quitamos posible | 
							str += nombre + "=" + observacionesValue + "|";
							//str += nombre + "=" + "\'" + JSON.stringify(Object.values(datosEditarParte)[i]) + "\'" + "|";
						} else if (nombre === 'operacion') {
							operacion = paramValue
							str += nombre + "=" + paramValue + "|";
						} else {
							str += nombre + "=" + paramValue + "|";
						}
					}
				} else {
					//En el caso de ser Fecha Aplazada creamos un array para obtener la fecha
					let soloFecha = Object.values(datosEditarParte)[i].split(" ");
					//Comprobamos que la fecha no este vacia o venga con el valor 0000
					if (Object.values(datosEditarParte)[i] != "" && soloFecha[0] != "0000-00-00") {
						//Si la fecha viene con datos, le añadimos la hora 08:00
						str += nombre + "=" + Object.values(datosEditarParte)[i] + " 08:00:00|";
					} else {
						//Si la fecha vuelve vacia, le indicamos que su valor sera NULL
						str += nombre + "=NULL|";
					}
				}
			}
		});

		//En el caso de modificar la ruta Lugar/Departamento 
		if (rutaActual.idDepartamento !== 0 && rutaActual.idDepartamento !== null && rutaActual.idDepartamento !== "") {
			modificarTituloParte();
		}

		const fd = new FormData();
		const requestOptions = {
			method: 'POST',
			body: fd
		};
		fd.append("f", "partes.set");
		fd.append("token", localStorage.getItem('token'));
		fd.append("id_hotel", datos.id_hotel);
		fd.append("params", str); //Parametros Obligatorios: 'id_usuario', 'operacion', 'prioridad'. Tambien el id del Parte.
		//Recorremos el listado de Imagenes para añadirlas
		Object.values(listaImagenEditarParte).forEach((imagen, i) => {
			fd.append("imagen" + i, imagen.archivo);
		});

		//Recorremos los archivos pdf
		listaPDFEditarParte.forEach((pdf, i) => {
			fd.append("pdf" + i, pdf.archivo);
		});

		// let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				// console.log(result);
				if (result.error.id !== 0) { //En caso de error.
					mostrarError("Se ha actualizado el parte.", "success");

				} else {
					mostrarError(result.error.msg);
					console.log(result.error.msg);
				}
				if (operacion == 'cerrado') {

					set_id(0)
				}
				recargarPartes();

			})
			.catch(() => {
				mostrarError("No se ha actualizado el parte." + parte);
				console.log("No se ha actualizado el parte." + parte);
			})

		finEdicion();
		// console.log('OPERACION: ', operacion)

		if (operacion == 'cerrado') {

			SetMostrado(false)
			historialParte('');
		} else {

			historialParte(datos.id_parte);
		}
	}

	//Funcion para realizar el cambio de Titulo del Parte seleccionado.
	const modificarTituloParte = () => {
		const fd = new FormData();
		const requestOptions = {
			method: 'POST',
			body: fd
		};
		fd.append("f", "partes.setNewLugar");
		fd.append("token", localStorage.getItem('token'));
		fd.append("id", datosEditarParte.id);//Id del Parte
		fd.append("rama", rutaActual.idDepartamento);//Ruta del nuevo Lugar del Parte   ej:4,107,109
		// let params = "id_departamento=" + localStorage.id_departamento + "|" + concatParams;
		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.error.id !== 0) { //En caso de error.
					mostrarError(result.error.msg);
					console.log(result.error.msg);
				}
				//console.log("Se ha modificado el Titulo del Parte.");
			})
			.catch(() => {
				mostrarError("Error Api. No se ha podido modificar el Titulo del Parte");
				console.log("Error Api. No se ha podido modificar el Titulo del Parte");
			})
	}

	//Funcionalidad para realizar el cambio de estado del Parte.  Es la funcionalidad de los botones Play, Pause, Stop.
	const peticionesHistorico = (idParte, estado) => {
		const fd = new FormData();
		const requestOptions = {
			method: 'POST',
			body: fd
		};
		//Comprobamos si nos llega una petición de ejecución.

		fd.append("f", "partes.set");
		fd.append("token", localStorage.getItem('token'));
		//fd.append("id_hotel", datos.id_hotel);
		var str = "id=" + idParte + "|operacion=" + estado + "|";//Añadimos el Id del Parte y el Estado del Parte.
		//
		//Ahora el problema es k se tendria k obtener los id_usuarios actuales y añadir el usuario local.
		// id_usuarios
		//str += 'prioridad=baja|'; //Prioridad por defecto.
		str += "prioridad=" + datos.ultima_prioridad + "|";
		str += "id_hotel=" + datos.id_hotel + "|hab=" + datos.habitacion + "|";//Pasamos los datos actuales de el hotel y habitacion.
		//Comprobar los Usuarios asignados del Chip.
		if (personName.length >= 1) {
			let usuarios = personName.map(function (usuario) {
				return usuario.value;
			});

			//Creamos un string con los id de usuarios selccionados en el chip. Si solo hay uno obtenemos su id.
			let asignados = personName.length > 1 ? usuarios.join(",") : personName[0].value;

			//Comprobamos si esta nuestro usuario.
			const index = personName.findIndex(persona => persona.value === localStorage.getItem('id'));
			if (index === -1) {
				str += "id_usuarios=" + asignados + "," + localStorage.getItem('id') + "|";
			} else {
				str += "id_usuarios=" + asignados + "|";
			}
			str += "id_usuario=" + localStorage.getItem('id') + "|"

		} else {//Añadimos el usuario actual a los usuarios asignados.
			str += "id_usuario=" + localStorage.getItem('id') + "|"
			str += "id_usuarios=" + localStorage.getItem('id') + "|";
		}
		str += 'observaciones=' + estado + '|';
		fd.append("params", str);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.error.id === 0) {
					mostrarError("Parte: " + idParte + " Estado actual: " + estado, "success");
				} else {
					mostrarError(result.error.msg);
					console.log(result.error.msg);
				}
				recargarPartes();
				historialParte(idParte);
			})
			.catch(() => {
				mostrarError("No se ha podido actualizar el parte.");
				console.log("No se ha podido actualizar el parte.");
			})
	};

	// Función para comprobar si el usuario tiene partes en Ejecución. O si esta ayudando a un parte.
	const asignarPartes = (operacion) => {
		let userId = localStorage.getItem('id');
		//Primero comprobamos si el estado es 'en ejecucion'
		fd.append("f", "partes.getPartesEjecucion");
		fd.append("token", localStorage.getItem('token'));
		fd.append("id_usuario", userId);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {

				//Caso de Botón Editar
				if (operacion === "editar") {
					//Comprobar si hay partes en ejecución. Si es asi abrir el dialogo para asignar esos partes y continuar.
					if (result.error.id === 0) {
						delete result.error;

						let id_departamento = localStorage.getItem('id_departamento');

						if (id_departamento === 71) {
							// console.log("Los partes en ejecución son:");
							// console.log(result);
							// console.log(Object.keys(partesActivos));

							//Guardamos el result
							setPartesActivos(result);//Lista de los partes en ejecución

							//Comprobamos si el parte actual esta en esta lista de partes en ejecución.
							if (result.hasOwnProperty(datos.id_parte)) {
								modificarParte();
								historialParte(datos.id_parte);
							} else {
								//Espera confirmación cambios
								setOpenConfirm(true);
								setCasoBotonEditar(true);
							}
						} else {
							modificarParte();
							historialParte(datos.id_parte);
						}

					} else if (result.error.id === 102) {//Petición sin resultados.
						modificarParte();
						historialParte(datos.id_parte);
					} else {//Error en la petición
						mostrarError(result.error.msg);
						console.log(result.error.msg);
					}

				} else {
					//Caso de Botón en Ejecucion
					if (result.error.id === 0) {
						let id_departamento = localStorage.getItem('id_departamento');

						if (id_departamento == 71) {

							// console.log("Los partes en ejecución son:");
							// console.log(result);
							delete result.error;
							//Guardamos el result
							setPartesActivos(result);

							//Espera confirmación cambios
							setOpenConfirm(true);
							setCasoBotonEditar(false);
							historialParte(datos.id_parte);
						} else {
							modificarParte();
							historialParte(datos.id_parte);
						}

					} else if (result.error.id === 102) {//Petición sin resultados.
						//Como el usuario no tiene partes en ejecución. Se pone en ejecución el parte actual
						parteEjecucion();
						historialParte(datos.id_parte);
					} else {
						mostrarError(result.error.msg);
						console.log(result.error.msg);
					}
				}
			})
			.catch(() => {
				mostrarError("No se ha podido actualizar el parte.");
				console.log("No se ha podido actualizar el parte.");
			})
	}

	const confirmarUpdate = () => {
		let userId = localStorage.getItem('id');
		//se comprueba si la petición es del botón Editar
		if (casoBotonEditar === false) {
			//Ponemos el parte en ejecución.
			parteEjecucion();
		}

		//Recorremos el result de los partes anteriores, tenemos que comprobar en que campo se encuentra el usuario.
		// Si esta en id_usuario   significa que es el asignado principal.
		// Si esta en id_usuarios  significa que esta ayudando en el parte.

		for (const parteId in partesActivos) {
			const parte = partesActivos[parteId];
			if (parte.id_usuario === userId) {//El usuario esta asignado.
				//Hacer un fetch mandando la info del parte .
				parteAsignado(parteId, parte);
			} else {
				const idUsuarios = parte.id_usuarios.split(",");
				//Comprobamos si el usuario se encuentra en id_usuarios.
				if (idUsuarios.includes(userId)) {
					// Eliminar el usuario de id_usuarios
					const updatedIdUsuarios = idUsuarios.filter(id => id !== userId);
					const usuarioslista = updatedIdUsuarios.join(","); //string conjunto id_usuarios 
					// Hacemos la petición con los nuevos id_usuarios.
					quitarUsuario(parteId, parte, usuarioslista);
				}
			}
		}
		//En caso de ser una Edición de Parte
		if (casoBotonEditar === true) {
			modificarParte();
		}
	}

	//Función para poner el parte en Asignado.
	const parteAsignado = (parteId, parte) => {
		fd.append("f", "partes.set");
		fd.append("token", localStorage.getItem('token'));

		var str = "id=" + parteId + "|operacion=asignado|";//Añadimos el Id del Parte y el Estado del Parte.
		str += "id_usuario=" + parte.id_usuario + "|id_hotel=" + parte.id_hotel + "|hab=" + parte.hab + "|prioridad=" + parte.prioridad + "|";
		str += "id_usuarios=" + parte.id_usuarios + "|observaciones=Ha puesto el parte " + infoParteTemp.id_parte + " en ejecucion. Este parte queda en Asignado.|";
		fd.append("params", str);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.error.id !== 0) { //En caso de error.
					mostrarError(result.error.msg);
					console.log(result.error.msg);
				}
				if (result.error.id === 0) {
					historialParte(parteId);
				}
			})
			.catch(() => {
				mostrarError("No se ha podido actualizar el parte.");
				console.log("No se ha podido actualizar el parte.");
			})
	}

	//Función para crear un historico sin el usuario. En id_usuarios.
	const quitarUsuario = (parteId, parte, usuarioslista) => {
		//Hacer un fetch mandando la info del parte .
		fd.append("f", "partes.set");
		fd.append("token", localStorage.getItem('token'));

		var str = "id=" + parteId + "|operacion=" + parte.operacion + "|";//Añadimos el Id del Parte y el Estado del Parte.
		str += "id_usuario=" + parte.id_usuario + "|id_hotel=" + parte.id_hotel + "|hab=" + parte.hab + "|prioridad=" + parte.prioridad + "|";
		str += "id_usuarios=" + usuarioslista + "|observaciones=" + parte.operacion + "|";
		fd.append("params", str);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.error.id !== 0) { //Caso de error
					mostrarError(result.error.msg);
				}
			})
			.catch(() => {
				mostrarError("No se ha podido actualizar el parte.");
				console.log("No se ha podido actualizar el parte.");
			})
	}

	//Poner el parte en Ejecución .
	const parteEjecucion = () => {
		//console.log(infoParteTemp)

		fd.append("f", "partes.set");
		fd.append("token", localStorage.getItem('token'));
		//fd.append("id_hotel", datos.id_hotel);
		var str = "id=" + infoParteTemp.id_parte + "|operacion=en ejecucion|";//Añadimos el Id del Parte y el Estado del Parte.
		str += "prioridad=" + infoParteTemp.ultima_prioridad + "|";
		str += "id_hotel=" + infoParteTemp.id_hotel + "|hab=" + infoParteTemp.habitacion + "|";//Pasamos los datos actuales de el hotel y habitacion.

		//Comprobar los Usuarios asignados del Chip.
		if (personName.length >= 1) {
			let usuarios = personName.map(function (usuario) {
				return usuario.value;
			});

			//Creamos un string con los id de usuarios selccionados en el chip. Si solo hay uno obtenemos su id.
			let asignados = personName.length > 1 ? usuarios.join(",") : personName[0].value;

			//Comprobamos si esta nuestro usuario.
			const index = personName.findIndex(persona => persona.value === localStorage.getItem('id'));
			if (index === -1) {
				str += "id_usuarios=" + asignados + "," + localStorage.getItem('id') + "|";
			} else {
				str += "id_usuarios=" + asignados + "|";
			}
			str += "id_usuario=" + localStorage.getItem('id') + "|"
		} else {//Añadimos el usuario actual a los usuarios asignados.
			str += "id_usuario=" + localStorage.getItem('id') + "|"
			str += "id_usuarios=" + localStorage.getItem('id') + "|";
		}
		str += "observaciones=en ejecucion|";
		fd.append("params", str);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.error.id === 0) {
					mostrarError("Parte: " + datos.id_parte + " Estado actual: en ejecucion success", "success");
				} else {
					mostrarError(result.error.msg);
					console.log(result.error.msg);
				}
				recargarPartes();
				historialParte(datos.id_parte);

			})
			.catch(() => {
				mostrarError("No se ha podido actualizar el parte.");
				console.log("No se ha podido actualizar el parte.");
			})

	}
	// Funcion para ver el informe del parte.
	const verInforme = () => {
		let id_parte = datos.id_parte;
		let url = "https://api.workers.grupotel.com/pdf-lib/index.php?token=workers2&action=getPartePdf&id="
		let final_url = url + id_parte;
		window.open(final_url, '_blank', 'noreferrer')
	}
	const verAlbaran = (historia) => {
		// console.log(historia)
		let id_parte = datos.id_parte;
		let cif_proveedor = historia.cif_proveedor;
		let fichero = historia.fichero;
		let url = "https://api.workers.grupotel.com/albaran/" + id_parte + "/" + cif_proveedor + "/" + fichero;
		window.open(url, '_blank', 'noreferrer')
	}
	const borrarAlbaran = (historia) => {
		let fichero = historia.fichero;
		fd.append("f", "partes.deleteAlbaranHistorico");
		fd.append("token", localStorage.getItem('token'));
		fd.append("fichero", fichero);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				let error = result.error;
				if (error.id == 0) {
					historia.fichero = false;
					mostrarError("Albaran: " + fichero + " eliminado correctamente.", "success");
				}
			})
			.catch(() => {
				mostrarError("No se ha podido eliminar el Albaran: " + fichero);
				console.log("No se ha podido eliminar el Albaran: " + fichero);
			})
	}

	const nuevoAlbaran = (fichero, id, cont, cif_proveedor) => {
		fd.append("f", "partes.setAlbaranHistorico");
		fd.append("token", localStorage.getItem('token'));
		fd.append("imagen", fichero);
		fd.append("id", id);
		fd.append("cont", cont);
		fd.append("cif_proveedor", cif_proveedor);

		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				let error = result.error;
				if (error.id == 0) {
					mostrarError("Albaran: " + fichero.name + " añadido correctamente.", "success");
					setAlbaranHistorico([]);
					actualizarListaPartes();
				}
			})
			.catch(() => {
				mostrarError("No se ha podido añadir el Albaran: " + fichero.name);
				console.log("No se ha podido añadir el Albaran: " + fichero.name);
			})
	}


	//Función que obtiene el evento con los archivos seleccionados. El id del Parte, nº historico y cif del proveedor.
	const selecAlbaran = (event, id, cont, cif_proveedor) => {
		event.preventDefault(); // Detener la propagación del evento

		//Si le llega una imagen en el evento
		if (event.target.files[0] != undefined) {
			// //Crear una ruta para mostrar la imagen al usuario.
			// setImagenPreviaEditar(URL.createObjectURL(event.target.files[0]));

			// albaranHistorico.length = 0;
			// //Guardar todo el files por si hay varias imagenes.
			// Object.values(event.target.files).forEach((nombre, i) => {
			// 	albaranHistorico.push(nombre);
			// 	console.log(nombre.name);
			// });
			// console.log(albaranHistorico);
			nuevoAlbaran(event.target.files[0], id, cont, cif_proveedor);
		} else {
			setAlbaranHistorico([]);
		}
	};

	//Funcion para activar el Dialog de edicion del Parte.
	const modoEdicion = () => {
		obtenerDatosSelect();
		buscarPartesHabitacion(); // Función que obtiene todos los partes de la habitación
		editarTemporal(obtenerUsuarios());
		obtenerListaTerceros();
		setModoLectura(true);
		document.querySelector('.editar').classList.add('pintado');
		if (infoParteTemp.habitacion !== '0') {
			setMostrarPartes(true);
			setEstadoListadoDepartamentos(0);
		} else {
			setMostrarPartes(false);
			setEstadoListadoDepartamentos(0);
			//mostrarDepartamentos();
		}
	};

	//Funcion para Ocultar el Dialog de edicion del Parte.
	const finEdicion = () => {
		//Dejamos el datosEditarParte vacio.
		setDatosEditarParte([estadoInicial]);
		setMostrarTerceros(false);
		setListaImagenEditarParte([]);
		setListaPDFEditarParte([]);
		setModoLectura(false);
		document.querySelector('.editar').classList.remove('pintado');
		setEstadoListadoDepartamentos(0);
		actualizarListaPartes();
	};

	//Funcionalidad de los botones para cambiar el estado del parte.
	const modoPlay = () => {
		//Obtener el ultimo asignado. Si esta vacio ponemos el usuario login. Si tiene info añadimos al usuario al final.
		asignarPartes("play");
	};
	const modoPause = () => {
		//Obtener el ultimo asignado. Si esta vacio ponemos el usuario login. Si tiene info añadimos al usuario al final.
		peticionesHistorico(datos.id_parte, "asignado");
	};
	const modoStop = () => {
		obtenerDatosSelect();
		buscarPartesHabitacion(); // Función que obtiene todos los partes de la habitación
		//Comprobar si el usuario esta en asignados.
		editarTemporal(obtenerUsuarios(), "cerrado");
		obtenerListaTerceros();
		setModoLectura(true);
		actualizarListaPartes();

		if (infoParteTemp.habitacion !== '0') {
			setMostrarPartes(true);
			setEstadoListadoDepartamentos(0);
		} else {
			setMostrarPartes(false);
			setEstadoListadoDepartamentos(0);
			//mostrarDepartamentos();
		}
	};

	//Cada vez que se realiza un cambio en el Dialog de Editar Parte guardamos la nueva información. onChange
	const editarParte = (event) => {
		// console.log(event.target.name)
		// console.log(event.target.value)

		setDatosEditarParte({
			...datosEditarParte,
			[event.target.name]: event.target.value
		})
		if (event.target.name == "operacion" && (event.target.value == "terceros" || event.target.value == "cerrado")) {//Si es operacion. Comprobar si el Estado del parte es Terceros. Si es asi mostrar los select de Proveedores.
			setMostrarTerceros(true);
		} else if (event.target.name == "operacion") {//Si se ha realizado otro tipo de cambio en operacion ocultamos. Los proveedores
			if (event.target.value != "") {
				setControlError(false);
			} else {
				setControlError(true);
			}
			setMostrarTerceros(false);
		}

		//Petición de Cristian, si se aplaza modificar el estado del parte a Nuevo.
		// if (event.target.name == "fecha_aplazado" && datosEditarParte.operacion != "nuevo") {
		// 	setDatosEditarParte({
		// 		...datosEditarParte,
		// 		[event.target.name]: event.target.value,
		// 		['operacion']: 'nuevo'
		// 	})
		// }
		// Ha Isaias no le ha molado, ha pedido quitar esta funcionalidad
	}

	//Funcion para detectar que la tecla pulsada es ESC y ocultar el Dialog de EditarParte.
	const comprobarESCEditarParte = (event) => {
		if (event.keyCode == 27) {
			finEdicion();
		}
	}

	//Funcion para detectar si se pulsa el Enter en el Textfield de Habitacion.
	const comprobarEnterHab = (e) => {
		if (e.key == 'Enter') {
			buscarHabitacion();
		}
	};

	//Funcion para el select chip multiple
	const chipMultipleAsignados = (idChip, lectura) => {

		const modificarAsignados = (id, nombre) => {
			// Comprobar si ya existe el usuario.
			const index = personName.findIndex(persona => persona.value === id);
			if (index !== -1) {
				// El id ya está. Procedemos a borrarlo.
				//Primero creamos una copia de el array actual.
				const copiaPersonName = JSON.parse(JSON.stringify(personName));
				copiaPersonName.splice(index, 1);//Quitamos el indice de la array para borrar el usuario.
				setPersonName(copiaPersonName);
			} else {
				//El id no esta en nuestra array de Usuarios.
				const nuevoAsignado = { value: id, label: nombre };
				setPersonName([...personName, nuevoAsignado]);
			}
		}

		return (
			<FormControl fullWidth id={"select-chip-asignados-control-datosParte" + idChip}>
				<InputLabel id={"select-chip-asignados-label-datosParte" + idChip} >Asignados</InputLabel>

				<Select
					labelId={"select-chip-asignados-label-datosParte" + idChip}
					//id={"select-chip-asignados" + idChip}
					multiple
					//label="Asignados"
					//value={personName}
					value={historico ? (personName != [] && personName.length >= 1) ? personName : [''] : ['']}
					size="small"
					inputProps={lectura == true ? { readOnly: true, } : { readOnly: false, }}
					input={<OutlinedInput id={"select-multiple-chip-datosParte" + idChip} label="Asignados" />}
					renderValue={() => (
						<Box
							className="chipNombres"
							size="small"
						>
							{personName != [] && personName.length >= 1 ?
								personName.map((persona) => (
									<Chip key={`asignado-datosParte-${persona.value}`} label={persona.label} className={'grupotel-blue'} />
								))
								: <Chip key={`asignado-datosParte-0`} label={' '} />}
						</Box>
					)}
				>
					{usuariosLista != "" ?
						usuariosLista.map((usuario) => (
							<MenuItem key={`mostrarUsuarios-${usuario.value}`} value={usuario.value} onClick={() => modificarAsignados(usuario.value, usuario.label)} className={personName.find(persona => persona.value === usuario.value) ? 'grupotel-blue' : ''} >
								{usuario.label}
							</MenuItem>
						))
						: null
					}
				</Select>
			</FormControl>
		);
	};

	const obtenerSelectEstados = () => {
		let listaEstados = tipoEstadosDefault;
		let departamento = localStorage.getItem('id_departamento');
		if (tipoSalidas === 1 && departamento != datos.id_departamento) {
			listaEstados = tipoEstadosAnulado;
		}

		return (
			<TextField
				className='proveedoresTextfield'
				variant="outlined"
				margin="normal"
				label="Estado"
				name="operacion"
				id="tipoOperacion"
				error={controlError}
				onChange={editarParte}
				size="small"
				value={datosEditarParte.operacion ? datosEditarParte.operacion : ' '}
				select
			>
				{listaEstados.map((tipoEstado) => (
					<MenuItem key={`editEstadoDatosP-${tipoEstado.valor}`} value={tipoEstado.valor}>
						{tipoEstado.nombre}
					</MenuItem>
				))}
			</TextField>
		);
	};
	/**************************************************************/
	//  		 Proveedores
	/**************************************************************/
	//Obtener el listado de Terceros. Los Proveedores.
	const obtenerListaTerceros = () => {
		fd.append("f", "proveedores.getList");
		fd.append("token", localStorage.getItem('token'));
		fetch(API_URL, requestOptions)
			.then(response => response.json())
			.then(result => {
				let error = result.error;
				if (error.id == 0) {
					let listaTerceros = [];
					let tipoTerceros = [];
					listaTerceros.push({ value: "", label: "" });
					Object.values(result.proveedores).map((index, i) => {
						listaTerceros.push({ value: index['cif'], label: index['nombre_comercial'] });
					})
					setTercerosLista(listaTerceros);

					tipoTerceros.push({ value: "", label: "" });
					Object.values(result.tipo_terceros).map((tipo, i) => {
						tipoTerceros.push({ value: Object.keys(result.tipo_terceros)[i], label: tipo });
					})

					setTipoTercerosLista(tipoTerceros);
				}
			})
			.catch(() => {
				mostrarError("No se ha podido obtener los Terceros.");
				console.log("No se ha podido obtener los Terceros.")
			})
	}

	const cambioProveedor = (event) => {
		//Si se ha realizado un cambio en el autocomplete de Proveedores. 
		//Primero comprobamos si podemos obtener un valor del index.
		if (event.target.dataset.optionIndex) {
			//console.log(event.target.innerText);//Obtenemos el Nombre del Proveedor
			//Obtenemos el valor del cif seleccionado usando la posicion del index.
			let cif = "";
			Object.values(terceros).map((index, i) => {
				if (index.label == event.target.innerText) {
					cif = index.value;
				}
			})
			setDatosEditarParte({
				...datosEditarParte,
				"cif_proveedor": cif, "nombre_comercial": event.target.innerText
			})
		} else if (event.target.dataset.testid == "CloseIcon") {
			//En caso contrario se habra realizado click en la X para borrar el contenido de Proveedores
			setDatosEditarParte({
				...datosEditarParte,
				"cif_proveedor": "", "nombre_comercial": ""
			})
		}
	};

	/**************************************************************/
	//  		Apartado imagenes
	/**************************************************************/
	//Función para obtener y actualizar la imagen del parte. Afecta a el Dialog de EditarParte.
	const selecImageEditarParte = (event) => {
		const files = event.target.files;

		// Verificar si se seleccionó algún archivo
		if (files.length === 0) {
			// Vaciar las listas de imágenes y PDFs, si no se seleccionó ningún archivo
			setListaImagenEditarParte([]);
			setListaPDFEditarParte([]);
			event.target.value = null;  // Limpiar el input
			return;
		}

		let nuevasImagenes = [];
		let nuevosPDFs = [];

		// Filtrar los archivos de imagen y PDF
		const imagenes = Array.from(files).filter(file => file.type.startsWith('image/'));
		const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');
		/* Lo dejo comentado por si algun dia es necesario
		// Controlar el límite de imágenes
		if (imagenes.length > 3) {
			mostrarError("Máximo 3 imágenes permitidas");
			setListaPDFEditarParte([]);
			setListaImagenEditarParte([]);
			event.target.value = null;  // Limpiar el input
			return;
		}

		// Controlar el límite de PDFs
		if (pdfs.length > 6) {
			mostrarError("Máximo 6 archivos PDF permitidos");
			setListaPDFEditarParte([]);
			setListaImagenEditarParte([]);
			event.target.value = null;  // Limpiar el input
			return;
		}*/

		//Variable para saber el peso de los archivos.
		let pesoArchivos = 0;

		// Montar el array de imágenes
		imagenes.forEach((file, index) => {
			pesoArchivos += file.size;
			nuevasImagenes.push({
				id: index,
				ruta: URL.createObjectURL(file),
				nombre: file.name,
				archivo: file
			});
		});

		// Montar el array de PDFs
		pdfs.forEach((file, index) => {
			let nombrePdf = "";
			if (file.name.length > 29) {
				nombrePdf = file.name.substring(0, 29) + "...";
			} else {
				nombrePdf = file.name;
			}
			pesoArchivos += file.size;
			nuevosPDFs.push({
				id: index,
				ruta: URL.createObjectURL(file),
				nombre: nombrePdf,
				archivo: file
			});
		});

		//Comprobación de si se supera el maximo de 32
		if (pesoArchivos >= 32000000) {
			mostrarError("Los archivos superan los 32 MB");
			console.log("Los archivos superan los 32 MB");
			setListaPDFEditarParte([]);
			setListaImagenEditarParte([]);
			event.target.value = null;  // Limpiar el input
			return;
		}

		setListaImagenEditarParte(nuevasImagenes);
		setListaPDFEditarParte(nuevosPDFs);
	};

	// Abrir imagen o pdf  en ventanda nueva
	const ventanaImagenEditarParte = (ruta) => {
		const rutaMostrar = ruta.includes("min_") ? ruta.replace("min_", "") : ruta;
		window.open(rutaMostrar);
	};

	const borrarImagenEditarParte = (id) => {
		setListaImagenEditarParte(listaImagenEditarParte.filter((foto) => foto.id !== id));
	}
	const borrarPdfEditarParte = (id) => {
		setListaPDFEditarParte(listaPDFEditarParte.filter((pdf) => pdf.id !== id));
	}

	const abrirImagen = () => {
		setPosImage(0);
		//Deshabilitar los botones de navegacion de imagenes si solo hay 1 imagen.
		setGaleriaImagenes(true);
		//window.open('https://api.workers.grupotel.com/imagenes/parte/' + idParte + "/" + fotos[0].archivo);
	}
	//Funcion para Ocultar el Dialog de edicion del Parte.
	const ocultarGaleria = () => {
		setGaleriaImagenes(false);
		setPosImage(0);
	};

	const abrirPDF = () => {
		setPdfParte(true);
	}
	const ocultarPDF = () => {
		setPdfParte(false);
	};

	//Funcion para pasar a la imagen anterior
	const prevImagen = () => {
		if (posImage - 1 == -1) {
			setPosImage(fotoHistorico.length - 1);
		} else {
			setPosImage(posImage - 1);
		}
	};

	//Funcion para pasar a la siguiente imagen
	const nextImagen = () => {
		if (posImage + 1 == fotoHistorico.length) {
			setPosImage(0);
		} else {
			setPosImage(posImage + 1);
		}
	};

	const abrirVentanaImagen = () => {
		//Obtenemos la información de la imagen que estamos mostrando. Utilizando el ID.
		var imagen = document.getElementById('imagenGaleria');
		//Abrimos la imagen en una nueva ventana.
		window.open(imagen.src)
	};

	const ventanaPdf = (nombrePdf) => {
		//Abrimos el pdf en una nueva ventana.
		window.open(Api_Imagen + datos.id_parte + "/" + nombrePdf)
	}

	/**************************************************************/
	//  		Fin Apartado imagenes
	/**************************************************************/

	//Listas utilizadas
	const prioridadInicial = [
		{ valor: 'baja', nombre: 'Baja', },
		{ valor: 'media', nombre: 'Media', },
		{ valor: 'alta', nombre: 'Alta', },
	];
	const [tipoPrioridad, setTipoPrioridad] = useState(prioridadInicial);

	const estadosInicial = [
		{ valor: ' ', nombre: ' ' },
		{ valor: 'nuevo', nombre: 'Nuevo' },
		{ valor: 'asignado', nombre: 'Asignado' },
		{ valor: 'en ejecucion', nombre: 'En ejecución' },
		{ valor: 'terceros', nombre: 'Terceros' },
		{ valor: 'cerrado', nombre: 'Cerrado' },
		{ valor: 'anulado', nombre: 'Anulado' },
	];
	const [tipoEstadosDefault, setTipoEstadosDefault] = useState(estadosInicial);


	const tipoEstadosAnulado = [
		{ valor: ' ', nombre: ' ' },
		{ valor: 'anulado', nombre: 'Anulado' },
	];


	const parteInicial = [ //parteInicial
		{ valor: '', nombre: '', },
		{ valor: 'normal', nombre: 'Normal', },
		{ valor: 'No conformidad', nombre: 'No conformidad', },
		{ valor: 'objeto perdido', nombre: 'Objeto Perdido', },
		{ valor: 'amenity', nombre: 'Amenity', } //,
		// { valor: 'bloqueo', nombre: 'Bloqueo', },
	];
	const [tipoPartes, setTipoPartes] = useState(parteInicial);

	const obtenerDatosSelect = () => {
		fd.append("f", "partes.getDatosSelect");
		fd.append("token", localStorage.getItem('token'));

		fetch('https://api.workers.grupotel.com/', requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result.error.id === 0) {
					// console.log(result);
					const tipoConDefecto = [{ valor: '', nombre: '' }, ...result.tipo];
					setTipoPartes(tipoConDefecto);

					const estadoConDefecto = [{ valor: '', nombre: '' }, ...result.operacion];
					setTipoEstadosDefault(estadoConDefecto);

					setTipoPrioridad(result.prioridad);

				} else {
					setTipoPartes(parteInicial);
					setTipoEstadosDefault(estadosInicial);
					setTipoPrioridad(prioridadInicial);

					mostrarError(result.error.msg);
					console.log(result.error.msg)
				}
			})
			.catch(() => {
				mostrarError("No se ha podido actualizar los Tipos de Parte");
				console.log("No se ha podido actualizar los Tipos de Parte");
			})
	}


	useEffect(() => {

		if (parte != undefined && parte != [] && parte != 0) {

			historialParte(parte);
			setOcultarBotonEdición(false);
			setOcultarBotonInforme(false);
		} else {
			//Vaciamos el Asignados.
			setPersonName([]);
			//Ocultamos los botones
			setOcultarPlay(true);
			setOcultarPauseStop(true);
			setOcultarBotonEdición(true);
			setOcultarBotonInforme(true);
		}
	}, [parte, actualizo]);

	let datos = [];
	let historico = [];
	let fotos = [];

	datos = objeto[0];
	historico = objeto[1];
	fotos = objeto[2];
	let archivoFoto = [];//Un array que contendrá los indice de las fotos. Este indice esta utilizado en Historicos del Parte.
	let fotoHistorico = [];
	let pdfHistorico = [];


	//Ordenamos por el index utilizando el sort. Ya que la respuesta del fetch al pasar a json nos ordena el resultado por el id de Parte.
	const listaPartes = Object.values(listaPartesRoom).sort((a, b) => (a.index > b.index) ? 1 : -1).map((parte, i) =>
		<>
			{datos.id_parte != parte.id ? <>
				<ListItemButton onClick={() => abrirHistorialParte(parte.id)} key={parte.id} id={parte.id} className={parte.operacion === 'en ejecucion' ? 'en-ejecucion-gradient bordeParte infoParte' : parte.operacion + "-gradient bordeParte infoParte"}>
					<HtmlTooltip

						placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
						title={
							<React.Fragment>
								<Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{parte.id}</Typography>
							</React.Fragment>
						}
					>
						<ListItemText className='infoParteId' >
							|
						</ListItemText>
					</HtmlTooltip>
					<ListItemText className='infoParteRoom infoTitulo' primary={parte.titulo} />
					<ListItemText className='infoParteRoom infoHistorico' primary={parte.fecha} />

				</ListItemButton>
				<Collapse in={isOpenCollapse === parte.id} timeout="auto" unmountOnExit className='separarPalabras'>
					{parte.historico ?
						< div >
							{parte.historico.map((historico, j) => {
								return < div className={historico.estado === 'en ejecucion' ? 'en-ejecucion  infoParteIdHijo' : historico.estado + "-gradient infoParteIdHijo"}>
									<ListItem >
										<HtmlTooltip
											placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
											title={
												<React.Fragment>
													<Typography color="inherit">{historico.estado}</Typography>
												</React.Fragment>
											}
										>
											<ListItemText className='infoParteId'>
												|
											</ListItemText>
										</HtmlTooltip>

										<ListItemText className='mostrar justificarTexto infoParteRoom infoTitulo' >
											{historico.fecha_aplazado !== '' ?
												historico.estado === 'terceros' ?
													'\"Aplazado: ' + historico.fecha_aplazado + '\" ' + '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
													: '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + historico.observaciones

												: historico.estado === 'terceros' ?
													'\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
													: historico.observaciones
											}
										</ListItemText>
										<ListItemText className='mostrar justificarTexto infoParteRoom infoHistorico' >
											{historico.fecha_evento}
										</ListItemText>

									</ListItem>
								</div>
							})
							}</div>
						: ""
					}
				</Collapse>
			</> : ''}
		</>
	);




	if (fotos != undefined) {

		//Recorremos el historico para crear un array y guardar los campos necesarios para la galeria de imagenes.
		historico.map((historia, i) => {
			fotos.map((foto, j) => {
				if (foto.indice === historia.indice) {
					//Creamos un array para separar el nombre de la extension del archivo.
					let extension = foto.archivo.split(".");
					if (extension[extension.length - 1] == "pdf") {//Caso de que la extension es un pdf
						pdfHistorico.push({ "fecha_evento": historia.fecha_evento, "archivo": foto.archivo })
					} else {//Si no es un pdf entonces sera una imagen.
						//"usuario":historia.usuario_asignado, "estado":historia.estado ,
						fotoHistorico.push({ "fecha_evento": historia.fecha_evento, "archivo": foto.archivo, "indice": foto.indice })
						archivoFoto.push(foto.indice)//Guardamos el indice de la foto.
					}
				}
			})
		})
	}

	if (parte == 0) {// Si nos llega parte 0 Vaciamos toda la informacion del parte anterior. Para no mostrar información. Este caso ocurre al cargar el componente DatosParte en los distintos lugares.
		datos = [];
		historico = [];
		fotos = [];
		fotoHistorico = [];
		pdfHistorico = [];
	}

	if (datos != undefined && datos != []) {
		/*
		if (fotos != undefined) {
			fotos.reverse();//Invertir el oden de las fotos.
		}*/
		let indiceFotoTemp = "";
		let listaDatos = "";
		if (historico != undefined && historico != []) {
			listaDatos = historico.map((historia, i) =>
				<>
					<ListItemButton key={`botonHistorico-${historia.fecha_evento + i}`} onClick={() => abrirHistorial(historia.fecha_evento + i)} id={historia.fecha_evento + i} className={historia.estado === 'en ejecucion' ? 'en-ejecucion bordeParte infoParte' : historia.estado + "-gradient bordeParte infoParte blanco"} >
						<HtmlTooltip

							placement="left-start" enterDelay={0} leaveDelay={200} TransitionComponent={Zoom}
							title={
								<React.Fragment>
									<Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{historia.estado}</Typography>
								</React.Fragment>
							}
						>
							<ListItemText className='liniaOperacionHistorico' />
						</HtmlTooltip>
						<ListItemText primary={historia.fecha_evento} />
						<ListItemText primary={historia.asignados} />
						<ListItemText className='botonera-historico'>
							{
								historia.estado == 'terceros' ?
									<ButtonGroup>
										{/* Si tiene fichero, veremos o borraremos. Si no, subiremos */}
										{historia.fichero ?
											<>
												<Button component="span" onClick={(e) => { e.stopPropagation(); verAlbaran(historia) }}>
													<VisibilityIcon className='icon-button-small ' />
												</Button>
												<Button component="span" onClick={(e) => { e.stopPropagation(); borrarAlbaran(historia) }}>
													<DeleteIcon className='icon-button-small ' />
												</Button>
											</>
											:
											<>
												<label onClick={(e) => { e.stopPropagation() }} htmlFor="upload-albaran" className='anchoLabel' >
													<input
														className='ocultar'
														accept=".pdf"
														id="upload-albaran"
														name="upload-albaran"
														type="file"
														onChange={(e) => selecAlbaran(e, parte, historia.indice, historia.cif_proveedor)}
													/>
													<Button onClick={(e) => { e.stopPropagation() }} component="span">
														<FileUploadIcon className='icon-button-small ' />
													</Button>
												</label>

											</>
										}
									</ButtonGroup>
									: ''
							}
						</ListItemText>

					</ListItemButton>

					{
						archivoFoto.includes(historia.indice) ?
							<div>{fotoHistorico.map((foto, j) => {
								{
									if (indiceFotoTemp != foto.indice) {//Comprobamos que la foto no tenga el ultimo indice mostrado.  Para mostrar unicamente una foto por indice.
										indiceFotoTemp = foto.indice;

										if (foto.indice === historia.indice) {
											if (historia.observaciones || historia.cif_proveedor || historia.proveedor || historia.proveedor_grupo || historia.tipo_terceros) {
												//return <Collapse in={isOpenCollapse === historia.fecha_evento + i} timeout="auto" unmountOnExit>
												return <Collapse key={`historicoArchivos-${historia.indice}${historia.fecha_evento + i}`} in={openCollapse[historia.fecha_evento + i]} className='colorCollapse' timeout="auto" unmountOnExit>

													<div className='observacionesFotosDiv'>
														<div className='divSoloObservacionesProveedores observacionesAncho'>
															{historia.observaciones !== null && historia.observaciones !== "" && (
																<ListItemText className=' separarPalabras observacionProveedor'>
																	<b>Observaciones:</b><br />
																	{historia.observaciones}
																</ListItemText>
															)}
															{historia.proveedor !== null && historia.proveedor !== "" && (
																<ListItemText className=' separarPalabras'>
																	<b>Proveedor:</b><br />
																	{historia.proveedor}
																</ListItemText>
															)}
															{historia.proveedor_grupo !== null && historia.proveedor_grupo !== "" && (
																<ListItemText className=' separarPalabras'>
																	<b>Proveedor Grupo:</b><br />
																	{historia.proveedor_grupo}
																</ListItemText>
															)}
															{historia.cif_proveedor !== null && historia.cif_proveedor !== "" && (
																<ListItemText className=' separarPalabras tipoTercerosCIF'>
																	<b>CIF:</b><br />
																	{historia.cif_proveedor}
																</ListItemText>
															)}
															{historia.tipo_terceros !== null && historia.tipo_terceros !== "" && (
																<ListItemText className=' separarPalabras tipoTercerosCIF'>
																	<b>Tipo terceros:</b><br />
																	{historia.tipo_terceros}
																</ListItemText>
															)}
														</div>
														<div className='mostrar fotosAncho'>
															<img className="img-preview-Historico clicable" src={Api_Imagen + datos.id_parte + "/min_" + foto.archivo}
																onError={({ currentTarget }) => {
																	currentTarget.onerror = null; // prevents looping
																	currentTarget.src = Api_Antiguo_Imagen + datos.id_parte + "/min_" + foto.archivo;
																}}
																onClick={abrirImagen}
															/>
														</div>
													</div>
												</Collapse>
											} else {
												//<Collapse in={isOpenCollapse === historia.fecha_evento + i} timeout="auto" unmountOnExit>

												return <div >
													<Collapse key={`historicoImagen-${historia.indice}${historia.fecha_evento + i}`} in={openCollapse[historia.fecha_evento + i]} className='colorCollapse' timeout="auto" unmountOnExit>

														<div className='mostrar imagenDerecha fotosAncho'>
															<img className="img-preview-Historico clicable" src={Api_Imagen + datos.id_parte + "/min_" + foto.archivo}
																onError={({ currentTarget }) => {
																	currentTarget.onerror = null; // prevents looping
																	currentTarget.src = Api_Antiguo_Imagen + datos.id_parte + "/min_" + foto.archivo;
																}}
																onClick={abrirImagen}
															/>
														</div>
													</Collapse>
												</div>
											}
										}
									}
								}
							})
							}</div>
							:
							historia.observaciones || historia.cif_proveedor || historia.proveedor || historia.proveedor_grupo || historia.tipo_terceros ?
								//<Collapse in={isOpenCollapse === historia.fecha_evento + i} timeout="auto" unmountOnExit>
								<Collapse key={`historicoTerceros-${historia.indice}${historia.fecha_evento + i}`} in={openCollapse[historia.fecha_evento + i]} className='colorCollapse' timeout="auto" unmountOnExit>
									<div className='divSoloObservacionesProveedores textoObservaciones'>
										{historia.observaciones !== null && historia.observaciones !== "" && (
											<ListItemText className=' separarPalabras observacionProveedor'>
												<b>Observaciones:</b><br />
												{historia.observaciones}
											</ListItemText>
										)}
										{historia.proveedor !== null && historia.proveedor !== "" && (
											<ListItemText className=' separarPalabras'>
												<b>Proveedor:</b><br />
												{historia.proveedor}
											</ListItemText>)}
										{historia.proveedor_grupo !== null && historia.proveedor_grupo !== "" && (
											<ListItemText className=' separarPalabras'>
												<b>Proveedor Grupo:</b><br />
												{historia.proveedor_grupo}
											</ListItemText>)}
										{historia.cif_proveedor !== null && historia.cif_proveedor !== "" && (
											<ListItemText className=' separarPalabras tipoTercerosCIF'>
												<b>CIF:</b><br />
												{historia.cif_proveedor}
											</ListItemText>
										)}
										{historia.tipo_terceros !== null && historia.tipo_terceros !== "" && (
											<ListItemText className=' separarPalabras tipoTercerosCIF'>
												<b>Tipo terceros:</b><br />
												{historia.tipo_terceros}
											</ListItemText>)}
									</div>
								</Collapse>
								: ""
					}
				</>
			);

		}

		return (

			<div>
				{!mostrado ? <div></div>
					:
					<div>
						<div className='header'>
							<div className='containerAccionesLeft'>
								<ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
									<HtmlTooltip
										placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
										title={
											<React.Fragment>
												<Typography color="inherit">{"PDF Parte"}</Typography>
											</React.Fragment>
										}
									>
										<Button
											className={ocultarBotonInforme != false ? 'ocultar' : 'mostrar boton botonesAcciones'}
											onClick={verInforme}
										>
											<PrintIcon className='icon-button' />
										</Button>
									</HtmlTooltip>
									{
										departamento_id == datos.id_departamento || tipoSalidas == 1 ?

											<HtmlTooltip
												placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
												title={
													<React.Fragment>
														<Typography color="inherit">{"Editar Parte"}</Typography>
													</React.Fragment>
												}
											>
												<Button
													className={ocultarBotonEdición != false ? 'ocultar' : 'mostrar boton botonesAcciones editar'}
													//className="boton botonesAcciones editar"
													onClick={modoEdicion}
												>
													<ModeEditIcon className='icon-button' />
												</Button>
											</HtmlTooltip>
											: ""}
											{/* ES AQUI PARA EDITAR DESDE CUALQUIER USUARIO, JUSTO ARRIBA. */}
									<HtmlTooltip
										placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
										title={
											<React.Fragment>
												<Typography color="inherit">{"Ejecucion"}</Typography>
											</React.Fragment>
										}
									>
										<Button
											className={ocultarPlay != false ? 'ocultar' : 'mostrar boton botonesAcciones'}
											onClick={(ev) => { modoPlay(ev) }}
										>
											<PlayArrowIcon className='icon-button' />
										</Button>
									</HtmlTooltip>
									<HtmlTooltip
										placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
										title={
											<React.Fragment>
												<Typography color="inherit">{"Asignar"}</Typography>
											</React.Fragment>
										}
									>
										<Button
											className={ocultarPauseStop != false ? 'ocultar' : 'mostrar boton botonesAcciones'}
											onClick={() => { modoPause() }}
										>
											<PauseCircleOutlineIcon className='icon-button' />
										</Button>
									</HtmlTooltip>
									<HtmlTooltip
										placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
										title={
											<React.Fragment>
												<Typography color="inherit">{"Cerrar"}</Typography>
											</React.Fragment>
										}
									>
										<Button
											className={ocultarPauseStop != false ? 'ocultar' : 'mostrar boton botonesAcciones'}
											onClick={() => { modoStop() }}
										>
											<StopCircleOutlinedIcon className='icon-button' />
										</Button>
									</HtmlTooltip>

								</ButtonGroup>
							</div>
						</div>
						<div className='datos-container'>
							<div className='bloque-inputs'>
								<TextField
									className='titulo'
									variant="outlined"
									margin="normal"
									size="small"
									value={datos ? (datos.titulo ? datos.titulo : ' ') : ' '}
									inputProps={{ className: 'bold', readOnly: true }}
									InputLabelProps={{ className: 'bold' }}
									label="Titulo"
								/>
								<TextField
									variant="outlined"
									margin="normal"
									label="ID PARTE"
									size="small"
									id={Object.keys(datos)[0]}
									value={datos ? (datos.id_parte ? datos.id_parte : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
							</div>
							<div className='bloque-inputs'>
								<TextField
									variant="outlined"
									margin="normal"
									label="Hotel"
									size="small"
									value={datos ? (datos.hotel ? datos.hotel : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									label="Bloque"
									size="small"
									className='anchoBloque'
									value={datos ? (datos.bloque ? datos.bloque : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									label="Planta"
									size="small"
									value={datos ? (datos.planta ? datos.planta : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									label="Habitacion"
									size="small"
									value={datos ? (datos.habitacion ? datos.habitacion : ' ') : ' '}
									inputProps={{ className: 'bold', readOnly: true }}
									InputLabelProps={{ className: 'bold' }}
								/>
							</div>
							<div className='bloque-inputs'>
								<TextField
									variant="outlined"
									margin="normal"
									label="Fecha creacion"
									size="small"
									value={datos ? (datos.fecha_creacion ? datos.fecha_creacion : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									label="Departamento"
									size="small"
									value={datos ? (datos.departamento_creador ? datos.departamento_creador : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									label="Creador"
									size="small"
									value={datos ? (datos.creador ? datos.creador : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									label="Asignado"
									size="small"
									value={historico && historico.length > 0 ? (historico[0].usuario_asignado ? historico[0].usuario_asignado : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>

							</div>
							<div className='formChip' >
								{chipMultipleAsignados(1, true)}
							</div>
							<div className='bloque-inputs'>
								<TextField
									id="observacionesDatosParte"
									className='observaciones colorFondo'
									label="Observaciones"
									size="small"
									multiline
									rows={5}
									value={historico && historico.length > 0 ? (historico[historico.length - 1].observaciones ? historico[historico.length - 1].observaciones : ' ') : ' '}
									inputProps={
										{ readOnly: true, }
									}
								/>
							</div>
							<div className='bloque-inputs divImagen'>
								<TextField
									variant="outlined"
									margin="normal"
									label="Tipo Parte"
									size="small"
									value={datos ? (datos.tipo ? datos.tipo : ' ') : ' '}
									inputProps={
										{
											readOnly: true,
											className: 'capitalize'
										}
									}
								/>
								<div className='divBotonesImgPdf'>
									{fotoHistorico.length != 0 && fotoHistorico != [] ? <IconButton className='boton ' onClick={abrirImagen}>
										<ImageSearchIcon className='icon-button ' />
									</IconButton> : ""}
									{pdfHistorico.length != 0 && pdfHistorico != [] ? <IconButton className='boton ' onClick={abrirPDF}>
										<PictureAsPdfOutlinedIcon className='icon-button ' />
									</IconButton> : ""}
								</div>
								<TextField
									variant="outlined"
									margin="normal"
									label="Prioridad"
									size="small"
									value={datos ? (datos.ultima_prioridad ? datos.ultima_prioridad : ' ') : ' '}
									inputProps={
										{
											readOnly: true,
											className: 'capitalize',
										}
									}
								/>

							</div>
							<div className='historial-container '>
								<List>
									{listaDatos}
								</List>
							</div>
							<Dialog fullScreen={fullScreen}
								open={modoLectura}
								onClose={comprobarESCEditarParte}
								aria-labelledby="responsive-dialog-title" id="dialogDatosParteEditar" >
								<DialogTitle>{rutaActual.idDepartamento ? (datosEditarParte.id ? datosEditarParte.id + ' - ' : '') + rutaActual.nombre : (datosEditarParte.id ? datosEditarParte.id + ' - ' : '') + departamentoInicial}</DialogTitle>
								<DialogContent className='container-filtros alturaDialog'>
									<form>
										{/*
										<div className='bloque-inputs'>
											<TextField
												variant="outlined"
												margin="normal"
												label="ID PARTE"
												size="small"
												value={datosEditarParte.id ? datosEditarParte.id : ''}
												inputProps={
													{ readOnly: true, }
												}
											/>
											<TextField
												variant="outlined"
												margin="normal"
												label="Fecha creacion"
												size="small"
												value={datosEditarParte.fecha_creacion ? datosEditarParte.fecha_creacion : ''}
												inputProps={
													{ readOnly: true, }
												}
											/>
											<TextField
												variant="outlined"
												margin="normal"
												label="Creador"
												size="small"
												value={datosEditarParte.creador ? datosEditarParte.creador : ''}
												inputProps={
													{ readOnly: true, }
												}
											/>
											<TextField
												variant="outlined"
												margin="normal"
												label="Hotel"
												size="small"
												value={datosEditarParte.hotel ? datosEditarParte.hotel : ''}
												inputProps={
													{ readOnly: true, }
												}
											/>
										</div>
											*/}

										<div className='bloque-inputs'>
											<TextField
												className='medio'
												variant="outlined"
												margin="normal"
												label="Bloque"
												name='bloque'
												size="small"
												value={datosEditarParte.bloque ? datosEditarParte.bloque : ''}
												onChange={editarParte}
												inputProps={
													{ readOnly: true, }
												}
											/>
											<TextField
												className='numeros'
												variant="outlined"
												margin="normal"
												label="Planta"
												name='planta'
												onChange={editarParte}
												size="small"
												value={datosEditarParte.planta ? (datosEditarParte.planta ? datosEditarParte.planta : ' ') : ' '}
												//type="number"
												inputProps={
													{ readOnly: true, }
												}
											/>
											<TextField
												key="editarParteHab"
												variant="outlined"
												margin="normal"
												label="Hab."
												name='hab'
												size="small"
												className='habitacionBuscador'
												onChange={editarParte}
												onKeyPress={(ev) => { comprobarEnterHab(ev) }}
												value={datosEditarParte.hab ? datosEditarParte.hab : ''}
												InputProps={
													{
														endAdornment:
															<IconButton
																key="buscarHab"
																className="boton buscador botonHabitacionBuscador"
																// onClick={buscarHabitacion}
																onClick={() => buscarHabitacion()}
															>
																<SearchIcon className='icon-button ' />
															</IconButton>
													}}
												autoComplete="off"
											/>

											<TextField
												className='medio'
												variant="outlined"
												margin="normal"
												label="Tipo parte"
												name="tipo"
												size="small"
												onChange={editarParte}
												value={datosEditarParte.tipo ? datosEditarParte.tipo : 'normal'}
												select
											>
												{tipoPartes
													.sort((a, b) => a.orden - b.orden) // Ordenar por el campo 'orden' de forma ascendente
													.map((tipoParte) => (
														<MenuItem key={`editTipoDatosP-${tipoParte.valor}`} value={tipoParte.valor}>
															<Tooltip
																title={tipoParte.descripcion && tipoParte.descripcion.trim() ? tipoParte.descripcion : ''} // Mostrar solo si hay descripción
																placement="right"
															>
																<span>{tipoParte.nombre}</span> {/* Envolver el texto del menú, no el componente entero */}
															</Tooltip>
														</MenuItem>
													))}
											</TextField>

											<TextField
												label="Aplazar"
												type="date"
												format={'DD/MM/YYYY'}
												InputLabelProps={{
													shrink: true,
												}}
												name='fecha_aplazado'
												size="small"
												value={datosEditarParte.fecha_aplazado ? datosEditarParte.fecha_aplazado : ''}
												onChange={editarParte}
												autoComplete="off"
											/>
											{
												// <TextField
												// 	variant="outlined"
												// 	margin="normal"
												// 	label="Asignado"
												// 	name='id_usuario'
												// 	size="small"
												// 	value={datosEditarParte.id_usuario ? datosEditarParte.id_usuario : ''}
												// 	onChange={editarParte}
												// 	select
												// >
												// 	{usuariosLista.map((usuario) => (
												// 		<MenuItem value={usuario.value}>
												// 			{usuario.label}
												// 		</MenuItem>
												// 	))}
												// </TextField>
											}
										</div>
										<div className='formChip' >
											{chipMultipleAsignados(2, false)}
										</div>
										<div className='bloque-inputs alinearIzquierda'>
											<div className='divEdicion'>
												<div className='divFiltrosTerceros'>
													{obtenerSelectEstados()}


													<TextField
														className='proveedoresTextfield'
														variant="outlined"
														margin="normal"
														label="Prioridad"
														name="prioridad"
														onChange={editarParte}
														size="small"
														value={datosEditarParte.prioridad ? datosEditarParte.prioridad : 'baja'}
														select
													>
														{tipoPrioridad.map((prioridad) => (
															<MenuItem key={`editPrioridadDatosP-${prioridad.valor}`} value={prioridad.valor}>
																{prioridad.nombre}
															</MenuItem>
														))}
													</TextField>
												</div>
												<div className='divFiltrosTerceros'>
													{mostrarTerceros ?
														<div className='divFiltrosTextField'>
															<TextField
																variant="outlined"
																margin="normal"
																label="Tipo terceros"
																name="id_tipo_terceros"
																className='proveedoresTextfield'
																size="small"
																value={datosEditarParte.id_tipo_terceros ? datosEditarParte.id_tipo_terceros : ''}
																onChange={editarParte}
																select
															>
																{tipoTerceros.map((tipoTercero) => (
																	<MenuItem key={`editTerceroDatosP-${tipoTercero.value}`} value={tipoTercero.value}>
																		{tipoTercero.label}
																	</MenuItem>
																))}
															</TextField>
															<Autocomplete
																id="proveedorAuto"
																className='proveedoresTextfield'
																options={terceros}
																onChange={cambioProveedor}
																size="small"
																isOptionEqualToValue={(option, value) => option.value === value.value}
																//value={datosFiltroParte.nombre_comercial ? datosFiltroParte.nombre_comercial : ''}
																renderInput={(params) => <TextField {...params} label="Proveedor" />}
															/>
														</div>
														: ""
													}
												</div>
												<TextField
													className='observaciones observacionesEditarParte'
													label="Observaciones"
													name="observaciones"
													size="small"
													onChange={editarParte}
													multiline
													rows={12}
													value={datosEditarParte.observaciones ? datosEditarParte.observaciones : ' '}
												/>
											</div>
											<div className='containerBotonesEditarDepartamento'>
												<div className='containerPdf'>
													<div>
														<label htmlFor="upload-files" className=''>
															<input
																className='ocultar'
																accept=".pdf, .jpg, .jpeg, .png"
																id="upload-files"
																type="file"
																onChange={selecImageEditarParte}
																multiple
															/>
															<Button component='span' className='colorGrupo botonEditarDepartamento' variant="outlined">Añadir archivo</Button>
														</label>
													</div>
													<div className='divBotonesToogle'>
														{
															infoParteTemp.habitacion !== '0' ?
																<Button
																	variant="outlined"
																	margin="normal"
																	className={mostrarPartes ? "activoBoton botonEditarDepartamento" : " colorGrupo botonEditarDepartamento "}
																	onClick={mostrarPartesAbiertos}>
																	Partes Activos
																</Button> : ''
														}
														<Button
															variant="outlined"
															margin="normal"
															className={mostrarPartes ?
																" colorGrupo botonEditarDepartamento " :
																estadoListadoDepartamentos != 1 ?
																	// estadoListadoDepartamentos === 0 ?
																	" colorGrupo botonEditarDepartamento " :

																	"activoBoton botonEditarDepartamento"}
															onClick={mostrarDepartamentos}>
															Cambiar Lugar
														</Button>
													</div>
												</div>
												<div className='css-1rv3ei8-MuiFormControl-root-MuiTextField-root'>


													<div className='divPdf'>
														{listaPDFEditarParte.length > 0 ?
															<div className='containerPdf' >
																{listaPDFEditarParte.map((pdf, j) => {
																	return (
																		<div className='' key={pdf.id}>
																			<Button className='nuevoParteNombrePdf' variant="text" onClick={() => ventanaImagenEditarParte(pdf.ruta)} >{pdf.nombre ? pdf.nombre : ''}</Button>
																			<IconButton className='iconX' onClick={() => borrarPdfEditarParte(pdf.id)}>x</IconButton>
																		</div>
																	);
																})
																}
															</div>
															: ''
														}
													</div>
													<div className='containerDiv'>
														{listaImagenEditarParte.length > 0 ?
															<div className='divImgMaquinasNuevas'>
																{listaImagenEditarParte.map((foto, j) => {
																	return (
																		<div className='divCentrarImagen' key={foto.id}>
																			<IconButton className='iconoBorrarImg' onClick={() => borrarImagenEditarParte(foto.id)}>
																				<CancelIcon className=' ' />
																			</IconButton>
																			<img className="editarPartePreviewImagen" src={foto.ruta} onClick={() => ventanaImagenEditarParte(foto.ruta)} />
																		</div>
																	);
																})
																}
															</div>
															: ''
														}
													</div>

												</div>
											</div>
											<div className='nuevoParteBloque  '>
												{
													mostrarPartes ?
														<div className='nuevoParteHistoricoEditar'>
															{listaPartes}
														</div>
														:
														<></>
												}
												{
													estadoListadoDepartamentos == 0 ?
														<></>
														:
														<div className=' bloqueEdicion departamentoEditar anchoDepartamentos'>
															<Box sx={{ width: '100%' }} className='departamento listaDepartamentos'>

																<List
																	className='botonLista'
																	sx={{ width: '100% !important' }}
																>
																	<ListItem className={'divisor'}>
																		<div className='itemBoton'>
																			<TextField InputProps={{
																				startAdornment: (
																					<InputAdornment position="start">
																						<FilterAltIcon color='#00a2dc' />
																					</InputAdornment>
																				),
																			}} value={textoBusqueda} onChange={(ev) => { handleSearchText(ev) }} id="search" fullWidth autoComplete="off"></TextField>
																		</div>
																	</ListItem>
																	<ListItem divider className='divisor botonAtras'>
																		<ListItemButton className='itemBoton'
																			disabled={activarAtras}
																			onClick={backDepartamento}
																		>
																			<ListItemText className='center'>
																				<ArrowBackIcon className='icon-button ' />
																			</ListItemText>
																		</ListItemButton>
																	</ListItem>
																	{(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).map((departamento, i) =>
																		<>
																			<ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisor ultimoDiv' : 'divisor'} >
																				<ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
																					<ListItemText primary={departamento.label} id={departamento.value} className='center' />
																				</ListItemButton>
																			</ListItem>
																		</>
																	)
																	}
																</List>
															</Box>
														</div>
												}
											</div>
										</div>
									</form>
								</DialogContent>
								<DialogActions>
									<ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
										<Button className="colorGrupo" onClick={finEdicion}>Cancelar</Button>
										<Button className="colorGrupo" onClick={confirmarEdicion}>Confirmar</Button>
									</ButtonGroup>
								</DialogActions>
							</Dialog>
							{fotoHistorico.length != 0 ?
								<Dialog fullScreen={fullScreen}
									open={galeriaImagenes}
									onClose={ocultarGaleria}
									aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
								>
									<DialogTitle>{fotoHistorico[posImage].fecha_evento ? "Fecha historico: " + fotoHistorico[posImage].fecha_evento : " "}</DialogTitle>
									<DialogContent className='container-filtros '>
										{fotos ?
											fotoHistorico[posImage].archivo != undefined || fotoHistorico[posImage].archivo != "" ?
												<div>
													<IconButton component="span" className='iconoFoto buscador' onClick={abrirVentanaImagen} >
														<AddAPhotoIcon className='icon-button ' />
													</IconButton>
													<img id="imagenGaleria" className="mdc-image-list__image"
														alt={"Imagen del Parte " + datos.id_parte + " Nombre archivo: " + fotoHistorico[posImage].archivo}
														src={Api_Imagen + datos.id_parte + "/" + fotoHistorico[posImage].archivo}
														style={{
															"max-width": "-webkit-fill-available"
															, "max-with": "90%"
															// , "max-width": "90vh"  si el ancho de la imagen es excesivo.
														}}
														onError={({ currentTarget }) => {
															currentTarget.src = Api_Antiguo_Imagen + datos.id_parte + "/" + fotoHistorico[posImage].archivo;
															currentTarget.onerror = null; // prevents looping

														}} />
												</div>
												: ""
											: ""
										}

									</DialogContent>
									<DialogActions className='centrar'>
										{fotoHistorico.length > 1 ?
											<div className='direccion ' id='direccionImagen'>
												<ButtonGroup className=" colorGrupo" variant="contained" aria-label="outlined button group">
													<IconButton component="span" className='direccionFlecha boton' onClick={prevImagen}>
														<WestOutlinedIcon />
													</IconButton>
													<Button className="colorGrupo2" onClick={ocultarGaleria}>Cerrar</Button>
													<IconButton component="span" className=' direccionFlecha boton' onClick={nextImagen}>
														<EastOutlinedIcon />
													</IconButton>
												</ButtonGroup>
												<p>{posImage + 1} / {fotoHistorico.length}</p>
											</div>
											:
											<ButtonGroup variant="contained" aria-label="outlined button group">
												<Button className="colorGrupo" onClick={ocultarGaleria}>Cerrar</Button>
											</ButtonGroup>

										}
									</DialogActions>
								</Dialog>
								: ""}
							{pdfHistorico.length != 0 ?
								<Dialog fullScreen={fullScreen}
									open={pdfParte}
									onClose={ocultarPDF}
									aria-labelledby="responsive-dialog-title" id="galeriaEditarParte" style={{ "width": "100%", "height": "100%" }}
								>
									<DialogTitle>{pdfHistorico[0].fecha_evento ? "Fecha historico: " + pdfHistorico[0].fecha_evento : " "}</DialogTitle>
									<DialogContent className='container-filtros '>
										{Object.keys(pdfHistorico).map((id, i) => {
											return <ListItemButton onClick={() => ventanaPdf(pdfHistorico[i].archivo)} >{pdfHistorico[i] ? pdfHistorico[i].archivo : ''}</ListItemButton>
										})
										}
									</DialogContent>
									<DialogActions className='centrar'>
										<ButtonGroup variant="contained" aria-label="outlined button group">
											<Button className="colorGrupo" onClick={ocultarPDF}>Cerrar</Button>
										</ButtonGroup>
									</DialogActions>
								</Dialog>
								: ""
							}
							<Dialog open={openConfirm} onClose={handleOpenConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
								<DialogTitle>Tienes partes en Ejecución, quieres que se pongan en Asignado?</DialogTitle>
								<DialogContent className='container-filtros '>
									{
										partesActivos.length != 0 ?
											Object.keys(partesActivos).map((id, i) => {
												// Obtener el nombre del hotel
												const hotelId = partesActivos[id].id_hotel;
												const hotelesLocalStorage = JSON.parse(localStorage.getItem("hoteles"));

												// Buscar el hotel con el mismo ID en el array de hoteles
												const hotelEncontrado = hotelesLocalStorage.find(hotel => hotel.value === hotelId);

												return <div>
													<div className='separarPalabras '><span><b>Titulo:</b> {partesActivos[id].titulo}</span></div>
													<div className='separarPalabras '><span><b>Parte:</b> {partesActivos[id].id} <b>Hotel:</b> {hotelEncontrado ? hotelEncontrado.label : "No encontrado"}</span></div>
												</div>
											})
											: ""
									}
								</DialogContent>
								<DialogActions>
									<Button className="colorGrupo" onClick={() => { confirmarUpdate(); handleOpenConfirm(); }}>SI</Button>
									<Button className="colorGrupo" onClick={handleOpenConfirm}>NO</Button>
								</DialogActions>
							</Dialog>
							<MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
						</div >
					</div>
				}

			</div>
		)
	}
}
export default DatosParte;