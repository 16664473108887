import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DataGrid } from '@mui/x-data-grid';
import {
  IconButton, InputAdornment, ButtonGroup, Tooltip, MenuItem, tooltipClasses, Typography, styled, Zoom, Button, TextField, Dialog, DialogActions,
  DialogContent, DialogTitle, useMediaQuery, Checkbox, FormControlLabel, List, ListItem, ListItemButton, ListItemText, Box, Autocomplete, Collapse,
  OutlinedInput, InputLabel, Chip, FormControl, Select
} from '@mui/material';

import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PrintIcon from '@mui/icons-material/Print';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import { set_id } from '../../parteDucks';
import { useTheme } from '@mui/material/styles';

import CargaSpinner from '../AccionesPartes/CargaSpinner';
import MostrarError from '../AccionesPartes/MostrarError';
import './GridParte.css';

import TextFieldFecha from '../AccionesPartes/TextFieldFecha';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} disableInteractive />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const columnasHotel = [
  {
    headerClassName: 'header-grid', field: 'operacion', headerName: 'Estado', width: 90,
    renderCell: (params) => (
      // <Tooltip title={params.row.id_parte} placement="right-start" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}>
      <HtmlTooltip
        placement="right-start" enterDelay={0} leaveDelay={200} TransitionComponent={Zoom}
        title={
          <React.Fragment>
            <Typography color="inherit">{params.row.id_parte}</Typography>
          </React.Fragment>
        }
      >
        <div className={'pastilla ' + params.row.operacion + '-button'}>

          {params.row.prioridad === 'media' ?
            <div className='pastilla-icono'><PriorityHighIcon fontSize='small'></PriorityHighIcon></div>
            : params.row.prioridad === 'alta' ?
              <div className='pastilla-icono'><NewReleasesIcon fontSize='small'></NewReleasesIcon></div>
              :
              ''
          }
          <div className='pastilla-texto centrado'>

            <span>{params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1).replace(/-/g, ' ') === 'En ejecucion' ?
              'En curso'
              :
              params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1).replace(/-/g, ' ')}</span>
          </div>

        </div>
      </HtmlTooltip>)
  },
  {
    headerClassName: "header-grid",
    field: "hotel",
    headerName: "Hotel",
    width: 160,
    renderCell: (params) => params.row.hotel,
  },
  {
    headerClassName: 'header-grid', field: 'titulo_corto', headerName: 'Titulo', width: 280,
    renderCell: (params) => (
      params.row.titulo_corto_modificado ?
        <HtmlTooltip
          placement="top-start" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} followCursor
          title={
            <React.Fragment>
              <Typography color="inherit">{params.row.titulo}</Typography>
            </React.Fragment>
          }
        >
          <div className='whitespace tabla-flex full-width'>
            <p>{params.value}</p>
            {params.row.albaran === '1' || params.row.foto === '1' ? <AttachFileIcon className='icon-button' /> : ''}
          </div>
        </HtmlTooltip>
        :
        <div className='whitespace tabla-flex full-width'>
          <p className=''>{params.value}</p>
          {params.row.albaran === '1' || params.row.foto === '1' ? <AttachFileIcon className='icon-button' /> : ''}
        </div>
    )
  }, {
    headerClassName: 'header-grid', field: 'asignado', headerName: 'Asignado', width: 130,

  },
  {
    headerClassName: 'header-grid', field: 'hab', headerName: 'Hab.', width: 60, align: 'center',
    renderCell: (params) => (
      <HtmlTooltip
        placement="right-start" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
        title={
          <React.Fragment>
            <Typography color="inherit">{params.row.bloque}-{params.row.planta}-{params.row.hab}</Typography>
          </React.Fragment>
        }
      >
        <div className=''>
          <p>{params.value}</p>
        </div>
      </HtmlTooltip>
    )
  },
  {
    headerClassName: 'header-grid', field: 'fecha_evento_en', headerName: 'Fecha', width: 100,
    renderCell: (params) => (
      <div>
        <p>{params.row.fecha_evento_es}</p>
      </div>
    )
  }
]

const columnasDep = [
  {
    headerClassName: 'header-grid', field: 'operacion', headerName: 'Estado', width: 90,
    renderCell: (params) => (
      // <Tooltip title={params.row.id_parte} placement="right-start" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}>
      <HtmlTooltip
        placement="right-start" enterDelay={0} leaveDelay={200} TransitionComponent={Zoom}
        title={
          <React.Fragment>
            <Typography color="inherit">{params.row.id_parte}</Typography>
          </React.Fragment>
        }
      >
        <div className={'pastilla ' + params.row.operacion + '-button'}>

          {params.row.prioridad === 'media' ?
            <div className='pastilla-icono'><PriorityHighIcon fontSize='small'></PriorityHighIcon></div>
            : params.row.prioridad === 'alta' ?
              <div className='pastilla-icono'><NewReleasesIcon fontSize='small'></NewReleasesIcon></div>
              :
              ''
          }
          <div className='pastilla-texto centrado'>

            <span>{params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1).replace(/-/g, ' ') === 'En ejecucion' ?
              'En curso'
              :
              params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1).replace(/-/g, ' ')}</span>
          </div>

        </div>
      </HtmlTooltip>)
  },
  {
    headerClassName: "header-grid",
    field: "departamento_creador",
    headerName: "Dep. Creador",
    width: 160,
    renderCell: (params) => params.row.departamento_creador,
  },
  {
    headerClassName: 'header-grid', field: 'titulo_corto', headerName: 'Titulo', width: 280,
    renderCell: (params) => (
      params.row.titulo_corto_modificado ?
        <HtmlTooltip
          placement="top-start" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} followCursor
          title={
            <React.Fragment>
              <Typography color="inherit">{params.row.titulo}</Typography>
            </React.Fragment>
          }
        >
          <div className='whitespace tabla-flex full-width'>
            <p>{params.value}</p>
            {params.row.albaran === '1' || params.row.foto === '1' ? <AttachFileIcon className='icon-button' /> : ''}
          </div>
        </HtmlTooltip>
        :
        <div className='whitespace tabla-flex full-width'>
          <p className=''>{params.value}</p>
          {params.row.albaran === '1' || params.row.foto === '1' ? <AttachFileIcon className='icon-button' /> : ''}
        </div>
    )
  }, {
    headerClassName: 'header-grid', field: 'asignado', headerName: 'Asignado', width: 130,

  },
  {
    headerClassName: 'header-grid', field: 'hab', headerName: 'Hab.', width: 60, align: 'center',
    renderCell: (params) => (
      <HtmlTooltip
        placement="right-start" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
        title={
          <React.Fragment>
            <Typography color="inherit">{params.row.bloque}-{params.row.planta}-{params.row.hab}</Typography>
          </React.Fragment>
        }
      >
        <div className=''>
          <p>{params.value}</p>
        </div>
      </HtmlTooltip>
    )
  },
  {
    headerClassName: 'header-grid', field: 'fecha_evento_en', headerName: 'Fecha', width: 100,
    renderCell: (params) => (
      <div>
        <p>{params.row.fecha_evento_es}</p>
      </div>
    )
  }
]


function GridParte({ tipoSalidas, idHab, idHotelHab, actuGridParte, tipoEstado }) {
  const API_URL = 'https://api.workers.grupotel.com/';

  //Funcion utilizada para obtener campos de DatosParte el hijo desde GridParte el componente Padre.
  // const datosParteAGridParte = (datos) => {
  //   if (datos == true) {
  //     actualizarGridParte();//Forzamos la actualizacion.
  //   }
  // }

  //Obtenemos el listado de hoteles del Login
  let hoteles = localStorage.getItem('hoteles');
  let idHotel = "";

  //Para saber que orden tiene actualmente el DataGrid
  const [sortModel, setSortModel] = useState([
    { field: 'fecha_evento_en', sort: 'desc' },
  ]);

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const [datos, setDatos] = useState([]);
  const [hotelesLista, SetHotelesLista] = useState([]);
  const [departamentos, setDepartamentosLista] = useState([]);
  const [idParte, setidParte] = useState(0);

  //Partes de la habitación buscada.
  const [listaPartesRoom, setListaPartesRoom] = useState([]);

  //Boolean para comprobar si queremos realizar una actualizacion.
  const [actualizo, SetActualizo] = useState(true);
  //Boolean utilizado para forzar el renderizado de los componentes.
  const [actualizo2, SetActualizo2] = useState(true);
  const [openAyuda, setOpenAyuda] = useState(false);

  const [estadoFiltros, setEstadoFiltros] = React.useState(false);
  const [activoFiltro, setActivoFiltro] = useState(false);

  //Lista de los proveedores
  const [terceros, setTercerosLista] = useState([{ value: '', label: '' }]);
  //Lista del Tipo de Terceros.
  const [tipoTerceros, setTipoTercerosLista] = useState([]);
  //Listado de los Usuarios con el mismo id Departamento.
  const [usuariosLista, setUsuariosLista] = useState([{ value: '', label: '' }]);
  // Todos los usuarios de un hotel
  const [usuariosHotel, setUsuariosHotel] = useState([{ value: '', label: '' }]);

  //Lista Bloques del hotel en Filtros.
  const [bloquesLista, setBloquesLista] = useState([]);
  const [plantasLista, setPlantasLista] = useState([]);


  //Array que contiene el resultado de getLocationsList. Obtiene un listado de todos los departamentos.
  const [todosDepartamentos] = useState([]);
  //Array temporal para comprobar si hay departamentos con el mismo idPadre.
  const [departamentosListado] = useState([]);
  //Array utilizada para mostrar los departamentos, en Crear Parte.
  const [departamentosNuevoParte] = useState([]);
  const [departamentosFiltrados, setDepartamentosFiltrados] = useState([]);
  const [textoBusqueda, setTextoBusqueda] = useState('');


  //Conjunto de listas donde guardamos un string de los id de Departamentos a los que damos click. Y lista de los nombres de departamentos.
  const [rutaActual, setRutaActual] = useState({ idDepartamento: '', nombre: '' });
  //Variable que utilizamos como contador. Para saber en que posición estamos de los departamentos.
  const [posicionActual, setPosicionActual] = useState(0);
  //Boolean utilizado para habilitar o deshabilitar el boton de atras en Nuevo Parte. Hace referencia a los departamentos.
  const [activarAtras, setActivarAtras] = useState(true);

  const [errorDepartamentosPartes, setErrorDepartamentosPartes] = React.useState(false);
  const [errorHotelPartes, setErrorHotelPartes] = React.useState(false);
  const [errorHabitacionPartes, setErrorHabitacionPartes] = React.useState(false);

  const [datosNuevoParte, setDatosNuevoParte] = useState({
    id_hotel: localStorage.getItem('idHotelSeleccionado'),
    titulo: '',
    rama: '',
    tipo: '',
    hab: '',
    bloque: '',
    planta: '',
    nombre: '',
    observaciones: ''
  });
  // Listas para previsualizar Imagenes o Pdf
  const [listaImagenesNuevoParte, setListaImagenesNuevoParte] = useState([]);
  const [listaPDFNuevoParte, setListaPDFNuevoParte] = useState([]);

  // Datos formulario Lo hacemos por separado para poder tener un control de todo.
  const [datosFiltroParte, setDatosFiltroParte] = useState({
    id: '',
    id_hotel: '',
    titulo: '',
    operacion: '',
    tipo: '',
    prioridad: '',
    bloque: [],
    planta: [],
    hab: '',
    fecha_desde: '',
    fecha_hasta: '',
    id_departamento: '',
    cif_proveedor: '',
    nombre_comercial: '',
    id_asignado: '',
    id_creador: '',
    id_tipo_terceros: '',
    historico_observacion: '',
    aplazados: false
  });
  const [datosFiltroParteTemp, setDatosFiltroParteTemp] = useState({});

  const [aplazado, setAplazado] = React.useState(false);
  const [btnAbierto, setBtnAbierto] = React.useState(false);

  const [nuevoParte, setNuevoParte] = React.useState(false);
  const [creandoParte, setCreandoParte] = useState(false);

  //Conjunto para mostrar un Alert con el resultado de la petición
  const [mensajeError, setMensajeError] = useState('');
  const [tipoError, setTipoError] = useState('');
  const [contadorError, setContadorError] = useState(1);
  const [contadorSpinner, setContadorSpinner] = React.useState(false);
  const [estadoAlert, setEstadoAlert] = React.useState(false);

  //Editar partes en Lote
  const [copiaDatos, setCopiaDatos] = useState([]);
  const [estadoDialogLote, setEstadoDialogLote] = useState(false);
  const [confirmarEditarLote, setConfirmarEditarLote] = useState(false);
  const [mostrarTerceros, setMostrarTerceros] = useState(false);


  //Tu codigo
  const dobleClick = (params) => {
    // console.log(params)
    setidParte(params.id)
    dispatch(set_id(params.id))

  };
  const toggleAyuda = () => {
    setOpenAyuda(!openAyuda)
  }

  const mostrarError = (mensajeError, tipoError) => {
    setMensajeError(mensajeError);
    if (tipoError) {
      setTipoError(tipoError);
    } else {
      setTipoError("error");
    }
    setContadorError(contadorError + 1);
    setEstadoAlert(true);
    const timer2 = setTimeout(() => {
      setEstadoAlert(false);
    }, 3000);
    return () => clearTimeout(timer2);
  };

  //Pasar el id de un Parte a Datos Parte
  const dispatch = useDispatch();
  const clickInfoParte = (params, event) => {
    // console.log(event)
    setidParte(params.id)
    dispatch(set_id(params.id))
  };


  //Funcion para obtener el Listado de los Partes.
  const obtenerListadoPartes = () => {
    dispatch(set_id(idParte))
    fd.append("f", "partes.getList");
    fd.append("token", localStorage.getItem('token'));
    let params = '';
    if ({ tipoSalidas }) {
      params += "salidas=" + tipoSalidas + "|";
    }
    if (estadoAbierto === true) {//Si esta true
      params += 'abierto=1|';
    }
    if (btnAbierto === true) {//Si esta true
      params += 'abierto=1|';
    } else {
      params += 'abierto=0|';
    }

    if (idHab) { //Comprobamos si nos llega una habitación por parametro.
      params += 'hab=' + idHab + '|';
    }

    if (datosFiltroParte.id_hotel !== "" && datosFiltroParte.id_hotel !== null) { //Comprobamos si tenemos un hotel 
      params += 'id_hotel=' + datosFiltroParte.id_hotel + '|';
    }
    if (idHotelHab) { //Comprobamos si nos llega un idHotel por parametro.
      params += 'id_hotel=' + idHotelHab + '|';
    }

    const datos = datosFiltroParte;
    Object.keys(datos).forEach((nombre, i) => {
      if (Object.values(datos)[i] !== '' && Object.values(datos)[i] !== undefined) {

        //El caso de Bloque puede ser numerico o string
        if (nombre !== 'id_hotel') {
          if (nombre === "fecha_desde" || nombre === "fecha_hasta") {
            let fecha_ordenada = "";

            // Dividir la cadena de la fecha en partes usando el separador "-"
            const partes = Object.values(datos)[i].split("-");

            // Reorganizar las partes para que la fecha esté en formato "yyyy-mm-dd"
            fecha_ordenada = partes[2] + "-" + partes[1] + "-" + partes[0];
            params += nombre + "=" + fecha_ordenada + '|';
            //setDatosFiltroParte({ ...datosFiltroParte, fecha_hasta: Object.values(datos)[i] });

          } else if (nombre === 'observaciones' || nombre === 'titulo') {
            params += nombre + "=" + "\'" + JSON.stringify(Object.values(datos)[i]) + "\'" + "|";
          } else if (nombre === 'bloque' || nombre === 'planta') {
            let valor = Object.values(datos)[i];
            if (Array.isArray(valor)) { // Si es un array y tiene contenido
              if (valor.length > 0) {
                const bloquesStr = valor.join(',');
                params += nombre + "=" + bloquesStr + "|";
              }
            }
          } else {
            params += nombre + "=" + Object.values(datos)[i] + "|";
          }
        }

      }
    });


    fd.append("params", params);
    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json(), setContadorSpinner(true))
      .then(result => {

        // console.log(params);
        // console.log(result);
        let error = result.error;
        if (error.id === 0) {
          let infoPartes = result.partes;
          Object.keys(infoPartes).forEach((id_parte, i) => {
            var parte = Object.values(infoPartes)[i];
            var parteObj = {};
            Object.keys(parte).forEach((element, j) => {
              parteObj[element] = Object.values(parte)[j];
            });
            partes = [...partes, parteObj];
          });
          // console.log('Info Grid Parte: ');
          // console.log(partes);

          const timer2 = setTimeout(() => {
            setContadorSpinner(false);
            setDatos(partes);
            mostrarError("Actualizado", "success");
          }, 1000);

          return () => clearTimeout(timer2);

        } else if (error.id >= 501 && error.id <= 510) {
          localStorage.setItem('token', 'CADUCADO');
          window.location.reload();
        } else if (error.id === 102) {
          const timer2 = setTimeout(() => {
            setContadorSpinner(false);
            setDatos('');
            mostrarError(error.msg);
            console.log(error.msg);
          }, 1000);
          return () => clearTimeout(timer2);
        } else {
          const timer2 = setTimeout(() => {
            setContadorSpinner(false);
            setDatos('');
            mostrarError(error.msg);
            console.log(error.msg);
          }, 1000);
          return () => clearTimeout(timer2);
        }
      })
  };

  const actualizarGridParte = () => {
    SetActualizo(true)
    SetActualizo2(!actualizo2);
  };

  /******************************************************************/
  //                   Inicio  Dialog crearPartes                   //
  /******************************************************************/
  //Comprobación de si ha realizado un enter en el campo Habitación.
  const comprobarEnterHab = (e) => {
    if (e.key === 'Enter') {
      comprobarHabitacion();
    }
  };
  const comprobarHabitacion = () => {
    buscarHabitacion();
    buscarPartesHabitacion();
  }

  //Funcion para buscar el bloque y planta. Dando el hotel y habitación. Se ejecuta dando enter en el campo habitacion o click en el buscador.
  const buscarHabitacion = async () => {
    let datos = datosNuevoParte;
    let estado = true;
    if (datos.id_hotel !== '' && datos.hab !== '' && datos.hab !== undefined && datos.id_hotel !== undefined) {
      fd.append("f", "habitaciones.get");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_hab", datos.hab);
      fd.append("id_hotel", datos.id_hotel);
      await fetch('https://api.workers.grupotel.com/', requestOptions)
        .then(response => response.json())
        .then(result => {
          //console.log(result)
          if (result) {
            let infoPartes = result;
            setDatosNuevoParte({ ...datosNuevoParte, "bloque": infoPartes.bloque, "planta": infoPartes.planta });
            setErrorHabitacionPartes(false);
            estado = false;
          }
        })//En caso de error mostramos nuestro mensaje
        .catch(() => {
          mostrarError("No existe la habitación: " + datos.hab);
          console.log("No existe la habitación: " + datos.hab);
          setDatosNuevoParte({ ...datosNuevoParte, "bloque": '', "planta": '' });
          setErrorHabitacionPartes(true);
        })
    } else if (datos.id_hotel === '' && datos.id_hotel !== undefined) {
      mostrarError("No ha seleccionado un hotel.");
      console.log("No ha seleccionado un hotel.");
      setErrorHabitacionPartes(true);
    } else if (datos.hab === '' && datos.hab !== undefined) {
      //Si no hay habitación seleccionada vaciar bloque y planta.
      setDatosNuevoParte({ ...datosNuevoParte, "bloque": '', "planta": '' });
      setErrorHabitacionPartes(true);
    } else {
      setDatosNuevoParte({ ...datosNuevoParte, "bloque": '', "planta": '', "hab": '' });
      setErrorHabitacionPartes(true);
    }
    return estado;
  };

  const buscarPartesHabitacion = () => {

    //Peticion para obtener todos los partes de la habitacion 
    if (datosNuevoParte.hab !== '' && datosNuevoParte.hab !== undefined) {
      fd.append("f", "partes.getByRoom");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_hotel", datosNuevoParte.id_hotel);
      fd.append("hab", datosNuevoParte.hab);
      fd.append("abiertos", 1);
      fetch(API_URL, requestOptions)
        .then((response) => {
          return response.json()
        })
        .then((result) => {
          let error = result.error;
          if (error.id === 0) {
            delete result.error;
            setListaPartesRoom(result.partes);
            //console.log(result);
          } else if (error.id === 102) {
            setListaPartesRoom('');
          } else {
            //console.log(result);
            setListaPartesRoom('');
            mostrarError(error.msg);
            console.log(error.msg);
          }
        })
        .catch(() => {
          mostrarError("Error Api. Obtener los partes de una habitacion.");
          console.log("Error Api. Obtener los partes de una habitacion.");
        })
    }
  }
  //Boolean para mostrar los historicos del parte que le indiquemos.
  const [isOpenCollapse, setIsOpenCollapse] = useState(null);
  const abrirHistorial = (clickedIndex) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  /******************************************************************/
  //                  Inicio Departamentos                          //
  /******************************************************************/

  //Funcion onChange del Dialog. Busca todos los departamentos de un hotel.
  const buscarDepartamentos = (id_hotel) => {
    if (id_hotel !== 0) {
      fd.append("f", "partes.getLocationsList");
      fd.append("token", localStorage.getItem('token'));
      fd.append("id_hotel", id_hotel);
      fetch('https://api.workers.grupotel.com/', requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          let error = result.error;
          if (error.id === 0) {
            let departamentosLista = result;
            //Funcion para limpiar las listas.
            vaciarDepartamentos();
            //Guardamos todos los departamentos.
            todosDepartamentos.push(departamentosLista);
            // console.log(departamentosLista)
            //LLamamos a la funcion para mostrar los departamentos padre con idPadre 0
            obtenerDepartamento(0);
          }
        })
        .catch(() => {
          mostrarError("No se ha podido obtener departamentos.");
          console.log("No se ha podido obtener departamentos.");
        })
    }
  };

  //Obtiene el id del Padre y mapea todos los departamentos con ese mismo idPadre.
  const obtenerDepartamento = (idPadre, textoPadre, back) => {
    let departamentosLista = todosDepartamentos[0];
    departamentosListado.length = 0;
    //Comprobar y obtener los que tengan el idPadre
    Object.keys(departamentosLista).map((index, i) => {
      if (index !== 'error') {
        var departamento = Object.values(departamentosLista)[i];
        // console.log('dep: ', departamento);
        if (parseInt(departamento.id_padre) === parseInt(idPadre)) {
          departamentosListado.push({ value: departamento.id_lugar, label: departamento.nombre, padre: departamento.id_padre, indice: departamento.indice });
        }
      }
      return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
    })

    //Si hay departamentos con el mismo idPadre mostrarlos
    if (departamentosListado.length !== 0) {
      // departamentosListado.sort((a, b) => (a.label > b.label) ? 1 : -1);
      departamentosListado.sort((a, b) => {
        // Manejar el caso en el que no se proporciona un índice
        if (a.indice === undefined && b.indice === undefined) {
          // Si ambos elementos no tienen índice, ordenar alfabéticamente por nombre
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        } else if (a.indice !== undefined && b.indice !== undefined) {
          // Si ambos elementos tienen índice definido, ordenar por índice
          return a.indice - b.indice;
        } else {
          // Si uno tiene índice definido y el otro no, el que tenga índice definido debe ir primero
          return a.indice !== undefined ? -1 : 1;
        }
      });

      departamentosNuevoParte.length = 0;
      departamentosNuevoParte.push(...departamentosListado);
      //Si es Departamento Padre inicializamos la posicion.
      if (idPadre === 0) {
        setPosicionActual(1);
      } else {
        //Si es un sub departamento guardamos la posicion.
        setPosicionActual(posicionActual + 1);
      }
    }
    //En el caso de no ser un back .
    if (back === undefined) {
      //Caso de un Sub departamento.
      //Si tenemos id del Departamento y la posicionActual no es 0
      if (rutaActual.idDepartamento !== '' && posicionActual >= 1) {
        var listaRutas = rutaActual.idDepartamento.split(",");
        setActivarAtras(false);
        //Funcion para cambiar el contenido del ultimo id y nombre.
        const actualizarUltimo = () => {
          let rutaId = rutaActual.idDepartamento.split(",");
          rutaId[listaRutas.length - 1] = idPadre;
          let rutaDepartamento = rutaActual.nombre.split("-");
          rutaDepartamento[listaRutas.length - 1] = textoPadre;
          setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
        }
        //Si el ultimo id es igual a idPadre. Y estamos en la misma posicion reemplazamos el contenido.
        if (listaRutas[listaRutas.length - 1] === idPadre && posicionActual === listaRutas.length) {
          actualizarUltimo();
        } else {
          //Caso de que en la misma posicion se ha introducido un id distinto.
          //Se compara que estemos en la misma posicion,que el tamaño del array. Y si la ultima posicion tiene un id diferente lo reemplazamos.
          if (posicionActual === listaRutas.length && listaRutas[listaRutas.length - 1] !== idPadre) {
            actualizarUltimo();
          } else {
            //Si la posicion es la misma, pero el id es distinto añdimos le nuevo valor.
            let rutaId = rutaActual.idDepartamento + "," + idPadre;
            let rutaDepartamento = rutaActual.nombre + "-" + textoPadre;
            setRutaActual({ idDepartamento: rutaId, nombre: rutaDepartamento });
          }
        }
      } else {
        //Caso inicial de idPadre 0
        if (textoPadre !== undefined) {
          setActivarAtras(false);
          setRutaActual({ idDepartamento: idPadre, nombre: textoPadre });
        }
      }
    }
  };

  //Obtenemos el id del padre que puede venir del div o el span.
  const obtenerSubDepartamento = (event) => {
    // Si nos llega un onclick del texto. span obtenemos el contenido
    let id = event.target.parentElement.id;
    let texto = event.target.innerText;
    //Si no era un span el id estara vacio. En este caso obtenemos el id del div
    if (id === '') {
      id = event.target.id;
    }
    //Mostramos la flecha para volver atras si es un sub departamento.
    document.querySelector('.botonAtras').classList.remove('ocultar');

    if (id !== undefined) {
      obtenerDepartamento(id, texto);
      setErrorDepartamentosPartes(false);
    }
    setTextoBusqueda('')
    setDepartamentosFiltrados([])
  };

  const vaciarDepartamentos = () => {
    todosDepartamentos.length = 0;
    rutaActual.idDepartamento = "";
    rutaActual.nombre = 'Nuevo parte';
    setPosicionActual(0);
    setRutaActual({ idDepartamento: '', nombre: '' });
    departamentosNuevoParte.length = 0;
    setDatosNuevoParte({ ...datosNuevoParte, "titulo": '' })
  };

  //Al realizar un cambio en un Textfield. Se guardara la información en un constructor.
  const cambioNuevoParte = (event) => {
    if (event.target.name === 'id_hotel') {
      datosNuevoParte.id_hotel = "";
      datosNuevoParte.id_hotel = event.target.value;
      //Guardamos el hotel en el LocalStorage
      localStorage.setItem('idHotelSeleccionado', event.target.value);

      document.querySelector('.botonAtras').classList.remove('ocultar');
      buscarDepartamentos(event.target.value);

      // Vaciamos la información de bloque, planta y hab. También el listado de partes, cuando se cambia de hotel en el dialog NuevoParte
      //setDatosNuevoParte({ ...datosNuevoParte, "hab": '', "bloque": '', "planta": ''})
      datosNuevoParte.hab = '';
      datosNuevoParte.bloque = '';
      datosNuevoParte.planta = '';
      setListaPartesRoom('');
      //Reiniciamos por si acaso el error de Habitación.
      setErrorHabitacionPartes(false);
      actualizarGridParte();
    } else {
      // console.log(event.target.value)
      setDatosNuevoParte({ ...datosNuevoParte, [event.target.name]: event.target.value })
    }
  }

  //Funcion de flecha para volver al departamento anterior.
  const backDepartamento = () => {
    //Obtenemos el penultimo id y texto
    let rutaId = rutaActual.idDepartamento.split(",");

    let rutaDepartamento = rutaActual.nombre.split("-");
    rutaId.pop();
    rutaDepartamento.pop();
    if (rutaId[rutaId.length - 1] !== undefined) {
      //listaRutas[listaRutas.length - 1]
      obtenerDepartamento(rutaId[rutaId.length - 1], rutaDepartamento[rutaDepartamento.length - 1], 1);
      setPosicionActual(rutaId.length + 1);
      setRutaActual({ idDepartamento: rutaId.toString(), nombre: rutaDepartamento.join("-") });
    } else {
      rutaActual.idDepartamento = "";
      rutaActual.nombre = 'Nuevo parte';
      setRutaActual({ idDepartamento: "", nombre: "" });
      obtenerDepartamento(0);
      setActivarAtras(true);
      setErrorDepartamentosPartes(true);
    }
    setTextoBusqueda('')
    setDepartamentosFiltrados([])
  }
  // Funcion de flecha para buscar entre los departamentos / lugares
  const handleSearchText = (e) => {
    const searchText = e.target.value.toLowerCase();
    setTextoBusqueda(searchText);
    const departamentosEncontrados = departamentosNuevoParte.filter(departamento =>
      departamento.label.toLowerCase().includes(searchText)
    );
    setDepartamentosFiltrados(departamentosEncontrados);
  }



  /******************************************************************/
  //                  Fin Departamentos                             //
  /******************************************************************/

  //Creación y gestion de componentes de Nuevo Parte
  const mostrarNuevoParte = () => {
    obtenerTiposParte();
    setCreandoParte(false);
    SetHotelesLista(JSON.parse(hoteles));
    document.querySelector('.nuevoParte').classList.add('pintado');
    vaciarDepartamentos();
    if (localStorage.getItem('idHotelSeleccionado')) {
      buscarDepartamentos(localStorage.getItem('idHotelSeleccionado'));
      //Si tenemos guardado el Hotel mostramos el Dialog crear parte con el hotel seleccionado.
      datosNuevoParte.id_hotel = localStorage.getItem('idHotelSeleccionado');
      //document.querySelector('.botonAtras').classList.remove('ocultar');
    }
    setNuevoParte(true);
  };

  const ocultarNuevoParte = () => {
    setNuevoParte(false);
    setErrorDepartamentosPartes(false);
    setErrorHotelPartes(false);
    setErrorHabitacionPartes(false);//No estoy muy seguro si hay que limpiar este error.
  };
  const comprobarESCNuevoParte = (event) => {
    if (event.keyCode === 27) {
      ocultarNuevoParte();
    }
  }
  const limpiarNuevoParte = () => {
    let ultimoHotel = datosNuevoParte.id_hotel;
    setDatosNuevoParte({
      id_hotel: ultimoHotel,
      titulo: '',
      rama: '',
      tipo: '',
      hab: '',
      bloque: '',
      planta: '',
      nombre: '',
      observaciones: ''
    });
    setListaImagenesNuevoParte([]);
    setListaPDFNuevoParte([]);
    setErrorDepartamentosPartes(false);
    setErrorHotelPartes(false);
    setErrorHabitacionPartes(false);
    setListaPartesRoom('');
    document.querySelector('.nuevoParte').classList.remove('pintado');
    setNuevoParte(false);
  };

  //Función para controlar las imagenes y pdf añadidos
  const selecImage = (event) => {
    const files = event.target.files;

    // Verificar si se seleccionó algún archivo
    if (files.length === 0) {
      // Vaciar las listas de imágenes y PDFs, si no se seleccionó ningún archivo
      setListaImagenesNuevoParte([]);
      setListaPDFNuevoParte([]);
      event.target.value = null;  // Limpiar el input
      return;
    }
    //console.log(files)

    let nuevasImagenes = [];
    let nuevosPDFs = [];

    // Filtrar los archivos de imagen y PDF
    const imagenes = Array.from(files).filter(file => file.type.startsWith('image/'));
    const pdfs = Array.from(files).filter(file => file.type === 'application/pdf');

    //Variable para saber el peso de los archivos.
    let pesoArchivos = 0;

    // Montar el array de imágenes
    imagenes.forEach((file, index) => {
      pesoArchivos += file.size;
      nuevasImagenes.push({
        id: index,
        ruta: URL.createObjectURL(file),
        nombre: file.name,
        archivo: file
      });
    });

    // Montar el array de PDFs
    pdfs.forEach((file, index) => {
      let nombrePdf = "";
      if (file.name.length > 29) {
        nombrePdf = file.name.substring(0, 29) + "...";
      } else {
        nombrePdf = file.name;
      }
      pesoArchivos += file.size;
      nuevosPDFs.push({
        id: index,
        ruta: URL.createObjectURL(file),
        nombre: nombrePdf,
        archivo: file
      });
    });

    //Comprobación de si se supera el maximo de 32
    if (pesoArchivos >= 32000000) {
      // console.log(pesoArchivos);
      mostrarError("Los archivos superan los 32 MB");
      console.log("Los archivos superan los 32 MB");
      setListaPDFNuevoParte([]);
      setListaImagenesNuevoParte([]);
      event.target.value = null;  // Limpiar el input
      return;
    }

    setListaImagenesNuevoParte(nuevasImagenes);
    setListaPDFNuevoParte(nuevosPDFs);
  };

  // Quitar la imagen seleccionada
  const borrarImagenNuevoParte = (id) => {
    setListaImagenesNuevoParte(listaImagenesNuevoParte.filter((foto) => foto.id !== id));
  };
  // Quitar el pdf seleccionado
  const borrarPDFNuevoParte = (id) => {
    setListaPDFNuevoParte(listaPDFNuevoParte.filter((pdf) => pdf.id !== id));
  };

  // Abrir imagen o pdf  en ventanda nueva
  const ventanaImagen = (ruta) => {
    const rutaMostrar = ruta.includes("min_") ? ruta.replace("min_", "") : ruta;
    window.open(rutaMostrar);
  };

  //Funcion del Dialog de Crear Parte comprobamos los datos introducidos por el usuario y creamos el parte.
  const crearParte = async () => {
    setCreandoParte(true);
    // console.log("En principio he puesto ")
    // console.log(creandoParte)

    if (rutaActual.idDepartamento !== '') {
      datosNuevoParte.rama = rutaActual.idDepartamento;
    }
    //Comprobamos si tenemos el Bloque
    if (datosNuevoParte.bloque === undefined || datosNuevoParte.bloque === '') {
      datosNuevoParte.bloque = '0';
    }
    //Comprobamos si tenemos la Planta
    if (datosNuevoParte.planta === undefined || datosNuevoParte.planta === '') {
      datosNuevoParte.planta = '0';
    }
    //Por defecto ponemos el tipo de parte en Normal.
    if (datosNuevoParte.tipo === '') {
      datosNuevoParte.tipo = 'normal';
    }

    let datos = datosNuevoParte; //Antes era const, seguramente para mantener la información cuando se recarga la pagina. Se hacia copia para instanciar y obtener los datos reales/actuales.
    let estadoHab = false;
    //Solicitamos la comprobación de habitación
    if (datosNuevoParte.hab === undefined || datosNuevoParte.hab === '') {
      datosNuevoParte.hab = '0';
    } else {
      //comprobarHabitacion();
      // estadoHab = buscarHabitacion();
      estadoHab = await buscarHabitacion();
    }

    /*
    console.log("Lo datosNuevoParte ");
    console.log(datosNuevoParte);
    console.log("Info datos:")
    console.log(datos);
    console.log("Ruta id departamento")
    console.log(rutaActual.idDepartamento)
    */

    if (estadoHab == false) {
      if (datosNuevoParte.id_hotel !== '' && datosNuevoParte.rama !== '') {
        var str = '';
        var id = localStorage.getItem('id');
        str += "id_usuario=" + id + "|id_creador=" + id + "|";
        Object.keys(datosNuevoParte).forEach((nombre, i) => {
          if (Object.values(datosNuevoParte)[i] !== '' && Object.values(datosNuevoParte)[i] !== undefined) {
            if (nombre !== 'bloque' && nombre !== 'planta') {
              if (nombre === 'observaciones') {
                const observacionesValue = Object.values(datosNuevoParte)[i].replace(/\|/g, ' ');
                str += nombre + "=" + observacionesValue + "|";
              } else {
                str += nombre + "=" + Object.values(datosNuevoParte)[i] + "|";
              }
            }
          }
        });

        fd.append("f", "partes.set");
        fd.append("token", localStorage.getItem('token'));
        fd.append("params", str);
        //Recorremos el listado de Imagenes añadidas al parte.
        Object.values(listaImagenesNuevoParte).forEach((imagen, i) => {
          fd.append("imagen" + i, imagen.archivo);
        });

        //Recorremos los archivos pdf
        listaPDFNuevoParte.forEach((pdf, i) => {
          fd.append("pdf" + i, pdf.archivo);
        });

        try {
          fetch('https://api.workers.grupotel.com/', requestOptions)
            .then(response => response.json())
            .then(result => {
              let error = result.error;
              // console.log(result)
              if (error.id === 0) {
                mostrarError("Se ha creado el Parte con ID: " + result.id, "success");
                //document.querySelector('.nuevoParte').classList.remove('pintado');
                limpiarNuevoParte();
                ocultarNuevoParte();
                //SetActualizo(true);
                actualizarGridParte();
              } else {
                mostrarError(error.msg);
                setCreandoParte(false);
                console.log(error.msg);
              }
            })
        } catch (error) {
          mostrarError(error);
          console.log(error);
          setCreandoParte(false);
        }

      } else if ((datosNuevoParte.id_hotel === '' && datosNuevoParte.rama === '') || (datosNuevoParte.id_hotel === undefined && datosNuevoParte.rama === '')) {
        mostrarError("No ha seleccionado el Hotel y el Departamento.");
        console.log("No ha seleccionado el Hotel y el Departamento.");
        setErrorDepartamentosPartes(true);
        setErrorHotelPartes(true);
        setCreandoParte(false);
      } else if (datosNuevoParte.rama === '') {
        mostrarError("No ha seleccionado el Departamento.");
        console.log("No ha seleccionado el Departamento.");
        setErrorDepartamentosPartes(true);
        setCreandoParte(false);
      } else {
        mostrarError("No ha seleccionado un hotel.");
        console.log("No ha seleccionado un hotel.");
        setErrorHotelPartes(true);
        setCreandoParte(false);
      }
    }
  };

  /******************************************************************/
  //                Fin Dialog crearPartes                          //
  /******************************************************************/

  /******************************************************************/
  //                    Inicio  Dialog Filtros                      //
  /******************************************************************/
  // Inicio de la gestión de Filtros.
  const mostrarFiltros = () => {
    obtenerDatosSelect();

    // Guardamos los datos temporalmente
    setDatosFiltroParteTemp({ ...datosFiltroParte });
    // setUsuariosHotel([{ value: '', label: '' }]);

    //AÃÂ±adir la clase Pintado. Para mostrar que los filtros siguen activos.
    setActivoFiltro(true);

    obtenerListaTerceros();//Lista de Terceros
    obtenerUsuarios();//Obtener la lista de Usuarios del mismo departamento en el mismo hotel.

    let hotelesArray = JSON.parse(hoteles);
    //SetHotelesLista(hotelesArray);

    // Si el usuario solo tiene un hotel.
    if (hotelesArray.length == 1) {
      listarDepartamentosFiltros(hotelesArray[0].value);
      listarUsuariosHotel(hotelesArray[0].value);
      obtenerBloquesHotel(hotelesArray[0].value);
      setDatosFiltroParte({ ...datosFiltroParte, 'id_hotel': hotelesArray[0].value });

      //Comprobamos si tenemos seleccionado un hotel.
    } else if (datosFiltroParte.id_hotel !== "" && datosFiltroParte.id_hotel !== null) {
      listarDepartamentosFiltros(datosFiltroParte.id_hotel);//Obtenemos los departamentos de ese Hotel.
    } else {
      listarDepartamentosFiltros(hotelesArray[0].value);
      // setDepartamentosLista([]);
    }
    hotelesArray.push({ value: '', label: '' });
    SetHotelesLista(hotelesArray);

    SetActualizo2(!actualizo2);
    setEstadoFiltros(true);
  };

  // Boton cancelar. Volvemos a inicializar todos los campos del Dialog para filtrar los partes. También ocultamos el Dialog.
  const eliminarFiltros = () => {
    setDatosFiltroParte({
      id: '',
      id_hotel: '',
      titulo: '',
      operacion: '',
      tipo: '',
      prioridad: '',
      bloque: [],
      planta: [],
      hab: '',
      fecha_desde: '',
      fecha_hasta: '',
      id_departamento: '',
      cif_proveedor: '',
      nombre_comercial: '',
      id_asignado: '',
      id_creador: '',
      id_tipo_terceros: '',
      historico_observacion: '',
      aplazados: false
    });
    setAplazado(false);
    setPlantasLista([]);
    setBloquesLista([]);
    setDepartamentosLista([]);
    setUsuariosHotel([])
  };
  // Función para actualizar la fecha en datosFiltroParte
  const actualizarFechaHasta = (fecha) => {
    setDatosFiltroParte({ ...datosFiltroParte, fecha_hasta: fecha });
  };
  const actualizarFechaDesde = (fecha) => {
    setDatosFiltroParte({ ...datosFiltroParte, fecha_desde: fecha });
  };
  const ocultarFiltros = () => {
    setEstadoFiltros(false);
    actualizarGridParte();
  };
  const ocultarFiltros2 = () => { // Solo oculta los filtros.
    setEstadoFiltros(false);
    setDatosFiltroParte(datosFiltroParteTemp);

    comprobarEstadoFiltros();
  };
  const comprobarESC = (event) => {
    if (event.keyCode === 27) {
      ocultarFiltros();
    }
  }
  const comprobarEstadoFiltros = () => { // Comprobamos si hay algun valor en datosFiltroParte
    const hayFiltrosActivos = Object.values(datosFiltroParte).some(
      (valor) => valor !== '' && valor !== false && valor !== 0 && valor.length !== 0
    );

    setActivoFiltro(hayFiltrosActivos);
  }
  //Cuando se realiza un cambio en el Dialog de Filtros guardamos la informacion.
  const actualizarFiltros = (event) => {
    // console.log(event);

    const { name, value } = event.target;

    if (name === 'bloque') {
      // Si la opción vacía se selecciona junto con otras opciones
      var temp = typeof value === 'string' ? value.split(',') : value
      // Si no hay opción vacía seleccionada, procesamos normalmente
      setDatosFiltroParte({
        ...datosFiltroParte,
        [name]: temp // Aseguramos que 'value' sea siempre un array
      });
      if (temp.length > 0) {
        obtenerPlantasHotel(temp);
      } else {
        setPlantasLista([]);
      }

    } else if (name === 'id') {
      const validValue = value.replace(/[^0-9,]/g, ''); // Eliminar cualquier carácter no numérico o coma
      setDatosFiltroParte({
        ...datosFiltroParte,
        [name]: validValue,
      });
    } else if (name === 'id_hotel') {
      setDatosFiltroParte({ ...datosFiltroParte, [name]: value });
      listarDepartamentosFiltros(value);
      listarUsuariosHotel(value);
      obtenerBloquesHotel(value);
      if (value !== "") {
        // Guardamos el hotel en el LocalStorage
        localStorage.setItem('idHotelSeleccionado', value);
      }
      setDatosFiltroParte({ ...datosFiltroParte, 'id_hotel': value });
    } else {
      setDatosFiltroParte({
        ...datosFiltroParte,
        [name]: value,
      });
    }
  };

  const obtenerValueFiltros = (event) => {
    //Como el  event.target.value devolvia 0 obtenemos el id del hotel directamente del data-value
    const value = event.target.value;
    if (value !== undefined) {
      listarDepartamentosFiltros(event.target.attributes[4].value);
    }
  }
  //Busqueda departamentos.
  const listarDepartamentosFiltros = (id_hotel) => {
    fd.append("f", "partes.getLocationsTree");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_hotel", id_hotel);
    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          let departamentosLista = result;
          departamentos.length = 0;
          Object.keys(departamentosLista).forEach((id, i) => {
            if (id !== 'error') {
              var departamento = Object.values(departamentosLista)[i].nombre;
              departamentos.push({ value: id, label: departamento });
              //setDepartamentosLista({ ...departamentos, value: key, label: departament  })
            }
          })
          setDepartamentosLista(departamentos.sort((a, b) => (a.label > b.label) ? 1 : -1));
          actualizarGridParte();
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener departamentos.");
        console.log("No se ha podido obtener departamentos.");
      })
  };
  //Obtener el listado de Terceros. Los Proveedores.
  const obtenerListaTerceros = () => {
    fd.append("f", "proveedores.getList");
    fd.append("token", localStorage.getItem('token'));
    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        let error = result.error;
        if (error.id === 0) {
          let listaTerceros = [];
          let tipoTerceros = [];
          listaTerceros.push({ value: '', label: ' ' });
          Object.values(result.proveedores).map((index, i) => { //Recorremos los Proveedores y los añadimos a autocomplete.
            listaTerceros.push({ value: index['cif'], label: index['nombre_comercial'] });
            return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
          })
          setTercerosLista(listaTerceros.sort((a, b) => (a.label > b.label) ? 1 : -1));//Contendra la lista de Terceros "Navision, Acens, etc..."
          tipoTerceros.push({ value: '', label: '' });
          Object.values(result.tipo_terceros).map((tipo, i) => { //Recorremos los tipos de Terceros y los añadimos a nuestra lista con la estructura del select.
            tipoTerceros.push({ value: Object.keys(result.tipo_terceros)[i], label: tipo });
            return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
          })
          setTipoTercerosLista(tipoTerceros);//Contendra los tipos de Terceros "Reparacion, Compra, Esperando"
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los Terceros.");
        console.log("No se ha podido obtener los Terceros.");
      })
  }
  const comprobarEnter = (e) => {
    if (e.key === 'Enter') {
      guardarDatos();
    }
  }
  //Funcion para realizar el Filtro de los partes.
  const guardarDatos = () => {

    const datos = datosFiltroParte;
    var str = '';

    Object.keys(datos).forEach((nombre, i) => {
      if (Object.values(datos)[i] !== '' && Object.values(datos)[i] !== undefined) {
        if (nombre === "fecha_hasta" || nombre === "fecha_desde") {
          let fecha_ordenada = "";

          // Dividir la cadena de la fecha en partes usando el separador "-"
          const partes = Object.values(datos)[i].split("-");

          // Reorganizar las partes para que la fecha esté en formato "yyyy-mm-dd"
          fecha_ordenada = partes[2] + "-" + partes[1] + "-" + partes[0];
          str += nombre + "=" + fecha_ordenada + '|';

        } else if (nombre === "operacion" && Object.values(datos)[i] === "abierto") {//Caso de Operacion Abierto
          str += 'abierto=1|';
        } else if (nombre === "bloque" || nombre === "planta") {
          if (Object.values(datos)[i].length > 0) {
            str += nombre + "=" + Object.values(datos)[i] + '|'
          }
        } else if (nombre === "aplazados") {
          if (Object.values(datos)[i] === true) {
            str += nombre + "=" + Object.values(datos)[i] + '|'
          }
        } else {
          str += nombre + "=" + Object.values(datos)[i] + '|'
        }
      }
    });

    // console.log("Información Filtros")
    // console.log(datos);
    // console.log(str)

    setEstadoFiltros(false);
    actualizarGridParte();
    setActivoFiltro(false);
    comprobarEstadoFiltros();
  };

  //Caso aplazado.
  const estadoAplazado = (event) => {
    setAplazado(event.target.checked);
    setDatosFiltroParte({
      ...datosFiltroParte,
      [event.target.name]: event.target.checked ? 1 : 0
    })
  };
  const cambioProveedor = (event) => {
    //Si se ha realizado un cambio en el autocomplete de Proveedores. 
    //Primero comprobamos si podemos obtener un valor del index.
    if (event.target.dataset.optionIndex) {
      //console.log(event.target.innerText);//Obtenemos el Nombre del Proveedor
      //Obtenemos el valor del cif seleccionado usando la posicion del index.
      let cif = "";
      Object.values(terceros).map((index, i) => {
        if (index.label === event.target.innerText) {
          cif = index.value;
        }
        return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
      })
      setDatosFiltroParte({
        ...datosFiltroParte,
        "cif_proveedor": cif, "nombre_comercial": event.target.innerText
      });
    } else if (event.target.dataset.testid === "CloseIcon") {
      //En caso contrario se habra realizado click en la X para borrar el contenido de Proveedores

      //Primero vaciamos el contenido del Autocomplete.
      document.querySelector("#completeProveedor").value = "";
      //Vaciamos el contenido de la variable
      setDatosFiltroParte({
        ...datosFiltroParte,
        "cif_proveedor": "", "nombre_comercial": ""
      });
    }
  };
  const cambioProveedorEditar = (event) => {

    if (event.target.dataset.optionIndex) {
      let cif = "";
      Object.values(terceros).map((index, i) => {
        if (index.label === event.target.innerText) {
          cif = index.value;
        }
        return null;
      })
      setDatosEditarParte({
        ...datosEditarParte,
        "cif_proveedor": cif, "nombre_comercial": event.target.innerText
      });
    } else if (event.target.dataset.testid === "CloseIcon") {
      document.querySelector("#proveedorAuto").value = "";
      setDatosEditarParte({
        ...datosEditarParte,
        "cif_proveedor": "", "nombre_comercial": ""
      });
    }
  };

  const listarUsuariosHotel = (id_hotel) => {
    //Contendrá el listado de usuarios temporalmente.
    let usuarios = [];
    //Añadimos el primer valor vacio para el select.
    usuarios.push({ value: '', label: '' });

    fd.append("f", "usuarios.getUsuariosHotel");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_hotel", id_hotel);

    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        //Recorremos el array de Usuarios para obtener sus id de Usuario y nombres.
        if (result.usuarios) {
          usuarios = usuarios.concat(
            Object.keys(result.usuarios).map(id => ({
              value: id,
              label: result.usuarios[id]
            }))
          );
          setUsuariosHotel(usuarios.sort((a, b) => a.label.localeCompare(b.label)));
          let rol = localStorage.getItem('id_rol');
          if (rol == 12) { //Si es director en asignados mostramos todos los usuarios
            setUsuariosLista(usuarios.sort((a, b) => a.label.localeCompare(b.label)));
          }
        } else {
          setUsuariosHotel([]);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido listar los Usuarios.");
        console.log("No se ha podido listar los Usuarios.");
      })
  }

  // Función para obtener los usuarios de nuestro departamento.
  const obtenerUsuarios = () => {
    //Contendrá el listado de usuarios temporalmente.
    let usuarios = [];
    //Añadimos el primer valor vacio para el select.
    usuarios.push({ value: '', label: '' });

    fd.append("f", "usuarios.getList");
    fd.append("token", localStorage.getItem('token'));
    //Obtenemos los campos guardados en el localStorage.
    let str = "id_hotel=" + localStorage.getItem('idHotelSeleccionado') + "|id_departamento=" + localStorage.getItem('id_departamento') + '|';
    fd.append("params", str);

    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        delete result.error;
        //Recorremos el array de Usuarios para obtener sus id de Usuario y nombres.
        Object.keys(result.usuarios).map((id, i) => {
          var nombreUsuario = Object.values(result.usuarios)[i].nombre;
          usuarios.push({ value: id, label: nombreUsuario });
          return null; // Agregar este return al final de la función de flecha del map. Ya que espera retornar algo.
        })
        //Guardamos el listado de Usuarios para el Select de Editar Parte.
        setUsuariosLista(usuarios.sort((a, b) => (a.label > b.label) ? 1 : -1));

      })
      .catch(() => {
        mostrarError("No se ha podido listar los Usuarios.");
        console.log("No se ha podido listar los Usuarios.");
      })
  }

  // Función para obtener todos los bloques de un hotel.
  const obtenerBloquesHotel = (id_hotel) => {

    let bloques = [];
    //Añadimos el primer valor vacio para el select.
    // bloques.push({ value: '', label: '' });

    fd.append("f", "hotel.getBloquesHotel");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_hotel", id_hotel);

    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error.id === 0) {
          let bloquesTransformados = result.bloques.map(bloque => ({
            value: bloque.id,
            label: bloque.bloque
          }));
          // Concadenamos los valores por defecto con el array de bloques
          bloques = bloques.concat(bloquesTransformados).sort((a, b) => (a.label > b.label) ? 1 : -1);

          setBloquesLista(bloques);
        } else {
          setBloquesLista([]);
          setDatosFiltroParte({
            ...datosFiltroParte,
            'bloque': [],
            'id_hotel': id_hotel
          });
          // mostrarError(result.error.msg);
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener los Bloques del Hotel");
        console.log("No se ha podido obtener los Bloques del Hotel");
      })
  }

  // Función para obtener las plantas de Uno o Varios Bloques
  const obtenerPlantasHotel = (id_bloque) => {
    fd.append("f", "hotel.getPlantasHotel");
    fd.append("token", localStorage.getItem('token'));
    fd.append("id_hotel", datosFiltroParte.id_hotel);
    fd.append("id_bloque", id_bloque);


    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error.id === 0) {
          // console.log(result.plantas)
          setPlantasLista(result.plantas);
        } else {
          setPlantasLista([]);
          setDatosFiltroParte({
            ...datosFiltroParte,
            'planta': [], // Nos aseguramos de que 'value' sea siempre un array
          });
          mostrarError(result.error.msg);
          console.log(result.error.msg)
        }
      })
      .catch(() => {
        mostrarError("No se ha podido obtener las Plantas del Hotel");
        console.log("No se ha podido obtener las Plantas del Hotel");
      })
  }

  //Obtener un pdf con el listado de partes filtrados.
  const listaInformes = () => {
    // console.log(sortModel);
    //Pongo por defecto que se ordene por la ultima fecha evento.
    const sortField = sortModel.length > 0 ? sortModel[0].field : 'fecha_evento_en';
    const sortOrder = sortModel.length > 0 ? sortModel[0].sort : 'desc';

    // Si sortField es 'titulo_corto', cambiar a 'titulo'
    const fieldToSortBy = sortField === 'titulo_corto' ? 'titulo' : sortField;

    // Ordenar el array según sortModel
    const sortedDatos = [...datos].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[fieldToSortBy] > b[fieldToSortBy] ? 1 : -1;
      } else {
        return a[fieldToSortBy] < b[fieldToSortBy] ? 1 : -1;
      }
    });

    // Obtener los id_parte en orden
    const idParteString = sortedDatos.map(item => item.id_parte).join(',');
    // console.log('ID Parte String:', idParteString);
    /*
    // Ordenar el array por fecha_evento_en DESC
    const sortedDatos = [...datos].sort((a, b) => new Date(b.fecha_evento_en) - new Date(a.fecha_evento_en));

    // Obtener los id_parte en orden
    const idParteString = sortedDatos.map(item => item.id_parte).join(',');
*/
    // console.log("Los Datos de partes son:")
    // console.log(datos);
    // console.log(sortedDatos);

    // console.log("La lista de id es: ")
    // console.log(idParteString);

    let url = "https://api.workers.grupotel.com/pdf-lib/index.php?token=workers2&action=getPartesPdfWorkers2&idsPartes="
    let final_url = url + idParteString;
    window.open(final_url, '_blank', 'noreferrer')
  }

  /******************************************************************/
  //                     Fin  Dialog Filtros                        //
  /******************************************************************/

  /******************************************************************/
  //                     Edición en Lote                            //
  /******************************************************************/

  const activarEdicionLote = () => {
    if (datos.length > 0) {
      obtenerDatosSelect();
      // Ordenar el array por fecha_evento_en ASC
      var sortedDatos = [...datos].sort((a, b) => new Date(a.fecha_evento_en) - new Date(b.fecha_evento_en));

      // Obtener los id_parte en orden
      var idParteString = sortedDatos.map(item => item.id_parte).join(',');
      // console.log(idParteString);

      //obtenerListaTerceros();// No creo que sea necesario, pero por si acaso.

      setDatosEditarParte({ ...datosEditarParte, "listaId": idParteString });

      // Por defecto asignaremos automaticamente al usuario.
      var id = localStorage.getItem('id');
      var nombre = localStorage.getItem('nombre');
      const nuevoAsignado = { value: id, label: nombre };
      setPersonName([...personName, nuevoAsignado]);
      // Para no perder o duplicar los datos, si se actualiza la pagina. Creamos una copia de los Datos.
      // console.log(datos)
      setCopiaDatos([...datos]);

      setEstadoDialogLote(true);
    } else {
      mostrarError("No hay partes a modificar");
      console.log("No hay partes a modificar");
    }
  }
  const ocultarEdicionLote = () => {
    //Vaciar toda la información del Dialog
    setDatosEditarParte(estadoInicial);
    setPersonName([]);
    setCopiaDatos([]);

    setMostrarTerceros(false);
    setEstadoDialogLote(false);
  }
  const mostrarConfirmLote = () => {
    // Comprobamos que haya una operacion, se podria comprobar tambien la observación.
    if (datosEditarParte.operacion == "" || datosEditarParte.operacion == " ") {
      setControlError(true);
      mostrarError("Seleccione un Estado para los partes");
      console.log("Seleccione un Estado para los partes");
    } else {
      // console.log("Confirmación");
      // console.log(datosEditarParte);
      // console.log(personName);

      // Extraer los nombres y los ID. Los pasamos a string separado por ,
      var nombres = personName.map(person => person.label).join(', ');
      var id_usuarios = personName.map(person => person.value).join(',');

      // Comprobamos el usuario principal.
      var usuario = localStorage.getItem('id');
      // Comrpobamos si nuestro usuario esta en el array de personName. Si lo encontramos sera el usuario asignado/principal del parte
      var person = personName.find(person => person.value === usuario);
      // Sino esta nuestro usuario, pondremos el primer usuario del personName. Si esta vacio, ponemos el usuario que esta logueado.
      var id_usuario = person ? person.value : (personName.length > 0 ? personName[0].value : usuario);

      //Actualizamos la información de usuarios asignados
      setDatosEditarParte({
        ...datosEditarParte,
        ['id_usuario']: id_usuario,
        ['id_usuarios']: id_usuarios,
        ['nombresUsuarios']: nombres
      })

      setConfirmarEditarLote(true);
    }
  }
  const ocultarConfirmLote = () => {
    setConfirmarEditarLote(false);
    //setEstadoDialogLote(false);
  }
  const confirmEdicionLote = () => {

    // Si no se ha elegido prioridad mantendremos la prioridad de cada parte
    if (datosEditarParte.prioridad == "" || datosEditarParte.prioridad == " ") {
      // Crear el objeto con id_parte como key y prioridad como value
      var idPartePrioridad = datos.reduce((acc, curr) => {
        acc[curr.id_parte] = curr.prioridad;
        return acc;
      }, {});
      // console.log("Lista Partes Prioridad");
      // console.log(idPartePrioridad);
    }


    // Separar los IDs de listaId en un array
    const listaIdArray = datosEditarParte.listaId.split(',');

    // Crear un array de objetos con la información de cada parte
    const partesActualizados = listaIdArray.map(id_parte => {
      // Si la prioridad no está establecida, usar la prioridad original
      const prioridad = datosEditarParte.prioridad.trim() ? datosEditarParte.prioridad : idPartePrioridad[id_parte];

      return {
        id_parte,
        prioridad,
        operacion: datosEditarParte.operacion,
        fecha_aplazado: datosEditarParte.fecha_aplazado,
        tipo: datosEditarParte.tipo,
        observaciones: datosEditarParte.observaciones,
        id_usuario: datosEditarParte.id_usuario,
        id_usuarios: datosEditarParte.id_usuarios,
        id_tipo_terceros: datosEditarParte.id_tipo_terceros,
        cif_proveedor: datosEditarParte.cif_proveedor,
      };
    });

    // console.log("Partes Actualizados");
    // console.log(partesActualizados);

    fd.append("f", "partes.updatePartes");
    fd.append("token", localStorage.getItem('token'));
    fd.append("listaPartes", JSON.stringify(partesActualizados));
    fetch(API_URL, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        let error = result.error;
        console.log(result);
        if (error.id === 0) {
          mostrarError(error.msg, "success");
          // Ocultamos los Dialog
          ocultarConfirmLote();
          ocultarEdicionLote();
          // Actualizamos
          actualizarGridParte();
        } else {
          mostrarError(error.msg);
          console.log(error.msg);
        }
      })
      .catch(() => {
        mostrarError("Error Api. Obtener los partes de una habitacion.");
        console.log("Error Api. Obtener los partes de una habitacion.");
      })

  }

  const estadoInicial = {
    listaId: '',
    fecha_aplazado: '',
    operacion: '',
    prioridad: '',
    tipo: '',
    observaciones: '',
    id_usuario: '',
    id_usuarios: '',
    nombresUsuarios: '',
    id_tipo_terceros: '',
    cif_proveedor: '',
    nombre_comercial: '',
  };

  const [datosEditarParte, setDatosEditarParte] = useState(estadoInicial);

  const [controlError, setControlError] = useState(false);

  //Guardar en personName el string ,  de Usuario Asignado.
  const [personName, setPersonName] = useState([]);


  //Funcion para el select chip multiple
  const chipMultipleAsignados = (idChip, lectura) => {

    const modificarAsignados = (id, nombre) => {
      // Comprobar si ya existe el usuario.
      const index = personName.findIndex(persona => persona.value === id);
      if (index !== -1) {
        // El id ya está. Procedemos a borrarlo.
        //Primero creamos una copia de el array actual.
        const copiaPersonName = JSON.parse(JSON.stringify(personName));
        copiaPersonName.splice(index, 1);//Quitamos el indice de la array para borrar el usuario.
        setPersonName(copiaPersonName);
      } else {
        //El id no esta en nuestra array de Usuarios.
        const nuevoAsignado = { value: id, label: nombre };
        setPersonName([...personName, nuevoAsignado]);
      }
    }

    return (
      <FormControl fullWidth id={"select-chip-asignados-control" + idChip}>
        <InputLabel id={"select-chip-asignados-label" + idChip} >Asignados</InputLabel>

        <Select
          labelId={"select-chip-asignados-label" + idChip}
          //id={"select-chip-asignados" + idChip}
          multiple
          //label="Asignados"
          //value={personName}
          value={(personName != [] && personName.length >= 1) ? personName : ['']}
          size="small"
          inputProps={lectura == true ? { readOnly: true, } : { readOnly: false, }}
          input={<OutlinedInput id={"select-multiple-chip" + idChip} label="Asignados" />}
          renderValue={() => (
            <Box
              className="chipNombres"
              size="small"
            >
              {personName != [] && personName.length >= 1 ?
                personName.map((persona) => (
                  <Chip label={persona.label} className={'grupotel-blue'} />
                ))
                : <Chip label={' '} />}
            </Box>
          )}
        >
          {usuariosLista != "" ?
            usuariosLista.map((usuario) => (
              <MenuItem value={usuario.value} onClick={() => modificarAsignados(usuario.value, usuario.label)} className={personName.find(persona => persona.value === usuario.value) ? 'grupotel-blue' : ''} >
                {usuario.label}
              </MenuItem>
            ))
            : null
          }
        </Select>
      </FormControl>
    );
  };

  const editarParte = (event) => {
    // console.log(event.target.name)
    // console.log(event.target.value)
    setDatosEditarParte({
      ...datosEditarParte,
      [event.target.name]: event.target.value
    })
    if (event.target.name == "operacion" && (event.target.value == "terceros" || event.target.value == "cerrado")) {//Si es operacion. Comprobar si el Estado del parte es Terceros. Si es asi mostrar los select de Proveedores.
      setMostrarTerceros(true);
    } else if (event.target.name == "operacion") {//Si se ha realizado otro tipo de cambio en operacion ocultamos. Los proveedores
      if (event.target.value != "") {
        setControlError(false);
      } else {
        setControlError(true);
      }
      setMostrarTerceros(false);
    }

    //Petición de Cristian, si se aplaza modificar el estado del parte a Nuevo.
    if (event.target.name == "fecha_aplazado" && datosEditarParte.operacion != "nuevo") {
      setDatosEditarParte({
        ...datosEditarParte,
        [event.target.name]: event.target.value,
        ['operacion']: 'nuevo'
      })
    }
  }

  //Funcionalidad del boton para ver los partes abiertos.
  const estadoAbierto = (event) => {  //Mostrara todos los partes en: Nuevo, Asignado, En ejecucion
    //Si no estamos mostrando los partes abiertos
    if (btnAbierto === false) {
      //event.target.classList.add('pintado') // Intento de modificar añadir una clase al boton utilizando el evento.
      //Ponemos el color para indicar que solo mostramos los Partes abiertos.
      //document.querySelector('.abierto').classList.add('pintado');
      setBtnAbierto(true);
    } else {
      //Quitamos el color de que esta activo el filtro de Partes abiertos
      //document.querySelector('.abierto').classList.remove('pintado');
      setBtnAbierto(false);
    }
    actualizarGridParte();
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const parteInicial = [ //parteInicial
    { valor: '', nombre: '', },
    { valor: 'normal', nombre: 'Normal', },
    { valor: 'No conformidad', nombre: 'No conformidad', },
    { valor: 'objeto perdido', nombre: 'Objeto Perdido', },
    { valor: 'amenity', nombre: 'Amenity', } //,
    // { valor: 'bloqueo', nombre: 'Bloqueo', },
  ];
  const [tipoPartes, setTipoPartes] = useState(parteInicial);


  const estadosInicial = [
    { valor: '', nombre: '', },
    { valor: 'nuevo', nombre: 'Nuevo', },
    { valor: 'asignado', nombre: 'Asignado', },
    { valor: 'en ejecucion', nombre: 'En ejecución', },
    { valor: 'terceros', nombre: 'Terceros', },
    { valor: 'cerrado', nombre: 'Cerrado', },
    { valor: 'anulado', nombre: 'Anulado', },
  ];
  const [tipoEstados, setTipoEstados] = useState(estadosInicial);

  const prioridadInicial = [
    { valor: 'baja', nombre: 'Baja', },
    { valor: 'media', nombre: 'Media', },
    { valor: 'alta', nombre: 'Alta', },
  ];
  const [tipoPrioridad, setTipoPrioridad] = useState(prioridadInicial);


  const fd = new FormData();
  let partes = []
  const requestOptions = {
    method: 'POST',
    body: fd
  };

  // Función para obtener los tipos de partes.
  const obtenerTiposParte = () => {
    fd.append("f", "partes.getTipoParte");
    fd.append("token", localStorage.getItem('token'));

    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error.id === 0) {
          // console.log(result);
          // console.log(result.tipo);
          // Crear un array temporal y añadir el valor por defecto al principio
          const tipoConDefecto = [{ valor: '', nombre: '' }, ...result.tipo];
          setTipoPartes(tipoConDefecto);

        } else {
          setTipoPartes(parteInicial);

          mostrarError(result.error.msg);
          console.log(result.error.msg)
        }
      })
      .catch(() => {
        mostrarError("No se ha podido actualizar los Tipos de Parte");
        console.log("No se ha podido actualizar los Tipos de Parte");
      })
  }

  const obtenerDatosSelect = () => {
    fd.append("f", "partes.getDatosSelect");
    fd.append("token", localStorage.getItem('token'));

    fetch('https://api.workers.grupotel.com/', requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error.id === 0) {
          // console.log(result);
          const tipoConDefecto = [{ valor: '', nombre: '' }, ...result.tipo];
          setTipoPartes(tipoConDefecto);

          const estadoConDefecto = [{ valor: '', nombre: '' }, ...result.operacion];
          setTipoEstados(estadoConDefecto);

          setTipoPrioridad(result.prioridad);

        } else {
          setTipoPartes(parteInicial);
          setTipoEstados(estadosInicial);
          setTipoPrioridad(prioridadInicial);

          mostrarError(result.error.msg);
          console.log(result.error.msg)
        }
      })
      .catch(() => {
        mostrarError("No se ha podido actualizar los Tipos de Parte");
        console.log("No se ha podido actualizar los Tipos de Parte");
      })
  }

  //Comprobar si se obtiene un valor de tipoEstado. En ese caso controlar con un boolean para solo realizar una vez.

  useEffect(() => {
    //En caso de detectar un tipoEstado que llegue desde el Home. 
    //Se abrira los filtros con el estado/operacion seleccionado o si esta aplazado.
    if (tipoEstado && tipoEstado != undefined) {
      if (tipoEstado === 'ejecucion') {
        datosFiltroParte.operacion = 'en ejecucion';
      } else if (tipoEstado === 'aplazados') {
        // datosFiltroParte.aplazados = true;
        // setAplazado = true;
        setAplazado(true);
        setDatosFiltroParte({
          ...datosFiltroParte,
          ['aplazados']: 1
        })
      } else {
        datosFiltroParte.operacion = tipoEstado;
      }
      tipoEstado = undefined;
      mostrarFiltros();
    }
  }, [])


  useEffect(() => {
    // Para tener siempre siempre actualizado el tipo de Partes.

    if (actuGridParte === true) {
      obtenerListadoPartes();//
    }
    //Caso inicial siempre sera true. Utilizaremos actualizo para indicar que queremos actualizar la informacion.
    if (actualizo === true) {
      obtenerListadoPartes();//
      SetActualizo(false);//Una vez actualizada la información, cambiamos el estado de actualizo.
    }
    let tiempoActualizar = 60000 * 5;//Tiempo para actualizar desde la ultima carga 5 min
    const timer = setTimeout(() => { //Iniciamos el timer
      if (actualizo === true) {
        obtenerListadoPartes();
        SetActualizo(false); //Desactivamos el estado actualizo.
        SetActualizo2(!actualizo2); //Indicamos que queremos recargar los componenetes de GridParte
      } else {
        obtenerListadoPartes();
        SetActualizo(true);//Ponemos true para volver a iniciar el bucle.
        SetActualizo2(!actualizo2);
      }
    }, tiempoActualizar);
    return () => clearTimeout(timer);//Detenemos el timer para que si realizamos algun cambio no quede un timer que recargue los componentes.

  }, [actualizo2, actuGridParte, departamentos])


  //Ordenamos por el index utilizando el sort. Ya que la respuesta del fetch al pasar a json nos ordena el resultado por el id de Parte.
  const listaPartes = Object.values(listaPartesRoom).sort((a, b) => (a.index > b.index) ? 1 : -1).map((parte, i) =>
    <>
      <ListItemButton onClick={() => abrirHistorial(parte.id)} key={parte.id} id={parte.id} className={parte.operacion === 'en ejecucion' ? 'en-ejecucion-gradient bordeParte infoParte' : parte.operacion + "-gradient bordeParte infoParte"}>
        <HtmlTooltip

          placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
          title={
            <React.Fragment>
              <Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{parte.id}</Typography>
            </React.Fragment>
          }
        >
          <ListItemText className='infoParteId' >
            |
          </ListItemText>
        </HtmlTooltip>
        <ListItemText className='infoParteRoom infoTitulo' primary={parte.titulo} />
        <ListItemText className='infoParteRoom infoHistorico' primary={parte.fecha} />

      </ListItemButton>
      <Collapse in={isOpenCollapse === parte.id} timeout="auto" unmountOnExit className='separarPalabras'>
        {parte.historico ?
          < div >
            {parte.historico.map((historico, j) => {
              return < div className={historico.estado === 'en ejecucion' ? 'en-ejecucion  infoParteIdHijo' : historico.estado + "-gradient infoParteIdHijo"}>
                <ListItem >
                  <HtmlTooltip
                    placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{historico.estado}</Typography>
                      </React.Fragment>
                    }
                  >
                    <ListItemText className='infoParteId'>
                      |
                    </ListItemText>
                  </HtmlTooltip>

                  <ListItemText className='mostrar justificarTexto infoParteRoom infoTitulo' >
                    {historico.fecha_aplazado !== '' ?
                      historico.estado === 'terceros' ?
                        '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                        : '\"Aplazado: ' + historico.fecha_aplazado + '\" ' + historico.observaciones

                      : historico.estado === 'terceros' ?
                        '\"Terceros: ' + historico.proveedor + '\" ' + historico.observaciones
                        : historico.observaciones
                    }
                  </ListItemText>
                  <ListItemText className='mostrar justificarTexto infoParteRoom infoHistorico' >
                    {historico.fecha_evento}
                  </ListItemText>

                </ListItem>
              </div>
            })
            }</div>
          : ""
        }
      </Collapse>
    </>
  );

  // const columnasFinales = datos.some(d => d.mostrar_hotel === 0) ? columnasDep : columnasHotel;
  // Como datos puede ser "" o []
  const columnasFinales = Array.isArray(datos) && datos.length > 0 && datos.some(d => d.mostrar_hotel === 0) 
  ? columnasDep 
  : columnasHotel;

  return (
    <div>
      <div className='header'>
        <div className='containerAcciones'>
          {tipoSalidas != 1 ?
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              {
                <HtmlTooltip
                  placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                  title={
                    <React.Fragment>
                      <Typography color="inherit">{!(activoFiltro && btnAbierto) ? "Edición en Lote\nRequisitos: activar Partes Abiertos y Filtrar" : "Edición en Lote"}</Typography>
                    </React.Fragment>
                  }
                >
                  <span>
                    <Button
                      //className="nuevoParte edicionLote"
                      className={(activoFiltro && btnAbierto) ? 'editarLote ' : 'boton-disabled'}
                      onClick={activarEdicionLote}
                      disabled={!(activoFiltro && btnAbierto)}
                    >
                      <PostAddOutlinedIcon className='icon-button-big' />
                    </Button>
                  </span>
                </HtmlTooltip>

              }
            </ButtonGroup>
            : <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
            </ButtonGroup>

          }

          <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
            <HtmlTooltip
              placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <Typography color="inherit">{"Crear Parte"}</Typography>
                </React.Fragment>
              }
            >
              <Button
                className="boton-grande nuevoParte"
                onClick={mostrarNuevoParte}
              >
                <AddCircleOutlineIcon className='icon-button-big' />
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <Typography color="inherit">{"Partes Abiertos"}</Typography>
                </React.Fragment>
              }
            >
              <Button
                //className="boton abierto"
                className={(btnAbierto) ? 'boton abierto pintado' : 'boton abierto'}
                onClick={estadoAbierto}
              >
                <AssignmentLateOutlinedIcon className='icon-button' />
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <Typography color="inherit">{"Filtrar"}</Typography>
                </React.Fragment>
              }
            >
              <Button
                className={activoFiltro === true ? "boton filtro pintado" : "boton filtro"}
                onClick={mostrarFiltros}
              >
                <FilterAltIcon className='icon-button' />
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <Typography color="inherit">{"Actualizar"}</Typography>
                </React.Fragment>
              }
            >
              <Button
                className="boton"
                onClick={actualizarGridParte}
              >
                <RotateLeftIcon className='icon-button' />
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <Typography color="inherit">{(activoFiltro || btnAbierto) ? "PDF Partes Filtrados" : "PDF Partes Filtrados\nRequisitos: Filtrar partes"}</Typography>
                </React.Fragment>
              }
            >
              <Button
                //className={'boton '}
                className={(activoFiltro || btnAbierto) ? 'boton' : 'boton-disabled'}
                //className={(activoFiltro || btnAbierto) ? 'mostrar boton botonesAcciones' : 'ocultar'}
                onClick={(activoFiltro || btnAbierto) ? listaInformes : (e) => { e.stopPropagation() }}
              >
                <PrintIcon className={(activoFiltro || btnAbierto) ? 'icon-button' : 'icon-button-disabled'} />
              </Button>
            </HtmlTooltip>

            <HtmlTooltip
              placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <div className='ayuda-container'>
                    <div className='color-container'>
                      <div className='color nuevo-fill'></div>
                      <h4>Nuevo</h4>
                    </div>
                    <div className='color-container'>
                      <div className='color asignado-fill'></div>
                      <h4>Asignado</h4>
                    </div>
                    <div className='color-container'>
                      <div className='color en-ejecucion-fill'></div>
                      <h4>En ejecución</h4>
                    </div>
                    <div className='color-container'>
                      <div className='color terceros-fill'></div>
                      <h4>Terceros</h4>
                    </div>
                    <div className='color-container'>
                      <div className='color anulado-fill'></div>
                      <h4>Anulado</h4>
                    </div>
                    <div className='color-container'>
                      <div className='color cerrado-fill'></div>
                      <h4>Cerrado</h4>
                    </div>
                  </div>
                </React.Fragment>
              }
            >
              <Button
                className="boton"
                onClick={toggleAyuda}
              >
                <InfoOutlinedIcon className='icon-button' />
              </Button>
            </HtmlTooltip>
          </ButtonGroup>
        </div>
      </div>
      <div className='grid-container'>

        <DataGrid
          className='grid'
          rowHeight={56}//63
          getCellClassName={(params) => {
            if (params.field === 'id_parte') {
              return params.row.operacion
            }
            if (params.field === 'operacion') {
              return params.row.operacion + ' justify-content-center'
            }

          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? params.row.operacion + ' blanco' : params.row.operacion + ' fondogris blanco'
          }
          onRowClick={clickInfoParte}
          onCellDoubleClick={dobleClick}
          {...datos}
          getRowId={(datos) => datos.id_parte}
          rows={datos}
          columns={columnasFinales}
          maxColumns={8}
          pageSize={12}
          rowsPerPageOptions={[12]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'fecha_evento_en', sort: 'desc' }],
            },
            // sorting: {
            //   sortModel: [{ field: 'index', sort: 'desc' }], // Aquí ordenas por index ascendente
            // },
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            noRowsLabel: "No se han encontrado datos.",
            noResultsOverlayLabel: "No se han encontrado ningún resultado",
          }}
          slots={{
            // Hide `columnMenuColumnsItem`
            columnMenuColumnsItem: null,
          }}
        // disableColumnMenu
        />
        <Dialog fullScreen={fullScreen}
          open={estadoFiltros}
          //disableEscapeKeyDown
          onClose={comprobarESC}
          aria-labelledby="responsive-dialog-title" id="dialogoFiltros">
          <DialogTitle>Filtros</DialogTitle>
          <DialogContent >
            <form>
              <div className='bloque-inputs-filtros '>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="text"
                  label="ID"
                  onKeyPress={(ev) => { comprobarEnter(ev) }}
                  name="id"
                  size="small"
                  value={datosFiltroParte.id ? datosFiltroParte.id : ''}
                  onChange={actualizarFiltros}
                  autoComplete="off"
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Hotel"
                  name="id_hotel"
                  size="small"
                  value={datosFiltroParte.id_hotel ? datosFiltroParte.id_hotel : ''}
                  onChange={actualizarFiltros}
                  onClick={obtenerValueFiltros}
                  select
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: { maxHeight: { sm: 36 * 8 + 8 } }
                      }
                    }
                  }}
                >
                  {hotelesLista.sort((a, b) => a.label.localeCompare(b.label)) // Ordenar por hotel.label
                    .map((hotel) => (
                      <MenuItem key={`filtrosHotel-${hotel.value}`} value={hotel.value}>
                        {hotel.label}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Estado"
                  name="operacion"
                  size="small"
                  value={datosFiltroParte.operacion ? datosFiltroParte.operacion : ''}
                  onChange={actualizarFiltros}
                  select
                >
                  {tipoEstados.map((tipoEstado) => (
                    <MenuItem key={`filtrosEstado-${tipoEstado.valor}`} value={tipoEstado.valor}>
                      {tipoEstado.nombre}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Departamento de destino"
                  name="id_departamento"
                  size="small"
                  value={datosFiltroParte.id_departamento ? datosFiltroParte.id_departamento : ""}
                  onChange={actualizarFiltros}
                  select
                >
                  {departamentos.map((departamento) => (
                    <MenuItem key={`filtrosDep-${departamento.value}`} value={departamento.value}>
                      {departamento.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Tipo parte"
                  name="tipo"
                  size="small"
                  value={datosFiltroParte.tipo ? datosFiltroParte.tipo : ''}
                  onChange={actualizarFiltros}
                  select
                >
                  {tipoPartes
                    .sort((a, b) => a.orden - b.orden) // Ordenar por el campo 'orden' de forma ascendente
                    .map((tipoParte) => (
                      <MenuItem key={`filtrosTipo-${tipoParte.valor}`} value={tipoParte.valor}>
                        <Tooltip
                          title={tipoParte.descripcion && tipoParte.descripcion.trim() ? tipoParte.descripcion : ''} // Mostrar solo si hay descripción
                          placement="right"
                        >
                          <span>{tipoParte.nombre}</span> {/* Envolver el texto del menú, no el componente entero */}
                        </Tooltip>
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Prioridad parte"
                  name="prioridad"
                  size="small"
                  value={datosFiltroParte.prioridad ? datosFiltroParte.prioridad : ''}
                  onChange={actualizarFiltros}
                  select
                >
                  {tipoPrioridad.map((prioridad) => (
                    <MenuItem key={`filtrosTipo-${prioridad.valor}`} value={prioridad.valor}>
                      {prioridad.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className='divFiltros'>
                <div className='divFiltrosContainer'>
                  <h4>Filtros de Habitación</h4>
                  <div className='divFiltrosTextField'>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Bloque"
                      name="bloque"
                      size="small"
                      value={datosFiltroParte.bloque}
                      onChange={actualizarFiltros}
                      select
                      SelectProps={{
                        multiple: true,
                      }}
                    >
                      {bloquesLista.map((bloque) => (
                        <MenuItem key={`filtrosBloque-${bloque.value}`} value={bloque.value}>
                          {bloque.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <TextField
                      variant="outlined"
                      margin="normal"
                      label="Bloque"
                      onKeyPress={(ev) => { comprobarEnter(ev) }}
                      name="bloque"
                      size="small"
                      value={datosFiltroParte.bloque ? datosFiltroParte.bloque : ''}
                      onChange={actualizarFiltros}
                    /> */}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Planta"
                      // type="number"
                      // onKeyPress={(ev) => { comprobarEnter(ev) }}
                      name="planta"
                      size="small"
                      value={datosFiltroParte.planta}
                      onChange={actualizarFiltros}
                      select
                      SelectProps={{
                        multiple: true,
                      }}
                    >
                      {plantasLista.map((planta) => (
                        <MenuItem key={`${planta.bloque} - P${planta.planta}`} value={`${planta.id_bloque}-${planta.planta}`} id={`${planta.bloque} - P${planta.planta}`}>
                          {`${planta.bloque} - P${planta.planta}`}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      id="filtroHabitacion"
                      label="Habitación"
                      onKeyPress={(ev) => { comprobarEnter(ev) }}
                      name="hab"
                      size="small"
                      value={datosFiltroParte.hab ? datosFiltroParte.hab : ''}
                      onChange={actualizarFiltros}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className='divFiltrosContainer'>
                  <h4>Fecha de Creación</h4>
                  <div className='divFiltrosTextField'>
                    {/* <TextField
                      label="Desde"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="fecha_desde"
                      size="small"
                      value={datosFiltroParte.fecha_desde ? datosFiltroParte.fecha_desde : ''}
                      onChange={actualizarFiltros}
                    /> */}
                    <TextFieldFecha
                      idFormulario={4}
                      nombreTextField="fecha_desde"
                      textoLabel="Desde"
                      claseFormControl='grupotel-cuarto'
                      claseTextField='input'
                      actualizarFecha={actualizarFechaDesde}
                      valorInicial={datosFiltroParte.fecha_desde ? datosFiltroParte.fecha_desde : ''}
                    />
                    {/*
                    <TextField
                      type="date"
                      variant="outlined"
                      margin="normal"
                      label="Hasta"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="fecha_hasta"
                      size="small"
                      value={datosFiltroParte.fecha_hasta ? datosFiltroParte.fecha_hasta : ''}
                      onChange={actualizarFiltros}
                    />
                    */}
                    <TextFieldFecha
                      idFormulario={5}
                      nombreTextField="fecha_hasta"
                      textoLabel="Hasta"
                      claseFormControl='grupotel-cuarto'
                      claseTextField='input'
                      actualizarFecha={actualizarFechaHasta}
                      valorInicial={datosFiltroParte.fecha_hasta ? datosFiltroParte.fecha_hasta : ''}
                    />
                    <div className='divAplazado'>
                      <FormControlLabel
                        control={<Checkbox checked={aplazado} onChange={estadoAplazado} color="secondary" name="aplazados" />}
                        label="Aplazado"
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className='divFiltros'>

                <div className='divFiltrosContainer'>
                  <h4>Filtros de terceros</h4>
                  <div className='divFiltrosTextField'>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Tipo terceros"
                      name="id_tipo_terceros"
                      size="small"
                      value={datosFiltroParte.id_tipo_terceros ? datosFiltroParte.id_tipo_terceros : ''}
                      onChange={actualizarFiltros}
                      select
                    >
                      {tipoTerceros.map((tipoTercero) => (
                        <MenuItem key={`filtrosTercero-${tipoTercero.value}`} value={tipoTercero.value}>
                          {tipoTercero.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Autocomplete
                      //disablePortal
                      id="completeProveedor"
                      className="poperAutoCompletar"
                      size="small"
                      //value={datosFiltroParte.nombre_comercial ? datosFiltroParte.nombre_comercial : ''}
                      options={terceros}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      value={datosFiltroParte.nombre_comercial ? datosFiltroParte.nombre_comercial : ''}
                      //getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Proveedor" />}
                      onChange={cambioProveedor}

                    />
                  </div>
                </div>
                <div className='divFiltrosContainer'>
                  <h4>Filtros de Usuarios</h4>
                  <div className='divFiltrosTextField'>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Usuario Asignado"
                      name="id_asignado"
                      size="small"
                      value={datosFiltroParte.id_asignado ? datosFiltroParte.id_asignado : ''}
                      onChange={actualizarFiltros}
                      select
                    >
                      {usuariosLista.map((usuario) => (
                        <MenuItem key={`filtrosAsignado-${usuario.value}`} value={usuario.value}>
                          {usuario.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Creador del parte"
                      name="id_creador"
                      size="small"
                      value={datosFiltroParte.id_creador ? datosFiltroParte.id_creador : ''}
                      onChange={actualizarFiltros}
                      select
                    >
                      {usuariosHotel.map((usuario) => (
                        <MenuItem key={`filtrosCreador-${usuario.value}`} value={usuario.value}>
                          {usuario.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
              <div className='divFiltros'>
                <div className='divFiltrosContainer'>
                  <h4>Observaciones</h4>
                  <div className='divFiltrosTextField'>

                    <TextField
                      className='observaciones '
                      label="Observaciones"
                      name="historico_observacion"
                      size="small"
                      multiline
                      rows={2}
                      value={datosFiltroParte.historico_observacion ? datosFiltroParte.historico_observacion : ''}
                      onChange={actualizarFiltros}
                    />
                  </div>
                </div>
                <div className='divFiltrosContainer'>
                  <h4>Filtro Titulo</h4>
                  <div className='divFiltrosTitulo'>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Titulo"
                      onKeyPress={(ev) => { comprobarEnter(ev) }}
                      name="titulo"
                      size="small"
                      InputLabelProps={{ className: 'bold' }}
                      value={datosFiltroParte.titulo ? datosFiltroParte.titulo : ''}
                      onChange={actualizarFiltros}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <Button className="colorGrupo botonBorrarFiltros" onClick={eliminarFiltros}>Limpiar Filtros</Button>
            <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
              <Button className="colorGrupo" onClick={ocultarFiltros2}>Cancelar</Button>
              <Button className="colorGrupo" onClick={guardarDatos}>Filtrar</Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      </div>
      <Dialog fullScreen={fullScreen}
        open={nuevoParte}
        onClose={comprobarESCNuevoParte}
        className='dialogCrearParte'
        aria-labelledby="responsive-dialog-title" id="dialogo">
        <DialogTitle>{rutaActual.nombre ? rutaActual.nombre : 'Nuevo Parte'}</DialogTitle>
        <DialogContent className='container-filtros'>
          <form className='nuevoParteForm'>
            <div className='nuevoParteBloque nuevoParteAltura'>
              <TextField
                variant="outlined"
                margin="normal"
                label="Hotel"
                name="id_hotel"
                className='nuevoParteTextfield'
                size="small"
                value={datosNuevoParte.id_hotel ? datosNuevoParte.id_hotel : ''}
                onChange={cambioNuevoParte}
                error={errorHotelPartes}
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: { sm: 36 * 8 + 8 } }
                    }
                  }
                }}
              >
                {hotelesLista.map((hotel) => (
                  <MenuItem key={`nuevoHotel-${hotel.value}`} value={hotel.value}>
                    {hotel.label}
                  </MenuItem>
                ))}
              </TextField>
              {
                // <FormControl className='grupotel-cuarto input'>
                //   <InputLabel id="demo-simple-select-helper-label">Mes</InputLabel>
                //   <Select
                //     className='nuevoParteTextfield'
                //     size="small"
                //     labelId="demo-simple-select-helper-label"
                //     value={datosNuevoParte.id_hotel ? datosNuevoParte.id_hotel : ''}
                //     label="Mes"
                //     onChange={cambioNuevoParte}
                //     MenuProps={{ PaperProps: { sx: { maxHeight: { sm: 36 * 8 + 8 } } } }}
                //   >
                //     {hotelesLista.map((hotel) => (
                //       <MenuItem value={hotel.value}>
                //         {hotel.label}
                //       </MenuItem>
                //     ))}
                //   </Select>
                // </FormControl>
              }
              <div className='nuevoParteBuscar'>
                <TextField
                  key="nuevoParteGridParte"
                  variant="outlined"
                  margin="normal"
                  label="Habitación"
                  name="hab"
                  className='nuevoParteNumero margenBloque'
                  size="small"
                  value={datosNuevoParte.hab ? datosNuevoParte.hab : ''}
                  onChange={cambioNuevoParte}
                  error={errorHabitacionPartes}
                  onKeyPress={(ev) => { comprobarEnterHab(ev) }}
                  onBlur={(ev) => { comprobarHabitacion(ev) }}
                  InputProps={
                    {
                      endAdornment:
                        <IconButton
                          className="boton buscador"
                          onClick={comprobarHabitacion}
                        >
                          <SearchIcon className='icon-button ' />
                        </IconButton>
                    }}
                  autoComplete="off"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Tipo parte"
                  name="tipo"
                  className='nuevoParteNumero'
                  size="small"
                  value={datosNuevoParte.tipo ? datosNuevoParte.tipo : 'normal'}
                  onChange={cambioNuevoParte}
                  select
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: { maxHeight: { sm: 36 * 8 + 8 } }
                      }
                    }
                  }}
                >
                  {tipoPartes
                    .sort((a, b) => a.orden - b.orden) // Ordenar por el campo 'orden' de forma ascendente
                    .map((tipoParte) => (
                      <MenuItem key={`nuevoTipo-${tipoParte.valor}`} value={tipoParte.valor}>
                        <Tooltip
                          title={tipoParte.descripcion && tipoParte.descripcion.trim() ? tipoParte.descripcion : ''} // Mostrar solo si hay descripción
                          placement="right"
                        >
                          <span>{tipoParte.nombre}</span> {/* Envolver el texto del menú, no el componente entero */}
                        </Tooltip>
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              {/*  Lo comento por si lo llegan a pedir.
              <div className='nuevoParteBuscar'>
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Bloque"
                  name="bloque"
                  className='nuevoParteNumero margenBloque'
                  inputProps={
                    { readOnly: true, }
                  }
                  size="small"
                  value={datosNuevoParte.bloque ? datosNuevoParte.bloque : ''}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Planta"
                  type="number"
                  name="planta"
                  size="small"
                  className='nuevoParteNumero'
                  inputProps={
                    { readOnly: true, }
                  }
                  value={datosNuevoParte.planta ? datosNuevoParte.planta : ''}
                />
              </div>
                */}
              <div className='nuevoParteHistorico'>
                {listaPartes}
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                className='nuevoParteText'
                label="Observaciones"
                primary='nuevoParteTextObservacion'
                id='nuevoParteTextObservacion'
                multiline
                rows={7}
                name="observaciones"
                value={datosNuevoParte.observaciones ? datosNuevoParte.observaciones : ''}
                onChange={cambioNuevoParte}
              />


            </div>
            <div className='nuevoParteBloque nuevoParteAltura'>
              <Box sx={{ width: '100%' }} className='departamento'>

                <List
                  className='botonLista'
                >
                  <ListItem className={errorDepartamentosPartes ? 'divisorError  ' : 'divisor  '}>
                    <div className='itemBoton'>
                      <TextField InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FilterAltIcon color='#00a2dc' />
                          </InputAdornment>
                        ),
                      }} value={textoBusqueda} onChange={(ev) => { handleSearchText(ev) }} id="search" fullWidth autoComplete="off"></TextField>
                    </div>
                  </ListItem>
                  <ListItem divider className={errorDepartamentosPartes ? 'divisorError botonAtras ' : 'divisor botonAtras '}>
                    <ListItemButton className='itemBoton'
                      disabled={activarAtras}
                      onClick={backDepartamento}
                    >
                      <ListItemText className='center'>
                        <ArrowBackIcon className='icon-button ' />
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  {(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).map((departamento, i) =>
                    <>
                      {errorDepartamentosPartes ?
                        <ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisorError ultimoDivError' : 'divisorError'} >
                          <ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
                            <ListItemText primary={departamento.label} id={departamento.value} className='center' />
                          </ListItemButton>
                        </ListItem>
                        :
                        <ListItem divider className={(textoBusqueda === '' ? departamentosNuevoParte : departamentosFiltrados).length - 1 === i ? 'divisor ultimoDiv' : 'divisor'} >
                          <ListItemButton className='itemBoton' value={departamento.label} id={departamento.value} onClick={(ev) => { obtenerSubDepartamento(ev) }}>
                            <ListItemText primary={departamento.label} id={departamento.value} className='center' />
                          </ListItemButton>
                        </ListItem>
                      }
                    </>
                  )
                  }
                </List>
              </Box>
            </div>
            <div className='nuevoParteBloque nuevoParteAltura'>

              <label htmlFor="upload-files" className='nuevoParteBotonAdjuntar'>
                <input
                  className='ocultar'
                  accept=".pdf, .jpg, .jpeg, .png"
                  id="upload-files"
                  type="file"
                  onChange={selecImage}
                  multiple
                />
                <Button component='span' className='colorGrupo' variant="contained">Adjuntar archivo</Button>
              </label>

              <div className='divPdf'>
                {listaPDFNuevoParte.length > 0 ?
                  <div>
                    {listaPDFNuevoParte.map((pdf, j) => {
                      return (
                        <div className='' key={pdf.id}>
                          <Button className='nuevoParteNombrePdf' variant="text" onClick={() => ventanaImagen(pdf.ruta)} >{pdf.nombre ? pdf.nombre : ''}</Button>
                          <IconButton className='iconX' onClick={() => borrarPDFNuevoParte(pdf.id)}>x</IconButton>
                        </div>
                      );
                    })
                    }
                  </div>
                  : ''
                }
              </div>

              <div className='containerDiv'>
                {listaImagenesNuevoParte.length > 0 ?
                  <div className='divImgMaquinasNuevas'>
                    {listaImagenesNuevoParte.map((foto, j) => {
                      return (
                        <div className='divCentrarImagen' key={foto.id}>
                          <IconButton className='iconoBorrarImg' onClick={() => borrarImagenNuevoParte(foto.id)}>
                            <CancelIcon className=' ' />
                          </IconButton>
                          <img className="nuevoPartePreviewImagen" src={foto.ruta} onClick={() => ventanaImagen(foto.ruta)} />
                        </div>
                      );
                    })
                    }
                  </div>
                  : ''
                }
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
            <Button className="colorGrupo" onClick={limpiarNuevoParte}>Cancelar</Button>
            {creandoParte ?
              <Button className="colorGrupo" >
                <CircularProgress className="botonCarga" color="inherit" />
              </Button>
              : <Button className="colorGrupo" onClick={crearParte}>Crear</Button>
            }
          </ButtonGroup>
        </DialogActions>
      </Dialog>

      <Dialog fullScreen={fullScreen}
        open={estadoDialogLote}
        onClose={ocultarEdicionLote}
        className='dialogCrearParte'
        aria-labelledby="responsive-dialog-title"
        id="dialogoEditarLotes"
      >
        <DialogTitle>
          Editar todos los partes Filtrados y en estado Abiertos
        </DialogTitle>
        <DialogContent className='container-filtros alturaDialog'>
          <form>
            <div>
              <div className='bloque-inputs'>
                <TextField
                  className='proveedoresTextfield'
                  variant="outlined"
                  margin="normal"
                  label="Estado"
                  name="operacion"
                  id="tipoOperacion"
                  error={controlError}
                  onChange={editarParte}
                  size="small"
                  value={datosEditarParte.operacion ? datosEditarParte.operacion : ' '}
                  select
                >
                  {tipoEstados.map((tipoEstado) => (
                    <MenuItem key={`editLoteEstado-${tipoEstado.valor}`} value={tipoEstado.valor}>
                      {tipoEstado.nombre}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className='proveedoresTextfield'
                  variant="outlined"
                  margin="normal"
                  label="Prioridad"
                  name="prioridad"
                  onChange={editarParte}
                  size="small"
                  value={datosEditarParte.prioridad ? datosEditarParte.prioridad : ' '}
                  select
                >
                  <MenuItem value={' '}> </MenuItem>
                  {tipoPrioridad.map((prioridad) => (
                    <MenuItem key={`editLotePrioridad-${prioridad.valor}`} value={prioridad.valor}>
                      {prioridad.nombre}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  //className='medio'
                  variant="outlined"
                  margin="normal"
                  label="Tipo parte"
                  name="tipo"
                  size="small"
                  onChange={editarParte}
                  value={datosEditarParte.tipo ? datosEditarParte.tipo : ' '}
                  select
                >
                  {tipoPartes
                    .sort((a, b) => a.orden - b.orden) // Ordenar por el campo 'orden' de forma ascendente
                    .map((tipoParte) => (
                      <MenuItem key={`editLoteTipo-${tipoParte.valor}`} value={tipoParte.valor}>
                        <Tooltip
                          title={tipoParte.descripcion && tipoParte.descripcion.trim() ? tipoParte.descripcion : ''} // Mostrar solo si hay descripción
                          placement="right"
                        >
                          <span>{tipoParte.nombre}</span> {/* Envolver el texto del menú, no el componente entero */}
                        </Tooltip>
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  label="Aplazar"
                  type="date"
                  format={'DD/MM/YYYY'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name='fecha_aplazado'
                  size="small"
                  value={datosEditarParte.fecha_aplazado ? datosEditarParte.fecha_aplazado : ''}
                  onChange={editarParte}
                  autoComplete="off"
                />

              </div>
              <div className='dispFlex'>
                {mostrarTerceros ?
                  <div class="bloque-tercero ">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      label="Tipo terceros"
                      name="id_tipo_terceros"
                      // className='proveedoresTextfield'
                      size="small"
                      value={datosEditarParte.id_tipo_terceros ? datosEditarParte.id_tipo_terceros : ' '}
                      onChange={editarParte}
                      select
                    >
                      {tipoTerceros.map((tipoTercero) => (
                        <MenuItem key={`editLoteTercero-${tipoTercero.value}`} value={tipoTercero.value}>
                          {tipoTercero.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <Autocomplete
                      id="proveedorAuto"
                      className='proveedoresTextfield'
                      options={terceros}
                      onChange={cambioProveedorEditar}
                      size="small"
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      //value={datosFiltroParte.nombre_comercial ? datosFiltroParte.nombre_comercial : ''}
                      renderInput={(params) => <TextField {...params} label="Proveedor" />}
                    />
                  </div>
                  : <div class=""></div>
                }
                <div className='bloque-tercero' >
                  {chipMultipleAsignados(2, false)}
                </div>
              </div>
            </div>
            <div className='bloque-inputs alinearIzquierda'>
              <div className='divEdicion'>
                <TextField
                  className='observaciones observacionesEditarParte'
                  label="Observaciones"
                  name="observaciones"
                  size="small"
                  onChange={editarParte}
                  multiline
                  rows={12}
                  value={datosEditarParte.observaciones ? datosEditarParte.observaciones : ''}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
            <Button className="colorGrupo" onClick={ocultarEdicionLote}>Cerrar</Button>
            <Button className="colorGrupo" onClick={mostrarConfirmLote}>Confirmar</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>


      <Dialog fullScreen={fullScreen}
        open={confirmarEditarLote}
        onClose={ocultarConfirmLote}
        className='dialogCrearParte'
        id="confirmEditarLotes"

      >
        <DialogTitle>
          ¿Editar todos los partes?
        </DialogTitle>
        <DialogContent className='container-filtros alturaDialog'>
          <div><b>Lista de partes a Modificar:</b></div>
          <div className='historicoEditarLotes'>{
            Object.values(copiaDatos).sort((a, b) => (a.fecha_evento_en > b.fecha_evento_en) ? -1 : 1).map((parte, i) =>
              <>
                <ListItem key={parte.id_parte} id={parte.id_parte} className={parte.operacion === 'en ejecucion' ? 'en-ejecucion-gradient bordeParte infoParte' : parte.operacion + "-gradient bordeParte infoParte"}>
                  <HtmlTooltip
                    placement="left-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit" onClick={(e) => { e.stopPropagation() }}>{parte.operacion}</Typography>
                      </React.Fragment>
                    }
                  >
                    <ListItemText className='infoParteId' >
                      |
                    </ListItemText>
                  </HtmlTooltip>
                  <ListItemText className='infoParteRoom infoHistorico' primary={parte.id_parte} />
                  <ListItemText className='infoParteRoom infoTitulo' primary={parte.titulo} />
                  <ListItemText className='infoParteRoom infoHistorico' primary={parte.fecha} />
                </ListItem>
              </>
            )
          }</div>
          <div className='infoEditarLotes'>
            <div className='center'>
              <b>Cambios a realizar</b>
            </div>
            <div className='editarLoteInfo'>
              {(datosEditarParte.fecha_aplazado != "" & datosEditarParte.fecha_aplazado != " ") ? <div><b>Fecha Aplazado: </b>{datosEditarParte.fecha_aplazado}</div>
                : ""}
            </div>
            <div className='editarLoteInfo'>
              {(datosEditarParte.operacion != "" & datosEditarParte.operacion != " ") ? <div className='nuevoParteBloque'><b>Estado del Parte: </b>{datosEditarParte.operacion}</div>
                : ""}
              {(datosEditarParte.prioridad != "" & datosEditarParte.prioridad != " ") ? <div className='nuevoParteBloque'><b>Prioridad del Parte: </b>{datosEditarParte.prioridad}</div>
                : ""}
              {(datosEditarParte.tipo != "" & datosEditarParte.tipo != " ") ? <div className='nuevoParteBloque'><b>Tipo de Parte: </b>{datosEditarParte.tipo}</div>
                : ""}
            </div>
            <div className='editarLoteInfo'>
              {(datosEditarParte.id_tipo_terceros != "" & datosEditarParte.id_tipo_terceros != " ") ? <div className='nuevoParteBloque'><b>Tipo Terceros: </b>{tipoTerceros[datosEditarParte.id_tipo_terceros - 1].label}</div>
                : ""}
              {(datosEditarParte.cif_proveedor != "" & datosEditarParte.cif_proveedor != " "
                & datosEditarParte.nombre_comercial != "" & datosEditarParte.nombre_comercial != " ") ? <><div className='nuevoParteBloque'><b>CIF Proveedor: </b>{datosEditarParte.cif_proveedor} </div><div className='nuevoParteBloque'><b>Nombre Proveedor : </b> {datosEditarParte.nombre_comercial} </div></>
                : ""}
            </div>
            <div className='editarLoteInfo'>
              {(datosEditarParte.nombresUsuarios != "" & datosEditarParte.nombresUsuarios != " ") ? <div className='editarLoteInfo'><b>Personas asignadas: </b>{datosEditarParte.nombresUsuarios}</div>
                : ""}
            </div>
            <div className='editarLoteInfo'>
              {(datosEditarParte.observaciones != "" & datosEditarParte.observaciones != " ") ? <div className='observacionesEditarLote'><b>Observaciones: </b>{datosEditarParte.observaciones}</div>
                : ""}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
            <Button className="colorGrupo" onClick={ocultarConfirmLote}>No</Button>
            <Button className="colorGrupo" onClick={confirmEdicionLote}>Si</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>

      {/* <Dialog fullScreen={fullScreen}
        open={openAyuda}>
        <DialogTitle>
          Leyenda
        </DialogTitle>
        <DialogContent>
          
        </DialogContent>
        <DialogActions>
          <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
            <Button className="colorGrupo" onClick={toggleAyuda}>Cerrar</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog> */}

      <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
      <CargaSpinner contadorSpinner={contadorSpinner} />

    </div >
  );
}

export { GridParte };
export default GridParte;